import { instance } from "lib/axios";
export async function login(data) {
  const response = await instance.post(`/api/user/login`, data);

  if (response?.data?.data?.access_token) {
    console.log(response?.data?.data)
    localStorage.setItem("token", response?.data?.data?.access_token);
    localStorage.setItem("user_id", response?.data?.data?.user_id);
    localStorage.setItem("refresh_token", response?.data?.data?.refresh_token);
    localStorage.setItem("username", response?.data?.data?.username);
    localStorage.setItem("role", response?.data?.data.role);
    localStorage.setItem("user_email", response?.data?.data?.email);
  }
  return response;
}
