import { Spinner } from "components/common/Spinner";
import { queryClient } from "lib/react-query";
import { useGetAdditionalData } from "modules/vendorConsolidation/api";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { globalStoreV2 } from "store/globalStoreV2";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
import useFilterStore from "store/persistentFilterStore";
import {
  useGetCombinedTable,
  useGetDocumentMetadata
} from "../api";
import CombinedTable from "./CombinedTable";
import HumanVerificationTable from "./HumanVerificationTable";
import MetadataTable from "./MetadataTable";
import RawTable from "./RawTable";
const Tab = ({ value, activeValue, onClick, ...props }) => (
  <button
    className="border p-2"
    style={{
      flex: "1 1 0%",
      minWidth: "120px",
      backgroundColor: activeValue === value ? "#FDFFD0" : "white",
      textTransform: "capitalize",
      cursor: "pointer"
    }}
    onClick={() => onClick(value)}
    {...props}
  >
    {value}
  </button>
);
const Tables = ({ setData, setIsLoading, setActiveTab, activeTab }) => {
  const [show, setShow] = useState(true);
  const [showComments, setShowComments] = useState(false);
  let count = 0;
  const [searchParams, setSearchParams] = useSearchParams();
  let page = searchParams.get("page_number") || 1;
  let vendor_id = searchParams.get("vendor") || "";
  let document_uuid = searchParams.get("document_uuid") || "";
  let layout = searchParams.get("layout") || null;
  let assigned_to=searchParams.get("assigned_to")
  const [modalShow, setModalShow] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  const { filters } = useFilterStore();
// alert(vendor_id)
  const { data, isLoading, isPending, isFetched } = useGetDocumentMetadata({
    page: page, 
    page_size: filters?.page_size,
    invoice_type: filters?.invoice_type,
    invoice_detection_status: filters?.invoice_detection_status,
    rerun_status: filters?.rerun_status,
    auto_accepted: filters?.auto_accepted,
    start_date: filters?.start_date,
    end_date: filters?.end_date,
    clickbacon_status: filters?.clickbacon_status,
    human_verification: filters?.human_verification,
    sort_order: filters?.sort_order,
    restaurant: filters?.restaurant,
    human_verified:filters?.human_verified,
    vendor_id,
    document_uuid,
    assigned_to
    
  });
  const [showVertical, setShowVertical] = useState(false);
  const handleTabChange = (tab) => setActiveTab(tab);

  const { data: additionaldata } = useGetAdditionalData();
  const { data: combinedTable, isLoading: loadingCombined } =
    useGetCombinedTable(document_uuid || data?.data?.[0]?.document_uuid);
  const {
    setTableData,
    tableData,
    clearStore,
    setLastUpdateInfo,
    setInvoiceType,
    categoryWiseSum,
    setMetaData,
    setCategoryWiseSum,
    setOperations,
    setCombinedTableCopy,
    added,
    setAdded,
    reCalculateCWiseSum,
    history,
    setHistory,
    operations,
    setBoundingBoxes,
    setBoundingBox,
    setHighlightAll,
    setReCalculateCWiseSum,
    setUpdatedFields
  } = invoiceDetailStore();
  const { setAdditionalData } = globalStoreV2();
  const appendFiltersToUrl = () => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      }
    });
    setSearchParams(newParams);
  };
  useEffect(() => {
    appendFiltersToUrl();
    setTableData([]);
  }, []);
  useEffect(() => {
    setLastUpdateInfo(
      data?.data?.[0]?.latest_update_info || data?.data?.latest_update_info
    );
  }, [data, isLoading]);

  useEffect(() => {
    setHighlightAll(false);
    setBoundingBox({});
    setBoundingBoxes([]);
    setReCalculateCWiseSum(true);
    setUpdatedFields({});
    if (combinedTable) {
      setTableData(combinedTable);
    }
    setOperations([]);
    setHistory([]);
    setAdditionalData(additionaldata);
    setActiveTab("Metadata");
    setOperations([]);
    setCombinedTableCopy({});
    // setTableData({})
  }, [page, additionaldata]);
  useEffect(() => {
    // setTableData({});
    setCombinedTableCopy({});
  }, [page]);
  useEffect(() => {
    if (tableData?.length !== 0) {
      const categoryColNum =
        tableData?.data?.processed_table?.columns?.findIndex(
          (col) => col.column_name == "Category"
        );
      const extPriceColNum =
        tableData?.data?.processed_table?.columns?.findIndex(
          (col) => col.column_name == "Extended Price"
        );

      const categorySum = tableData?.data?.processed_table?.rows?.reduce(
        (acc, row) => {
          const category = row?.cells[categoryColNum]?.text;
          const price = Number(row?.cells[extPriceColNum]?.text || 0);
          if (category) {
            acc[category] = (acc[category] || 0) + price;
          }
          return acc;
        },
        {}
      );
      if (!categorySum) {
        return;
      }
      const categorySumArray = Object?.entries(categorySum).map(
        ([category, sum]) => ({ category, sum })
      );

      setCategoryWiseSum(categorySumArray);
    }

  }, [reCalculateCWiseSum, history, setHistory, operations, tableData, page]);

  useEffect(() => {
    if (!combinedTable) return;
    const { processed_table } = combinedTable?.data || {};
    const { columns, rows } = processed_table || {};

    if (columns && rows) {
      let copyObj = JSON.parse(JSON.stringify(combinedTable));
      const { columns, rows } = copyObj?.data?.processed_table;

      // Create a mapping from column_uuid to column_order
      const columnOrderMap = columns.reduce((acc, column) => {
        acc[column.column_uuid] = column.column_order;
        return acc;
      }, {});

      // Sort cells in each row based on column_order
      copyObj.data.processed_table.rows.forEach((row) => {
        row.cells.sort((a, b) => {
          return columnOrderMap[a.column_uuid] - columnOrderMap[b.column_uuid];
        });
      });

      queryClient.setQueryData(["combined-table", document_uuid], copyObj);

      const filteredColumns = columns.filter((col) => col.selected_column);

      const filteredRows = rows.map((row) => ({
        ...row,
        cells: row.cells.filter((cell, i) =>
          filteredColumns.some((col) => {
            return col.column_order - 1 === i;
          })
        )
      }));

      
      setTableData({
        ...data,
        data: {
          ...data?.data,
          processed_table: {
            ...processed_table,
            columns: filteredColumns,
            rows: filteredRows
          }
        }
      });
    }
  }, [loadingCombined, page,isLoading,data]);
  useEffect(() => {
    if (combinedTable) {
      if (!added) {
        setCombinedTableCopy(combinedTable);
        setAdded(true);
      }
    }
  }, [combinedTable]);
  useEffect(() => {
    setMetaData(data?.data?.[0] || data?.data);
  }, [data, isLoading]);

  useEffect(() => {
    if (layout == "table") {
      setShowVertical(true);
    }
  }, [layout]);
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    } else {
      // setIsLoading(true);
    }
    setData(data);
  }, [data]);
  return (
    <>
      {" "}
      <Col md={["pdf", "table"].includes(layout) ? 12 : 6}>
        {isLoading ? (
          <div
            style={{ height: "500px" }}
            className="d-flex justify-content-start align-items-center"
          >
            <Spinner />
          </div>
        ) : (
          <div className="my-4" style={{ height: "530px" }}>
            <div className="d-flex border my-2" style={{ overflowX: "auto" }}>
              <Tab
                activeValue={activeTab}
                onClick={handleTabChange}
                value="Metadata"
              />

              {!document_uuid &&
                data?.data[0]?.invoice_type !== "Summary Invoice" &&
                data?.data[0] !== undefined && (
                  <>
                    <Tab
                      activeValue={activeTab}
                      onClick={handleTabChange}
                      value={"Raw Table"}
                    />

                    <Tab
                      activeValue={activeTab}
                      onClick={handleTabChange}
                      value={"Combined Table"}
                    />
                  </>
                )}
              {document_uuid &&
                ((data?.data?.invoice_type !== "Summary Invoice" &&
                  data?.data !== undefined) ||
                  (data?.data[0]?.invoice_type !== "Summary Invoice" &&
                    data?.data[0] !== undefined)) && (
                  <>
                    <Tab
                      activeValue={activeTab}
                      onClick={handleTabChange}
                      value={"Raw Table"}
                    />

                    <Tab
                      activeValue={activeTab}
                      onClick={handleTabChange}
                      value={"Combined Table"}
                    />
                  </>
                )}
              {!isLoading && (
                <Tab
                  activeValue={activeTab}
                  onClick={handleTabChange}
                  value={"Human Verification"}
                />
              )}
            </div>
            {activeTab === "Metadata" ? (
              <>
                {!isLoading && (
                  <MetadataTable
                    data={data}
                    additionaldata={additionaldata}
                    isLoading={isLoading}
                    payload={{
                      ...filters,
                      page,
                      vendor_id,
                      document_uuid
                    }}
                  />
                )}
              </>
            ) : activeTab === "Raw Table" ? (
              <>
                {loadingCombined ? (
                  <>
                    <Spinner />
                  </>
                ) : (
                  <RawTable data={combinedTable?.data?.raw_table} />
                )}
              </>
            ) : activeTab === "Combined Table" ? (
              <>
                {loadingCombined ? (
                  <Spinner />
                ) : (
                  <CombinedTable
                    data={combinedTable}
                    document_uuid={
                      document_uuid || data?.data?.[0]?.document_uuid
                    }
                  />
                )}
              </>
            ) : activeTab === "Human Verification" ? (
              <HumanVerificationTable
                data={combinedTable}
                metadata={data}
                payload={{
                  ...filters,
                  page,
                  vendor_id,
                  document_uuid
                }}
                isLoading={loadingCombined}
                document_uuid={document_uuid || data?.data?.[0]?.document_uuid}
              />
            ) : null}
          </div>
        )}
      </Col>
    </>
  );
};

export default Tables;
