import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import ResizableCell from "components/Utility/ResizableCell";
import { queryClient } from "lib/react-query";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
  Tooltip
} from "react-bootstrap";
import {
  ArrowBarDown,
  ArrowBarUp,
  ArrowClockwise,
  Crosshair,
  CurrencyDollar,
  DashCircleFill,
  DashLg,
  PatchCheckFill,
  PatchExclamationFill,
  Plus,
  PlusCircleFill,
  Sliders,
  XCircle
} from "react-bootstrap-icons";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { globalStoreV2 } from "store/globalStoreV2";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
import { v4 as uuidv4 } from "uuid";
import styles from "../../../components/invoice/InvoiceComponent.module.css";
import {
  useAutoCalculate,
  useMarkAsNotSupported,
  useUpdateDocumentMetadata,
  useUpdateDocumentTable
} from "../api";
import ContextMenu from "./ContextMenu";

// vendor_invoice_document_types
// vendor_invoice_categories
const MyDropDown = ({
  searchValue,
  setSearchValue,
  handleSelectCategory,
  cellValue,
  cell,
  rowIndex,
  cellIndex,
  additionalData,
  columnNames
}) => {
  const dropdownRef = useRef(null);
  const [dropdownDirection, setDropdownDirection] = useState("down");

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;

        setDropdownDirection(
          spaceBelow < 300 && spaceAbove > 200 ? "up" : "down"
        );
      }
    };

    checkDropdownPosition();
    window.addEventListener("resize", checkDropdownPosition);

    return () => {
      window.removeEventListener("resize", checkDropdownPosition);
    };
  }, []);

  return (
    <Dropdown
      ref={dropdownRef}
      drop={dropdownDirection}
      onSelect={(e) => {
        handleSelectCategory(rowIndex, cellIndex, e);
      }}
    >
      <Dropdown.Toggle variant="outline" className="border w-100 bg-white">
        {cellValue || cell.text || "Select Category"} {/* Update the label */}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          maxHeight: "200px",
          overflowY: "scroll",
          overflowX: "hidden",
          zIndex: 9000
        }}
      >
        <div className="px-2 sticky-top" style={{ top: "-7px" }}>
          <FormControl
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        {additionalData?.data?.category_choices
          ?.filter((it) => !columnNames.includes(it?.name?.toLowerCase()))
          ?.filter((it) =>
            it?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
          )
          ?.map((option, index) => (
            <Dropdown.Item key={index} eventKey={option?.name}>
              {option?.name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
const HumanVerificationTable = ({
  document_uuid,
  data,
  isLoading,
  metadata,
  payload
}) => {
  const {
    tableData,
    setTableData,
    operations,
    setOperations,
    invoiceType,
    metaData,
    categoryWiseSum,
    setMetaData,
    setBoundingBox,
    setBoundingBoxes,
    bounding_boxes,
    highlightAll,
    setHighlightAll,
    combinedTableCopy,
    setAdded,
    setCombinedTableCopy,
    history,
    totalExtendedPrce,
    setTotalExtendedPrice,
    setHistory,
    setReCalculateCWiseSum,
    setHighlightRow,
    updatedFields,
    setUpdatedFields,
    reCalculateCWiseSum,
    clearUpdatedFields,
    editVendor,
    editBranch,
    newVendor,
    newBranch,
    branchChanged,
    vendorChanged
  } = invoiceDetailStore();

  const getTooltipValue = (item) => {
    let category = item?.category;
    let vendor = item?.vendor;
    let branch = item?.branch;
    let item_code = item?.item_code;
    let item_description = item?.item_description; // corrected typo

    return `Category : ${category || ""}\nVendor : ${vendor || ""}\nBranch : ${
      branch || ""
    }\nItem Code : ${item_code || ""}\nItem Description : ${
      item_description || ""
    }`;
  };

  const { additionalData } = globalStoreV2();
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page_number") || 1;
  // const { columns, rows } = tableData?.data?.processed_table;
  const [loadingState, setLoadingState] = useState({
    saving: false,
    rejecting: false,
    accepting: false
  });

  const [rejectionReason, setRejectionReason] = useState(
    metaData?.["rejection_reason"]
  );
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [columnIds, setColumnsIds] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [stopHovering, setStopHovering] = useState(true);

  useEffect(() => {
    if (tableData?.data?.processed_table?.columns) {
      const columnIdsObjs = Object.values(
        tableData?.data?.processed_table?.columns || {}
      )?.map(
        ({
          bounding_box,
          column_name,
          column_order,
          selected_column,
          ...rest
        }) => rest
      );
      const colIds = columnIdsObjs?.reduce((acc, it, i) => {
        acc = [...acc, it?.column_uuid];
        return acc;
      }, []);
      setColumnsIds(colIds);
      const columnNamesObjs = Object.values(
        tableData?.data?.processed_table?.columns || {}
      )?.map(
        ({
          bounding_box,
          column_uuid,
          column_order,
          selected_column,
          ...rest
        }) => rest
      );
      const colnames = columnNamesObjs?.reduce((acc, it, i) => {
        acc = [...acc, it?.column_name?.toLowerCase()];
        return acc;
      }, []);
      setColumnNames(colnames);
    }

    // let copyObj = JSON.parse(JSON.stringify(tableData));
    // const { columns, rows } = copyObj?.data?.processed_table;

    // // Create a mapping from column_uuid to column_order
    // const columnOrderMap = columns?.reduce((acc, column) => {
    //   acc[column.column_uuid] = column.column_order;
    //   return acc;
    // }, {});

    // // Sort cells in each row based on column_order
    // copyObj?.data?.processed_table?.rows?.forEach((row) => {
    //   row.cells.sort((a, b) => {
    //     return columnOrderMap[a.column_uuid] - columnOrderMap[b.column_uuid];
    //   });
    // });
  }, [tableData]);

  const [viewVerificationColumn, setViewVerificationColumn] = useState(true);
  const [viewDeleteColumn, setViewDeleteColumn] = useState(false);
  const [viewShiftColumn, setViewShiftColumn] = useState(false);

  const [editMode, setEditMode] = useState({ rowIndex: null, cellIndex: null });
  const [cellValue, setCellValue] = useState("");
  const [marking, setMarking] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [autoCalculate, setAutoCalculate] = useState(false);
  const { mutate: markAsNotSupported } = useMarkAsNotSupported();
  const [showInvalidDocumentModal, setShowInvalidDocumentModal] =
    useState(false);
  const { mutate: saveDocumentTable } = useUpdateDocumentTable();
  const tableRef = useRef(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        // Clicked outside the table, reset the editMode
        setEditMode({ rowIndex: null, cellIndex: null });
      }
    };

    // Add event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const saveHistory = () => {
    setHistory([
      ...history,
      {
        tableData: JSON.parse(JSON.stringify(tableData)),
        operations: [...operations]
      }
    ]);
  };

  const undoLastAction = () => {
    if (history.length === 0) return;

    const { tableData: lastTableData, operations: lastOperations } =
      history.pop();
    const lastOperation = lastOperations[lastOperations.length - 1];
    if (lastOperation?.type === "delete_row") {
      const deletedRowUuid = lastOperation.data.transaction_uuid;
      const restoredRow = lastTableData.data.processed_table.rows.find(
        (row) => row.transaction_uuid === deletedRowUuid
      );

      const updatedRows = [
        ...lastTableData.data.processed_table.rows,
        restoredRow
      ];
      setTableData({
        ...lastTableData,
        data: {
          ...lastTableData.data,
          processed_table: {
            ...lastTableData.data.processed_table,
            rows: updatedRows
          }
        }
      });
    } else {
      setTableData(lastTableData);
      setContextMenu({
        visible: false,
        position: { x: 0, y: 0 }
      });
      setCombinedTableCopy(tableData);
    }

    setOperations(lastOperations);
    setHistory(history);
  };

  const setCachedData = (key, value, index, column_uuid) => {
    let copyObj = JSON.parse(JSON.stringify(tableData));
    const rows = JSON.parse(
      JSON.stringify(copyObj?.data?.processed_table?.rows)
    );
    copyObj.data.processed_table.columns[index][key] = value;
    copyObj.data.processed_table.rows = rows;

    setTableData(copyObj);

    saveHistory();
    // queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    if (key == "column_name") {
      if (
        !operations?.find(
          (op) =>
            op?.type == "update_column" && op?.data?.column_uuid == column_uuid
        )
      ) {
        let newOperation = {
          type: "update_column",
          operation_order: operations?.length + 1,
          data: {
            column_uuid:
              copyObj.data.processed_table.columns[index]?.column_uuid,
            selected_column: true,
            column_name: value
          }
        };
        setOperations([...operations, newOperation]);
      } else {
        let existingINdex = operations?.findIndex(
          (op) => op?.type == "update_column"
        );
        let existing = operations?.find((op) => op?.type == "update_column");
        existing["data"]["column_name"] = value;
        let finalOperations = operations?.map((opr, i) => {
          if (i == existingINdex) {
            opr = existing;
          }
          return opr;
        });
        setOperations(finalOperations);
      }
    }
  };
  const addEmptyRow = (rowIndexToAdd = -1, copyRowIndex = -1, copyType) => {
    saveHistory();

    let newRow = {};
    let operation = [];
    let tableDataRow;
    let combinedTableRow;
    const copyFromRow = data?.data?.processed_table?.rows[copyRowIndex];
    const updatedColumns = data?.data?.processed_table?.columns;
    if (copyRowIndex !== -1) {
      newRow = {
        cells: data?.data?.processed_table?.columns.map((column, i) => ({
          column_uuid: column?.column_uuid,
          text: copyFromRow?.cells[i]?.text || "",
          actual_text: copyFromRow?.cells[i]?.actual_text || null,
          confidence: copyFromRow?.cells[i]?.confidence || null,
          page_index: copyFromRow?.cells[i]?.page_index || 0,
          row_uuid: uuidv4(),
          cell_uuid: uuidv4()
        })),
        row_order:
          data?.data?.processed_table?.rows[
            data?.data?.processed_table?.rows.length - 1
          ]?.row_order + 1 || 1,
        transaction_uuid: uuidv4()
      };

      operation = [
        {
          type: "create_row",
          operation_order: operations?.length + 1,
          data: {
            transaction_uuid: newRow.transaction_uuid,
            row_order: newRow.row_order,
            cells: newRow.cells
          }
        },
        {
          type: "move_row",
          operation_order: operations?.length + 2,
          data: {
            selected_row_uuid: newRow?.transaction_uuid,
            move_to_row_uuid: copyFromRow?.transaction_uuid
          }
        }
      ];
      tableDataRow = newRow;
    } else {
      newRow = {
        cells: data?.data?.processed_table?.columns.map((column) => ({
          column_uuid: column?.column_uuid,
          text: "",
          actual_text: null,
          confidence: null,
          page_index: 0,
          row_uuid: uuidv4(),
          cell_uuid: uuidv4(),
          selected_column: column.selected_column
        })),
        row_order:
          tableData?.data?.processed_table?.rows[
            data?.data?.processed_table?.rows.length - 1
          ]?.row_order + 1 || 1,
        transaction_uuid: uuidv4()
      };
      combinedTableRow = { ...newRow };
      tableDataRow = {
        ...newRow,
        cells: newRow.cells?.filter((c) => {
          return c.selected_column == true;
        })
      };

      operation = [
        {
          type: "create_row",
          operation_order: operations?.length + 1,
          data: {
            transaction_uuid: newRow.transaction_uuid,
            row_order: newRow.row_order,
            cells: newRow.cells
          }
        }
      ];
    }

    const targetIndex =
      rowIndexToAdd === -1
        ? tableData?.data?.processed_table?.rows.length
        : copyType === "up"
        ? copyRowIndex // Insert directly at rowIndexToAdd for "up"
        : rowIndexToAdd + 1; // Insert after rowIndexToAdd for "down"

    let updatedData = JSON.parse(JSON.stringify(data));
    const updatedRows = [...tableData?.data?.processed_table?.rows];
    const updatedCombinedTableRows = [...data?.data?.processed_table?.rows];
    updatedRows.splice(targetIndex, 0, tableDataRow);
    updatedCombinedTableRows.splice(
      targetIndex,
      0,
      combinedTableRow == null ? tableDataRow : combinedTableRow
    );

    setOperations([...operations, ...operation]);

    setTableData({
      ...tableData,
      data: {
        ...tableData.data,
        processed_table: {
          ...tableData.data.processed_table,
          rows: updatedRows,
          row_count: updatedRows.length
        }
      }
    });

    setCombinedTableCopy({
      ...data,
      data: {
        ...data.data,
        processed_table: {
          ...data.data.processed_table,
          rows: updatedCombinedTableRows,
          columns: updatedColumns,
          row_count: updatedRows.length
        }
      }
    });

    queryClient.setQueryData(["combined-table", document_uuid], {
      ...updatedData,
      data: {
        ...updatedData.data,
        processed_table: {
          ...updatedData.data.processed_table,
          rows: updatedCombinedTableRows,
          // columns:updatedColumns,
          row_count: updatedRows.length
        }
      }
    });
    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 }
    });
  };
  const deleteRow = (rowIndex) => {
    saveHistory();
    const updatedRows = [...tableData?.data?.processed_table?.rows];
    const deletedRow = updatedRows[rowIndex];
    updatedRows.splice(rowIndex, 1);
    const operation = {
      type: "delete_row",
      operation_order: operations.length + 1,
      data: {
        transaction_uuid: deletedRow.transaction_uuid
      }
    };
    setOperations([...operations, operation]);
    setTableData({
      ...tableData,
      data: {
        ...tableData.data,
        processed_table: {
          ...tableData.data.processed_table,
          rows: updatedRows
        }
      }
    });
    toast.success(`Row ${rowIndex + 1} deleted successfully.`);
    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 }
    });
  };
  const addEmptyColumn = () => {
    saveHistory();

    // Step 1: Define new column
    const newColumn = {
      column_uuid: uuidv4(),
      column_name: "Select an option",
      column_order:
        tableData?.data?.processed_table?.columns?.[
          tableData?.data?.processed_table?.columns?.length - 1
        ]?.column_order + 1 || 0, // Default to 0 if no columns exist
      bounding_boxes: null,
      selected_column: true
    };

    // Step 2: Add the new column to columns array
    const updatedColumns = [
      ...tableData?.data?.processed_table?.columns,
      newColumn
    ];

    // Step 3: Add an empty cell for each row in the new column
    const updatedRows = tableData?.data?.processed_table?.rows?.map((row) => ({
      ...row,
      cells: [
        ...row.cells,
        {
          column_uuid: newColumn.column_uuid,
          text: "",
          actual_text: null,
          confidence: null,
          page_index: 0,
          row_uuid: row.transaction_uuid,
          cell_uuid: uuidv4(), // Ensure each cell has a unique UUID
          selected_column: true
        }
      ]
    }));

    // Step 4: Define the operation for creating a new column
    const newCells = updatedRows?.map((row) => row.cells[row.cells.length - 1]); // Extract the last cell (newly added)

    // Step 5: Update all states consistently

    // setTableData({
    //   ...tableData,
    //   data: {
    //     ...tableData.data,
    //     processed_table: {
    //       ...tableData.data.processed_table,
    //       columns: updatedColumns,
    //       rows: updatedRows
    //     }
    //   }
    // });

    let copyTable = JSON.parse(JSON.stringify(tableData));
    copyTable.data.processed_table.rows = updatedRows;
    copyTable.data.processed_table.columns = updatedColumns;
    setTableData(copyTable);

    const operation = {
      type: "create_column",
      operation_order: operations.length + 1,
      data: {
        column_uuid: newColumn.column_uuid,
        column_name: newColumn.column_name,
        selected_column: newColumn.selected_column,
        cells: updatedRows.reduce((acc, row) => {
          const filteredCells = row?.cells?.filter(
            (cell) => cell?.column_uuid == newColumn?.column_uuid
          );
          return acc.concat(filteredCells);
        }, [])
      }
    };

    setOperations([...operations, operation]);
    setCombinedTableCopy({
      ...combinedTableCopy,
      data: {
        ...data.data,
        processed_table: {
          ...combinedTableCopy?.data?.processed_table,
          columns: [
            ...combinedTableCopy?.data?.processed_table?.columns,
            newColumn
          ],
          rows: combinedTableCopy?.data?.processed_table?.rows?.map((row) => ({
            ...row,
            cells: [
              ...row.cells,
              ...updatedRows.reduce((acc, row) => {
                const filteredCells = row?.cells?.filter(
                  (cell) => cell?.column_uuid == newColumn?.column_uuid
                );
                return acc.concat(filteredCells);
              }, [])
            ]
          }))
        }
      }
    });

    queryClient.setQueryData(["combined-table", document_uuid], {
      ...combinedTableCopy,
      data: {
        ...data.data,
        processed_table: {
          ...combinedTableCopy.data.processed_table,
          columns: [
            ...combinedTableCopy?.data?.processed_table?.columns,
            newColumn
          ],
          rows: combinedTableCopy?.data?.processed_table?.rows?.map((row) => ({
            ...row,
            cells: [
              ...row.cells,
              {
                column_uuid: newColumn.column_uuid,
                text: "",
                actual_text: null,
                confidence: null,
                page_index: 0,
                row_uuid: row.transaction_uuid,
                cell_uuid: uuidv4()
              }
            ]
          }))
        }
      }
    });

    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 }
    });
  };

  const handleEditCell = (rowIndex, cellIndex, initialValue) => {
    setEditMode({ rowIndex, cellIndex });
    setCellValue(initialValue);
  };

  const handleSaveCell = async (rowIndex, cellIndex, value) => {
    const originalValue =
      tableData.data.processed_table.rows[rowIndex].cells[cellIndex].text;

    if (value !== originalValue) {
      saveHistory();

      const updatedTableData = JSON.parse(JSON.stringify(tableData));
      const updatedData = JSON.parse(JSON.stringify(data));

      updatedTableData.data.processed_table.rows[rowIndex].cells[
        cellIndex
      ].text = value;

      updatedData.data.processed_table.rows[rowIndex].cells[cellIndex].text =
        value;

      const operation = {
        type: "update_cell",
        operation_order: operations.length + 1,
        data: {
          cell_uuid:
            updatedTableData.data.processed_table.rows[rowIndex].cells[
              cellIndex
            ].cell_uuid,
          row_uuid:
            updatedTableData.data.processed_table.rows[rowIndex]
              .transaction_uuid,
          column_uuid:
            updatedTableData.data.processed_table.rows[rowIndex].cells[
              cellIndex
            ].column_uuid,
          text: value
        }
      };

      // Add column names if needed
      updatedTableData.data.processed_table.rows[rowIndex].cells.forEach(
        (c, i) => {
          c["column_name"] =
            tableData.data.processed_table.columns[i]?.column_name;
        }
      );

      setOperations([...operations, operation]);
      setTableData(updatedTableData);
      let extPriceCellColumnUUID =
        updatedTableData.data.processed_table.columns?.find(
          (col) => col?.column_name == "Extended Price"
        )?.["column_uuid"];
      let copyOperations = JSON.parse(
        JSON.stringify([...operations, operation])
      );

      if (autoCalculate) {
        mutate(
          {
            document_uuid,
            row: { ...updatedTableData.data.processed_table.rows[rowIndex] }
          },
          {
            onSuccess: (data) => {
              const extPriceCell = data?.data?.cells.find(
                (cell) => cell.column_uuid === extPriceCellColumnUUID
              );

              const newOperation = {
                type: "update_cell",
                operation_order: operations.length + 1,
                data: {
                  cell_uuid: extPriceCell?.cell_uuid,
                  row_uuid:
                    updatedTableData.data.processed_table.rows[rowIndex]
                      .transaction_uuid,
                  column_uuid: extPriceCellColumnUUID,
                  text: extPriceCell?.text
                }
              };

              setOperations([...copyOperations, newOperation]);
              updatedTableData.data.processed_table.rows[rowIndex] = data.data;
              setTableData(updatedTableData);
              setReCalculateCWiseSum(true);
            }
          }
        );
      }

      queryClient.setQueryData(["combined-table", document_uuid], updatedData);
    }

    setEditMode({ rowIndex: null, cellIndex: null });
  };

  const handleKeyPress = (event, rowIndex, cellIndex) => {
    if (event.key === "Enter") {
      handleSaveCell(rowIndex, cellIndex);
    }
  };

  const handleSelectCategory = (rowIndex, cellIndex, selectedValue) => {
    saveHistory();

    let updatedTableData = { ...tableData };
    let combineTableData = JSON.parse(JSON.stringify(data));
    updatedTableData.data.processed_table.rows[rowIndex].cells[cellIndex].text =
      selectedValue;
    combineTableData.data.processed_table.rows[rowIndex].cells[cellIndex].text =
      selectedValue;
    setTableData(updatedTableData);
    const existingOperationIndex = operations.findIndex(
      (op) =>
        op.type === "update_cell" &&
        op.data.cell_uuid ===
          updatedTableData.data.processed_table.rows[rowIndex].cells[cellIndex]
            .cell_uuid
    );
    const newOperation = {
      type: "update_cell",
      operation_order: operations.length + 1,
      data: {
        cell_uuid:
          updatedTableData.data.processed_table.rows[rowIndex].cells[cellIndex]
            .cell_uuid,
        row_uuid:
          updatedTableData.data.processed_table.rows[rowIndex].transaction_uuid,
        column_uuid:
          updatedTableData.data.processed_table.rows[rowIndex].cells[cellIndex]
            .column_uuid,
        text: selectedValue
      }
    };

    let updatedOperations;
    if (existingOperationIndex >= 0) {
      updatedOperations = [...operations];
      newOperation.operation_order =
        operations[existingOperationIndex].operation_order;
      updatedOperations[existingOperationIndex] = newOperation;
    } else {
      updatedOperations = [...operations, newOperation];
    }
    setOperations(updatedOperations);
    queryClient.setQueryData(
      ["combined-table", document_uuid],
      combineTableData
    );
    setEditMode({ rowIndex: null, cellIndex: null });
  };

  const handleRowRightClick = (
    event,
    rowIndex,
    cellIndex,
    column_uuid,
    cell_uuid,
    row_uuid
  ) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      position: { x: event.pageX, y: event.pageY },
      rowIndex,
      cellIndex,
      column_uuid,
      cell_uuid,
      row_uuid
    });
  };

  const handleCloseMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };

  useEffect(() => {
    if (metaData?.document_metadata?.added_fees?.length == 0) {
      setMetaData({
        ...metaData,
        ["document_metadata"]: {
          ...metaData.document_metadata,
          added_fees: [0]
        }
      });
    }
    if (metaData?.document_metadata?.added_taxes?.length == 0) {
      setMetaData({
        ...metaData,
        ["document_metadata"]: {
          ...metaData.document_metadata,
          added_taxes: [0]
        }
      });
    }
    if (metaData?.document_metadata?.added_discounts?.length == 0) {
      setMetaData({
        ...metaData,
        ["document_metadata"]: {
          ...metaData.document_metadata,
          added_discounts: [0]
        }
      });
    }
  }, [metaData]);

  const addTax = () => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_taxes"].push(0);
    setMetaData(newData);
  };

  const addDiscount = () => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_discounts"].push(0);
    setMetaData(newData);
  };
  const addNewFeeAmt = () => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_fees"].push(0);
    setMetaData(newData);
  };

  const removeDiscount = (index) => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_discounts"].splice(index, 1);
    setMetaData(newData);
    setUpdatedFields((prevFields) => {
      return {
        ...prevFields,
        document_metadata: {
          ...prevFields.document_metadata,
          added_discounts: newData["document_metadata"]["added_discounts"]
        }
      };
    });
  };

  const removeTax = (index) => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_taxes"].splice(index, 1);
    setMetaData(newData);
    setUpdatedFields((prevFields) => {
      return {
        ...prevFields,
        document_metadata: {
          ...prevFields.document_metadata,
          added_taxes: newData["document_metadata"]["added_taxes"]
        }
      };
    });
  };
  const removeFee = (index) => {
    const newData = { ...metaData };
    newData["document_metadata"]["added_fees"].splice(index, 1);
    setMetaData(newData);
    setUpdatedFields((prevFields) => {
      return {
        ...prevFields,
        document_metadata: {
          ...prevFields.document_metadata,
          added_fees: newData["document_metadata"]["added_fees"]
        }
      };
    });
  };

  let calculatedsum = (
    totalExtendedPrce +
    metaData?.document_metadata?.added_taxes?.reduce((acc, it, i) => {
      return (acc += Number(it));
    }, 0) +
    metaData?.document_metadata?.added_fees?.reduce((acc, it, i) => {
      return (acc += Number(it));
    }, 0) -
    metaData?.document_metadata?.added_discounts?.reduce((acc, it, i) => {
      return (acc += Number(it));
    }, 0)
  ).toFixed(2);

  const handleRejection = () => {
    setLoadingState({ ...loadingState, rejecting: true });
    let payload = {};
    payload["rejected"] = true;

    payload["rejection_reason"] = rejectionReason;
    if (operations?.length !== 0) {
      setLoadingState({ ...loadingState, saving: true });

      saveDocumentTable(
        { document_uuid: metaData?.document_uuid, data: operations },
        {
          onSuccess: () => {
            setLoadingState({ ...loadingState, saving: false });
            setOperations([]);

            setRejectionReason("");
            setHistory([]);
            setShowRejectionModal(false);
            queryClient.invalidateQueries({ queryKey: ["combined-table"] });
            queryClient.invalidateQueries({ queryKey: ["additional-data"] });
            queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
            setCombinedTableCopy({});
            setAdded(false);
          },

          onError: () => setLoadingState({ ...loadingState, saving: false })
        }
      );
    }
    updateTable(
      {
        document_uuid: metaData?.document_uuid,
        data: { ...payload, ...updatedFields }
      },
      {
        onSuccess: () => {
          setLoadingState({ ...loadingState, rejecting: false });
          // queryClient.invalidateQueries({ queryKey: ["combined-table"] });
          setRejectionReason("");
          setShowRejectionModal(false);
          queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
        },
        onError: () => setLoadingState({ ...loadingState, rejecting: false })
      }
    );
  };
  const handleAccept = () => {
    setLoadingState({ ...loadingState, accepting: true });
    metaData["human_verified"] = true;
    if (operations?.length !== 0) {
      setLoadingState({ ...loadingState, saving: true });

      saveDocumentTable(
        { document_uuid: metaData?.document_uuid, data: operations },
        {
          onSuccess: () => {
            setLoadingState({ ...loadingState, saving: false });
            setOperations([]);

            setHistory([]);
            queryClient.invalidateQueries({ queryKey: ["combined-table"] });
            queryClient.invalidateQueries({ queryKey: ["additional-data"] });
            queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
            setCombinedTableCopy({});
            setAdded(false);
          },

          onError: () => setLoadingState({ ...loadingState, saving: false })
        }
      );
    }
    setLoadingState({ ...loadingState, saving: true });

    updateTable(
      {
        document_uuid: document_uuid,
        data: { human_verified: true, ...updatedFields }
      },
      {
        onSuccess: () => {
          setLoadingState({ ...loadingState, saving: false });
          queryClient.invalidateQueries({ queryKey: ["combined-table"] });

          queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
          clearUpdatedFields();
        },
        onError: () => {
          setLoadingState({ ...loadingState, saving: false });
          queryClient.invalidateQueries({ queryKey: ["combined-table"] });
        }
      }
    );
  };
  const { mutate: updateTable } = useUpdateDocumentMetadata();

  const handleSave = () => {
    let refreshed = false;
    if (
      (newVendor?.length > 0 || editVendor || vendorChanged) &&
      (newBranch?.length > 0 || editBranch || branchChanged)
    ) {
      toast.warning("You have not saved Vendor Name in Metadata Table.");
      toast.warning("You have not saved the Vendor Address in Metadata Table.");
      return;
    }

    if (newVendor?.length > 0 || editVendor || vendorChanged) {
      return toast.warning("You have not saved Vendor Name in Metadata Table.");
    }
    if (newBranch?.length > 0 || editBranch || branchChanged) {
      return toast.warning(
        "You have not saved the Vendor Address in Metadata Table."
      );
    }

    if (Object.keys(updatedFields)?.length == 0 && operations?.length == 0) {
      toast.warning("No Fields Updated in Metadata table.");
      toast.warning("No Line Item Updated.");
      return;
    }
    if (Object.keys(updatedFields)?.length == 0) {
      toast.warning("No Fields Updated in Metadata table.");
    }
    if (operations.length == 0) {
      toast.warning("No Line Item Updated.");
    }

    if (Object.keys(updatedFields)?.length !== 0) {
      setLoadingState({ ...loadingState, saving: true });

      updateTable(
        { document_uuid: document_uuid, data: updatedFields },
        {
          onSuccess: () => {
            setLoadingState({ ...loadingState, saving: false });
            queryClient.invalidateQueries({ queryKey: ["additional-data"] });
            queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
            clearUpdatedFields();
            if (operations?.length == 0) {
              refreshed = true;
              window.location.reload();
            }
          },
          onError: () => {
            setLoadingState({ ...loadingState, saving: false });
          }
        }
      );
    }

    if (operations?.length !== 0) {
      setLoadingState({ ...loadingState, saving: true });

      saveDocumentTable(
        { document_uuid: metaData?.document_uuid, data: operations },
        {
          onSuccess: () => {
            setLoadingState({ ...loadingState, saving: false });
            setOperations([]);

            setHistory([]);
            queryClient.invalidateQueries({ queryKey: ["combined-table"] });
            queryClient.invalidateQueries({ queryKey: ["additional-data"] });
            // queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
            setCombinedTableCopy({});
            if (!refreshed) {
              refreshed = true;
              window.location.reload();
            }
            setAdded(false);
          },

          onError: () => setLoadingState({ ...loadingState, saving: false })
        }
      );
    }
  };

  const handleRowShifting = (rowIndex, shiftType) => {
    let copyObj = JSON.parse(JSON.stringify(tableData));
    if (rowIndex == 0 && shiftType == "up") {
      return;
    } else if (
      rowIndex == copyObj?.data?.processed_table?.rows?.length - 1 &&
      shiftType == "down"
    ) {
      return;
    }
    saveHistory();

    let shiftToIndex = shiftType == "up" ? rowIndex - 1 : rowIndex + 1;
    let temp = {};
    let newOperation = {
      type: "swap_rows",
      operation_order: operations?.length + 1,
      data: {
        row_1_uuid:
          copyObj.data.processed_table.rows[rowIndex]?.transaction_uuid,
        row_2_uuid:
          copyObj.data.processed_table.rows[shiftToIndex]?.transaction_uuid
      }
    };

    temp = copyObj.data.processed_table.rows[rowIndex];
    copyObj.data.processed_table.rows[rowIndex] =
      copyObj.data.processed_table.rows[shiftToIndex];
    copyObj.data.processed_table.rows[shiftToIndex] = temp;
    setTableData(copyObj);
    setOperations([...operations, newOperation]);
  };
  const handleDeleteCell = (
    cellIndex,
    rowIndex,
    column_uuid,
    cell_uuid,
    row_uuid
  ) => {
    saveHistory();
    let copyObj = JSON.parse(JSON.stringify(tableData));
    let { rows } = copyObj?.data?.processed_table;

    // Check if the row exists and has cells
    if (!rows[rowIndex] || !rows[rowIndex].cells) {
      console.error("Row or cells do not exist");
      return;
    }

    // Find the cell to delete
    const currentCellToDelete = rows[rowIndex].cells.find(
      (cell) => cell.column_uuid === column_uuid
    );
    if (!currentCellToDelete) {
      console.error("Cell to delete does not exist");
      return;
    }

    // Record the UUID of the cell being deleted for the operation
    const deletedCellUuid = currentCellToDelete.cell_uuid;

    // Shift cells up in the specified column (in the rows)
    for (let i = rowIndex; i < rows.length - 1; i++) {
      const currentCell = rows[i].cells.find(
        (cell) => cell.column_uuid === column_uuid
      );
      const nextCell = rows[i + 1].cells.find(
        (cell) => cell.column_uuid === column_uuid
      );

      if (nextCell) {
        // Move the data from the next cell to the current cell
        currentCell.text = nextCell.text;
        currentCell.actual_text = nextCell.actual_text;
        currentCell.cell_uuid = nextCell.cell_uuid; // Keep the UUID of the next cell
      }
    }

    // Clear the last cell in the column (after shifting cells up)
    const lastRow = rows[rows.length - 1];
    const lastCell = lastRow.cells.find(
      (cell) => cell.column_uuid === column_uuid
    );

    // Create a new empty cell in the last row if no cell exists
    if (!lastCell) {
      lastRow.cells.push({
        column_uuid: column_uuid,
        cell_uuid: uuidv4(), // New unique UUID for the empty cell
        text: "", // Initialize with empty text
        actual_text: "", // Initialize with empty actual text
        row_uuid: lastRow.row_uuid, // Maintain the row UUID
        selected_column: true // or your selection logic
      });
    } else {
      // If last cell exists, clear its text and set a new UUID
      lastCell.text = "";
      lastCell.actual_text = "";
      lastCell.cell_uuid = uuidv4(); // Assign a new unique UUID
    }

    // Create the delete operation (pass the UUID of the deleted cell)
    let deleteCellOperation = {
      type: "delete_cell",
      operation_order: operations.length + 1,
      data: {
        current_cell_uuid: deletedCellUuid, // UUID of the cell being deleted
        current_column_uuid: column_uuid,
        current_row_uuid: row_uuid,
        new_cell_uuid: lastCell ? lastCell.cell_uuid : null // New UUID of the last cell
      }
    };

    // Update operations and table data
    setOperations([...operations, deleteCellOperation]);
    setTableData(copyObj);
  };

  const addNewCell = (rowIndex, column_uuid, cell_uuid, row_uuid) => {
    let copyObj = JSON.parse(JSON.stringify(tableData));
    let combinedTableCopy = JSON.parse(JSON.stringify(data));

    let { rows } = copyObj.data.processed_table;
    saveHistory();
    let lastCell = null;
    let newRow;

    // Capture the last cell for the specified column before it’s shifted down
    if (rows[rows.length - 1]) {
      lastCell = rows[rows.length - 1].cells.find(
        (cell) => cell.column_uuid === column_uuid
      );
    }

    // Create a new row if we are at the last row
    if (lastCell) {
      let newRowUuid = uuidv4();
      newRow = {
        transaction_uuid: newRowUuid,
        row_order: rows.length + 1,
        item_master: {}, // Add any required row properties here
        cells: combinedTableCopy.data.processed_table.rows[0].cells.map(
          (cell) => ({
            ...cell,
            cell_uuid: uuidv4(),
            text: cell.column_uuid === column_uuid ? lastCell.text : "",
            actual_text: cell.column_uuid === column_uuid ? lastCell.text : "",
            row_uuid: newRowUuid
          })
        )
      };
      combinedTableCopy.data.processed_table.rows.push(newRow);
      rows.push(newRow);
    }

    // Shift every property down for the specified column
    for (let i = rows.length - 1; i > rowIndex; i--) {
      const currentCell = rows[i].cells.find(
        (cell) => cell.column_uuid === column_uuid
      );
      const previousCell = rows[i - 1].cells.find(
        (cell) => cell.column_uuid === column_uuid
      );

      if (currentCell && previousCell) {
        Object.assign(currentCell, previousCell); // Copy all properties
        currentCell.cell_uuid = uuidv4(); // Ensure unique UUID for the moved cell

        // Also shift in combinedTableCopy
        const combinedCurrentCell = combinedTableCopy.data.processed_table.rows[
          i
        ].cells.find((cell) => cell.column_uuid === column_uuid);
        if (combinedCurrentCell) {
          Object.assign(combinedCurrentCell, previousCell);
          combinedCurrentCell.cell_uuid = uuidv4();
        }
      }
    }

    // Clear the original cell's properties at the target rowIndex
    const targetCell = rows[rowIndex].cells.find(
      (cell) => cell.column_uuid === column_uuid
    );
    if (targetCell) {
      Object.assign(targetCell, {
        text: "",
        actual_text: "",
        cell_uuid: uuidv4(),
        bounding_box: null // Clear or reset bounding_box as needed
        // Reset any other properties as needed
      });
    }

    // Update the new row to remove unselected cells
    let updatedRow = {
      ...newRow,
      cells: newRow.cells?.filter((c) => c.selected_column !== false)
    };
    let copiedRows = [...rows.slice(0, -1), updatedRow]; // Remove last row and add new row
    let copiedRowsCombinedTable = [
      ...combinedTableCopy.data.processed_table.rows.slice(0, -1),
      newRow
    ];

    // Update both tableData and combinedTableCopy
    const filteredColumns = copyObj.data.processed_table.columns.filter(
      (c) => c.selected_column === true
    );

    setTableData({
      ...copyObj,
      data: {
        ...copyObj.data,
        processed_table: {
          ...copyObj.data.processed_table,
          columns: filteredColumns,
          rows: copiedRows
        }
      }
    });

    combinedTableCopy.data.processed_table.rows = copiedRowsCombinedTable;

    queryClient.setQueryData(
      ["combined-table", document_uuid],
      combinedTableCopy
    );

    let createCellOperation = {
      type: "create_cell",
      operation_order: operations?.length + 2,
      data: {
        current_cell_uuid: cell_uuid,
        current_column_uuid: column_uuid,
        current_row_uuid: row_uuid,
        new_cell_uuid: targetCell.cell_uuid
      }
    };

    let rowOperation = {
      type: "create_row",
      operation_order: operations?.length + 1,
      data: {
        transaction_uuid: newRow.transaction_uuid,
        row_order: newRow.row_order || 1,
        cells: newRow.cells
      }
    };

    setOperations([...operations, rowOperation, createCellOperation]);
  };

  const addRowBetween = (clickedRowIndex) => {
    saveHistory();
    const copyTableData = JSON.parse(JSON.stringify(tableData));
    const copyData = JSON.parse(JSON.stringify(data));
    let { processed_table } = copyTableData?.data;
    let clickedRow = processed_table?.rows[clickedRowIndex];

    // Create a new row
    let newRow = {
      transaction_uuid: uuidv4(),
      cells: data?.data?.processed_table?.columns.map((column) => ({
        column_uuid: column?.column_uuid,
        text: "",
        actual_text: null,
        confidence: null,
        page_index: 0,
        row_uuid: uuidv4(),
        cell_uuid: uuidv4()
      })),
      row_order: clickedRow ? clickedRow.row_order + 1 : 1
    };

    // Insert the new row
    processed_table.rows.splice(clickedRowIndex, 0, newRow);
    copyData.data.processed_table.rows.splice(clickedRowIndex, 0, newRow);

    // Update row orders
    for (let i = clickedRowIndex + 1; i < processed_table.rows.length; i++) {
      processed_table.rows[i].row_order += 1;
      copyData.data.processed_table.rows[i].row_order += 1;
    }

    // Prepare operations
    let new_operations = [
      {
        type: "create_row",
        operation_order: operations.length + 1,
        data: {
          transaction_uuid: newRow.transaction_uuid,
          row_order: newRow.row_order,
          cells: newRow.cells
        }
      },
      {
        type: "move_row",
        operation_order: operations.length + 2,
        data: {
          selected_row_uuid: newRow.transaction_uuid,
          move_to_row_uuid: clickedRow?.transaction_uuid
        }
      }
    ];

    setOperations([...operations, ...new_operations]);
    setTableData(copyTableData);
    queryClient.setQueryData(["combined-table", document_uuid], copyData);
  };

  const menuOptions = [
    {
      label: "Add Column",
      action: () => {
        addEmptyColumn();
      }
    },
    {
      label: "Add Row",
      action: () => {
        addRowBetween(contextMenu.rowIndex);
      }
    },
    {
      label: "Copy Row Up",
      action: () => {
        // handleRowShifting(contextMenu.rowIndex,"up");

        addEmptyRow(
          contextMenu.rowIndex == 0
            ? contextMenu?.rowIndex
            : contextMenu.rowIndexToAdd - 1,
          contextMenu.rowIndex,
          "up"
        );
      }
    },
    {
      label: "Copy Row Down",
      action: () => {
        addEmptyRow(contextMenu.rowIndex, contextMenu.rowIndex, "down");
      }
    },

    {
      label: "Add Cell",
      action: () => {
        addNewCell(
          contextMenu?.rowIndex,
          contextMenu?.column_uuid,
          contextMenu?.cell_uuid,
          contextMenu?.row_uuid
        );
      }
    },
    {
      label: "Delete Cell",
      action: () => {
        handleDeleteCell(
          contextMenu?.cellIndex,
          contextMenu?.rowIndex,
          contextMenu?.column_uuid,
          contextMenu?.cell_uuid,
          contextMenu?.row_uuid
        );
      }
    },

    {
      label: "Shift Row Up",
      action: () => {
        handleRowShifting(contextMenu.rowIndex, "up");
      }
    },
    {
      label: "Shift Row Down",
      action: () => {
        handleRowShifting(contextMenu.rowIndex, "down");
      }
    },

    {
      label: "Delete Row",
      action: () => {
        deleteRow(contextMenu.rowIndex);
      }
    }
  ];

  useEffect(() => {
    if (highlightAll == true) {
      let pushed = [];
      tableData?.data?.processed_table?.rows?.map((row) =>
        row?.cells?.map((cell) =>
          pushed.push({
            box: cell?.bounding_box,
            page_index: cell.page_index || 0
          })
        )
      );
      setBoundingBoxes(pushed);
    }
  }, [highlightAll]);

  const { mutate } = useAutoCalculate();
  const [hoveredRow, setHoveredRow] = useState(false);
  useEffect(() => {
    if (data && !isLoading) {
      let copyObj = JSON.parse(JSON.stringify(data));
      const { columns, rows } = copyObj?.data?.processed_table;

      // Create a mapping from column_uuid to column_order
      const columnOrderMap = columns?.reduce((acc, column) => {
        acc[column.column_uuid] = column.column_order;
        return acc;
      }, {});

      // Sort cells in each row based on column_order
      copyObj?.data?.processed_table?.rows?.forEach((row) => {
        row?.cells?.sort((a, b) => {
          return columnOrderMap[a.column_uuid] - columnOrderMap[b.column_uuid];
        });
      });
      setCombinedTableCopy(copyObj);
      // setTableData(copyObj);
      queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    }
  }, [isLoading, data]);
  useEffect(() => {
    if (tableData?.data?.processed_table?.columns?.length > 0) {
      let copyObj = JSON.parse(JSON.stringify(tableData));
      const { columns, rows } = copyObj?.data?.processed_table;

      // Create a mapping from column_uuid to column_order
      const columnOrderMap = columns?.reduce((acc, column) => {
        acc[column.column_uuid] = column?.column_order;
        return acc;
      }, {});

      // Sort cells in each row based on column_order
      copyObj?.data?.processed_table?.rows?.forEach((row) => {
        row.cells.sort((a, b) => {
          return columnOrderMap[a.column_uuid] - columnOrderMap[b.column_uuid];
        });
      });
      setTableData(copyObj);
    }
    let selectedFilteredColsIds = tableData?.data?.processed_table?.columns
      ?.filter((col) => col.selected_column == true)
      ?.filter((c) => c.selected_column)
      ?.map(
        ({
          bounding_box,
          column_name,
          column_order,
          selected_column,
          column_uuid
        }) => column_uuid
      );
    const filteredRowsForTableData = data?.data?.processed_table?.rows?.map(
      (row) => {
        const filteredCells = row?.cells?.filter((cell) =>
          selectedFilteredColsIds?.includes(cell?.column_uuid)
        );
        return { ...row, cells: filteredCells };
      }
    );
    // setTableData({
    //   ...tableData,
    //   data: {
    //     ...tableData?.data,
    //     processed_table: {
    //       ...tableData?.data?.processed_table,
    //       columns: tableData?.data?.processed_table?.columns?.filter(
    //         (col) => col.selected_column == true
    //       ),
    //       rows: filteredRowsForTableData
    //     }
    //   }
    // });
  }, [data, isLoading]);
  let invoice_type =
    metadata?.data?.[0]?.invoice_type || metadata?.data?.invoice_type;

  let last_update_info =
    metadata?.data?.[0]?.latest_update_info ||
    metadata?.data?.latest_update_info;

  let action_controls =
    metadata?.data?.[0]?.action_controls || metadata?.data?.action_controls;
  useEffect(() => {
    if (tableData?.length !== 0) {
      const extPriceColNum =
        tableData?.data?.processed_table?.columns?.findIndex(
          (col) => col.column_name == "Extended Price"
        );

      const totalExtendedPrice = tableData?.data?.processed_table?.rows?.reduce(
        (acc, row) => {
          const price = Number(row?.cells[extPriceColNum]?.text || 0);
          return acc + price;
        },
        0
      );

      setTotalExtendedPrice(totalExtendedPrice);
    }
  }, [
    reCalculateCWiseSum,
    history,
    setHistory,
    operations,
    tableData,
    page,
    editMode
  ]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {invoice_type !== "Summary Invoice" && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  justifyContent: "end",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <Link
                  to={`/invoice-details/?page_number=${page}&layout=pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pdf
                </Link>
                <Link
                  to={`/invoice-details/?page_number=${page}&layout=table`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Table
                </Link>

                <Form.Check
                  type="switch"
                  id="auto-calculate"
                  checked={autoCalculate}
                  className="d-flex justify-content-end mx-2 my-2 gap-2"
                  onChange={(e) => setAutoCalculate(e.target.checked)}
                  label="Auto Calculate"
                />
                <Form.Check
                  type="switch"
                  id="highlight"
                  checked={stopHovering}
                  className="d-flex justify-content-end mx-2 my-2 gap-2"
                  onChange={(e) => setStopHovering(e.target.checked)}
                  label="Highlight"
                />
                <Form.Check
                  type="switch"
                  id="highlight-all"
                  checked={highlightAll}
                  className="d-flex justify-content-end mx-2 my-2 gap-2"
                  onChange={(e) => {
                    setHighlightAll(e.target.checked);

                    if (e.target.checked == false) {
                      setBoundingBoxes([]);
                      setBoundingBox({});
                    }
                  }}
                  label="Highlight All "
                />

                <Dropdown className="">
                  <Dropdown.Toggle
                    variant="outline"
                    className="border w-90 bg-white"
                    style={{
                      // zIndex: 9999,
                      position: "relative",
                      lineHeight: "0.5",
                      verticalAlign: "middle"
                    }}
                  >
                    <Sliders height={15} width={15} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "100%",
                      maxHeight: " 200px",
                      overflowY: "auto",
                      minWidth: "220px",
                      overflowX: "auto",
                      zIndex: 1000,
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  >
                    <Dropdown.Item
                      style={{
                        backgroundColor: viewDeleteColumn ? "#F8C8BE" : null,
                        maxWidth: "200px",
                        marginBottom: 2
                      }}
                      onClick={() => {
                        setViewDeleteColumn(!viewDeleteColumn);
                      }}
                    >
                      View Row Delete Button
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{
                        backgroundColor: viewVerificationColumn
                          ? "#F8C8BE"
                          : null,
                        maxWidth: "200px",
                        marginBottom: 2
                      }}
                      onClick={() => {
                        setViewVerificationColumn(!viewVerificationColumn);
                      }}
                    >
                      View Verification Button
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{
                        backgroundColor: viewShiftColumn ? "#F8C8BE" : null,
                        maxWidth: "200px",
                        marginBottom: 2
                      }}
                      onClick={() => {
                        setViewShiftColumn(!viewShiftColumn);
                      }}
                    >
                      View Row Shift Button
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  className="btn btn-sm mx-1 d-flex justify-content-center align-items-center"
                  onClick={addEmptyColumn}
                  style={{
                    backgroundColor: "rgb(255, 242, 205)"
                  }}
                >
                  {" "}
                  <PlusCircleFill height={15} width={15} />
                </button>
              </div>
              <div
                style={{
                  width: "100%",
                  height:
                    tableData?.data?.processed_table?.rows?.length > 0 &&
                    "580px",
                  overflowX: "auto",
                  overflowY: "auto"
                }}
              >
                <table className="table table-bordered border-gray table-striped table-responsive">
                  <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                    <tr>
                      {tableData?.data?.processed_table?.columns?.map(
                        ({ column_uuid, column_name }, i) => {
                          return (
                            <th
                              style={{
                                padding: 6,
                                backgroundColor: "#FFF2CD",
                                textTransform: "capitalize",
                                verticalAlign: "middle"
                              }}
                              key={column_uuid}
                              className="resizable-header"
                            >
                              <ResizableCell
                                width={120}
                                style={{ minWidth: "120px" }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="outline"
                                    className="border w-100 bg-white"
                                    style={{
                                      lineHeight: "1.2",
                                      fontSize: "14px",
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {column_name}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "200px",
                                      overflowY: "scroll",
                                      overflowX: "hidden"
                                    }}
                                  >
                                    <div
                                      className="px-2 sticky-top"
                                      style={{ top: "-7px" }}
                                    >
                                      <FormControl
                                        placeholder="Search"
                                        value={searchValue}
                                        onChange={(e) =>
                                          setSearchValue(e.target.value)
                                        }
                                      />
                                    </div>
                                    <Dropdown.Item
                                      style={{
                                        lineHeight: "1",
                                        fontSize: "14px",
                                        textTransform: "capitalize"
                                      }}
                                      eventKey={"category_id"}
                                    >
                                      {column_name}
                                    </Dropdown.Item>

                                    {additionalData?.data?.processed_table_header_candidates
                                      ?.filter((it) =>
                                        it?.column_name
                                          ?.toLowerCase()
                                          ?.includes(searchValue?.toLowerCase())
                                      )
                                      ?.map((option, index) => (
                                        <Dropdown.Item
                                          style={{
                                            lineHeight: "1",
                                            fontSize: "14px"
                                          }}
                                          key={index}
                                          onClick={() => {
                                            setCachedData(
                                              "column_name",
                                              option?.column_name,
                                              i,
                                              column_uuid
                                            );
                                          }}
                                          disabled={columnNames?.includes(
                                            option?.column_name?.toLowerCase()
                                          )}
                                          // eventKey={option?.column_name}
                                        >
                                          {option?.column_name}
                                        </Dropdown.Item>
                                      ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </ResizableCell>
                            </th>
                          );
                        }
                      )}
                      {viewVerificationColumn && (
                        <th
                          style={{
                            padding: 3,
                            backgroundColor: "#FFF2CD",
                            textTransform: "capitalize",
                            verticalAlign: "middle",
                            fontWeight: "initial",
                            position: "sticky",
                            cursor: "not-allowed",
                            right:
                              viewVerificationColumn &&
                              viewDeleteColumn &&
                              viewShiftColumn
                                ? 68
                                : viewVerificationColumn && viewDeleteColumn
                                ? 32
                                : viewShiftColumn && viewVerificationColumn
                                ? 36
                                : viewShiftColumn &&
                                  viewVerificationColumn &&
                                  viewShiftColumn
                                ? 70
                                : 0,
                            backgroundColor: "#FFF2CD",
                            zIndex: 1000
                          }}
                        >
                          Item Status
                        </th>
                      )}
                      {viewDeleteColumn && (
                        <th
                          style={{
                            padding: 6,
                            backgroundColor: "#FFF2CD",
                            textTransform: "capitalize",
                            verticalAlign: "middle",
                            fontWeight: "initial",
                            position: "sticky",
                            right:
                              viewDeleteColumn &&
                              viewVerificationColumn & !viewShiftColumn
                                ? 0
                                : 38,
                            backgroundColor: "#FFF2CD",
                            zIndex: 1000
                          }}
                        >
                          {/* <Button variant="outline-secondary btn-sm " style={{border:"none"}} disabled> */}

                          <DeleteIcon
                            height={20}
                            width={20}
                            disabled
                            style={{ cursor: "not-allowed" }}
                          />
                          {/* </Button> */}
                        </th>
                      )}
                      {viewShiftColumn && (
                        <th
                          style={{
                            padding: 6,
                            backgroundColor: "#FFF2CD",
                            textTransform: "capitalize",
                            verticalAlign: "middle",
                            fontWeight: "initial",
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#FFF2CD",
                            zIndex: 1000
                          }}
                        >
                          {" "}
                          <ButtonGroup
                            vertical
                            style={{ cursor: "not-allowed" }}
                          >
                            <Button
                              variant="outline-secondary btn-sm p-1"
                              disabled
                            >
                              <ArrowBarUp height={15} width={15} />
                            </Button>
                            <Button
                              variant="outline-secondary btn-sm py-0 px-1"
                              disabled
                            >
                              <ArrowBarDown height={15} width={15} />
                            </Button>
                          </ButtonGroup>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.data?.processed_table?.rows?.map(
                      (item, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{ maxHeight: "50px", overflow: "auto" }}
                        >
                          {item?.cells
                            ?.filter((c) => columnIds?.includes(c.column_uuid))
                            ?.map((cell, cellIndex) => {
                              const column =
                                tableData?.data?.processed_table?.columns?.find(
                                  (col) => col.column_uuid === cell.column_uuid
                                );

                              //  console.log(cell)
                              return (
                                <td
                                  onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    if (stopHovering) {
                                      if (cell.bounding_box == null) {
                                        setHoveredRow(true);
                                      } else {
                                        setHoveredRow(false);
                                      }
                                      setBoundingBox({
                                        box: cell?.bounding_box,
                                        page_index: cell?.page_index
                                      });

                                      let pushed = [];

                                      item.cells?.map((cell) =>
                                        pushed.push({
                                          box: cell?.bounding_box,
                                          page_index:
                                            cell?.bounding_box?.page_index || 0
                                        })
                                      );

                                      setBoundingBoxes(pushed);
                                      setHighlightRow(true);
                                      setHighlightAll(false);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (stopHovering) {
                                      setBoundingBox({});
                                      setHighlightRow(false);
                                      setBoundingBoxes([]);
                                    }
                                  }}
                                  key={cellIndex}
                                  onContextMenu={(event) =>
                                    handleRowRightClick(
                                      event,
                                      rowIndex,
                                      cellIndex,
                                      cell?.column_uuid,
                                      cell?.cell_uuid,
                                      item?.transaction_uuid
                                    )
                                  }
                                  onClick={() =>
                                    handleEditCell(
                                      rowIndex,
                                      cellIndex,
                                      cell?.text
                                    )
                                  }
                                >
                                  {editMode.rowIndex === rowIndex &&
                                  editMode.cellIndex === cellIndex ? (
                                    column?.column_name === "Category" ? (
                                      <MyDropDown
                                        rowIndex={rowIndex}
                                        cellIndex={cellIndex}
                                        handleSelectCategory={
                                          handleSelectCategory
                                        }
                                        cellValue={cellValue}
                                        cell={cell}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        additionalData={additionalData}
                                        columnNames={columnNames}
                                      />
                                    ) : (
                                      <textarea
                                        className="form-control"
                                        width={"100%"}
                                        type="text"
                                        onCut={(e) => {
                                          handleSaveCell(
                                            rowIndex,
                                            cellIndex,
                                            ""
                                          );
                                          setCellValue("");
                                        }}
                                        value={cellValue}
                                        onChange={(e) =>
                                          setCellValue(e.target.value)
                                        }
                                        onBlur={() =>
                                          handleSaveCell(
                                            rowIndex,
                                            cellIndex,
                                            cellValue
                                          )
                                        }
                                        onDoubleClick={(e) =>
                                          e.preventDefault()
                                        }
                                        onKeyPress={(e) =>
                                          handleKeyPress(e, rowIndex, cellIndex)
                                        }
                                        autoFocus
                                      />
                                    )
                                  ) : (
                                    cell?.text
                                  )}
                                </td>
                              );
                            })}

                          {viewVerificationColumn && (
                            <td
                              style={{
                                position: "sticky",
                                // display:"flex",
                                alignItems: "center",
                                height: "100%",
                                justifyContent: "center",
                                alignContent: "center",
                                right:
                                  viewVerificationColumn &&
                                  viewDeleteColumn &&
                                  viewShiftColumn
                                    ? 65
                                    : viewVerificationColumn && viewDeleteColumn
                                    ? 32
                                    : viewShiftColumn && viewVerificationColumn
                                    ? 36
                                    : viewShiftColumn &&
                                      viewVerificationColumn &&
                                      viewShiftColumn
                                    ? 70
                                    : 0,
                                backgroundColor: "#FFF2CD"
                              }}
                            >
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                className="cursor-pointer d-flex justify-content-center align-items-center"
                                title={getTooltipValue(item?.item_master)}
                              >
                                <Tooltip id="" />
                                {item?.item_master?.human_verified == true ? (
                                  <PatchCheckFill
                                    height={15}
                                    width={15}
                                    fill="blue"
                                  />
                                ) : item?.item_master?.human_verified ==
                                  false ? (
                                  <XCircle height={15} width={15} fill="red" />
                                ) : (
                                  <PatchExclamationFill
                                    height={15}
                                    width={15}
                                  />
                                )}
                              </span>
                            </td>
                          )}
                          {viewDeleteColumn && (
                            <td
                              style={{
                                padding: 6,
                                backgroundColor: "#FFF2CD",
                                textTransform: "capitalize",
                                verticalAlign: "middle",
                                position: "sticky",
                                right:
                                  viewDeleteColumn &&
                                  viewVerificationColumn & !viewShiftColumn
                                    ? 0
                                    : 38,
                                fontWeight: "initial",
                                backgroundColor: "#FFF2CD"
                                // zIndex: 1000
                              }}
                            >
                              <DeleteIcon
                                height={20}
                                width={20}
                                onClick={() => {
                                  deleteRow(rowIndex);
                                }}
                              />
                            </td>
                          )}
                          {viewShiftColumn && (
                            <td
                              style={{
                                padding: 6,
                                backgroundColor: "#FFF2CD",
                                textTransform: "capitalize",
                                verticalAlign: "middle",
                                position: "sticky",
                                right: 0,
                                fontWeight: "initial",
                                backgroundColor: "#FFF2CD",
                                zIndex: 1
                              }}
                            >
                              <ButtonGroup vertical>
                                <Button variant="outline-secondary btn-sm p-1">
                                  <ArrowBarUp
                                    height={15}
                                    width={15}
                                    onClick={() =>
                                      handleRowShifting(rowIndex, "up")
                                    }
                                  />
                                </Button>
                                <Button variant="outline-secondary btn-sm py-0 px-1">
                                  <ArrowBarDown
                                    height={15}
                                    width={15}
                                    onClick={() =>
                                      handleRowShifting(rowIndex, "down")
                                    }
                                  />
                                </Button>
                              </ButtonGroup>
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              {contextMenu.visible && (
                <ContextMenu
                  menuOptions={menuOptions}
                  position={contextMenu.position}
                  onClose={handleCloseMenu}
                />
              )}
              <div className="d-flex justify-content-center align-items-center border border-white shadow-sm rounded p-1 bg-white my-2">
                {invoice_type !== "Summary Invoice" && (
                  <>
                    <div className="col-3 mx-2 text-center">
                      <PlusCircleFill
                        onClick={() => addEmptyRow()}
                        className="mx-auto fs-32 text-warning"
                        style={{
                          fontSize: "2rem",
                          cursor: "pointer"
                        }}
                      />
                      <p className="mx-auto text-center mt-2">Add Row</p>
                    </div>

                    {/* {history?.length > 0 && ( */}

                    <div
                      className="col-3 mx-2 text-center"
                      style={{
                        fontSize: "",
                        cursor: history.length == 0 ? "not-allowed" : "pointer",
                        marginTop: 0,
                        opacity: history.length == 0 ? "50%" : "100%"
                      }}
                    >
                      <ArrowClockwise
                        className="text-secondary"
                        onClick={() => history.length > 0 && undoLastAction()}
                        style={{
                          fontSize: "2rem",
                          cursor:
                            history.length == 0 ? "not-allowed" : "pointer",
                          marginTop: 0,
                          opacity: history.length == 0 ? "50%" : "100%"
                        }}
                      />
                      <p className="mx-auto text-center mt-2">Undo</p>
                    </div>
                  </>
                )}

                {/* )} */}
              </div>{" "}
            </>
          )}
          <div className="d-flex justify-content-between align-items-center border border-white shadow-sm rounded p-1 bg-white my-2 mt-3">
            <div
              className="col-3 mx-2 text-center "
              style={{ lineHeight: 0.5 }}
            >
              <p style={{ fontWeight: 700 }}>Action</p>
              <p
                className="text-capitalize mx-auto text-center "
                style={{ fontSize: "14px" }}
              >
                {last_update_info?.action}
              </p>
            </div>
            <div className="col-3 mx-2 text-center" style={{ lineHeight: 0.5 }}>
              <p style={{ fontWeight: 700 }}>Update At</p>
              <p
                className="text-capitalize mx-auto text-center "
                style={{ fontSize: "14px" }}
              >
                {
                  last_update_info?.updated_at
                    ?.split("T")
                    ?.join("  ")
                    ?.split(".")[0]
                }
              </p>
            </div>
            <div className="col-3 mx-2 text-center" style={{ lineHeight: 0.5 }}>
              <p style={{ fontWeight: 700 }}>Update By</p>
              <p
                className="text-capitalize mx-auto text-center "
                style={{ fontSize: "14px" }}
              >
                {last_update_info?.updated_by}
              </p>
            </div>
          </div>

          {/* Toatals Section */}
          <div className={styles.card}>
            {metaData?.invoice_type !== "Summary Invoice" ? (
              <>
                <div className={styles.button_row}>
                  <Button
                    disabled={
                      Number(
                        metaData?.document_metadata?.added_taxes?.at(-1)
                      ) === 0
                    }
                    variant="success"
                    onClick={addTax}
                  >
                    Add Tax
                  </Button>
                  <Button
                    disabled={
                      Number(
                        metaData?.document_metadata?.added_discounts?.at(-1)
                      ) === 0
                    }
                    variant="warning"
                    onClick={addDiscount}
                  >
                    Add Discount
                  </Button>
                  <Button
                    disabled={
                      Number(
                        metaData?.document_metadata?.added_fees?.at(-1)
                      ) === 0
                    }
                    variant="info"
                    onClick={addNewFeeAmt}
                  >
                    Add Fee
                  </Button>
                </div>
                <div className={styles.grid_container}>
                  <div className={styles.label}>Subtotal</div>
                  <div className={styles.value}>
                    <InputGroup>
                      <InputGroup.Text>
                        <CurrencyDollar />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        disabled
                        value={totalExtendedPrce?.toFixed(2)}
                      />
                    </InputGroup>
                  </div>

                  <div
                    style={{
                      color: "#707070",
                      fontSize: "16px",
                      fontWeight: 400,
                      alignItems: "start",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "row"
                    }}
                  >
                    Taxes
                  </div>
                  <div className="d-flex flex-column gap-2">
                    {metaData?.document_metadata?.added_taxes?.map(
                      (tax, index) => (
                        <div key={index}>
                          {index !== 0 && <div className={styles.label} />}
                          <div className={styles.value}>
                            <InputGroup>
                              <InputGroup.Text>
                                <Plus />
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                max={100}
                                step="0.1"
                                value={tax}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const value = e.target.value;

                                  // Update metadata only if the value is not empty
                                  const newData = { ...metaData };
                                  newData["document_metadata"]["added_taxes"][
                                    index
                                  ] = Number(value);

                                  setMetaData(newData);

                                  setUpdatedFields((prevFields) => {
                                    return {
                                      ...prevFields,
                                      document_metadata: {
                                        ...prevFields.document_metadata,
                                        added_taxes:
                                          newData["document_metadata"][
                                            "added_taxes"
                                          ]
                                      }
                                    };
                                  });
                                }}
                              />
                            </InputGroup>

                            <button
                              onClick={() => removeTax(index)}
                              disabled={index === 0}
                              className={`border-0 bg-transparent ${
                                index === 0 ? "invisible" : "visible"
                              }`}
                            >
                              <DashCircleFill
                                height={20}
                                width={20}
                                color="red"
                              />
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div
                    style={{
                      color: "#707070",
                      fontSize: "16px",
                      fontWeight: 400,
                      alignItems: "start",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "row"
                    }}
                  >
                    Fees
                  </div>

                  <div className="d-flex flex-column gap-2">
                    {metaData?.document_metadata?.added_fees?.map(
                      (fee, index) => (
                        <div key={index}>
                          {index !== 0 && <div className={styles.label} />}
                          <div className={styles.value}>
                            <InputGroup>
                              <InputGroup.Text>
                                <Plus />
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                max={100}
                                step="0.01"
                                value={fee}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const value = e.target.value;

                                  // Update metadata only if the value is not empty
                                  const newData = { ...metaData };
                                  newData["document_metadata"]["added_fees"][
                                    index
                                  ] = Number(value);

                                  setMetaData(newData);

                                  setUpdatedFields((prevFields) => {
                                    return {
                                      ...prevFields,
                                      document_metadata: {
                                        ...prevFields.document_metadata,
                                        added_fees:
                                          newData["document_metadata"][
                                            "added_fees"
                                          ]
                                      }
                                    };
                                  });
                                }}
                              />
                            </InputGroup>

                            <button
                              onClick={() => removeFee(index)}
                              disabled={index === 0}
                              className={`border-0 bg-transparent ${
                                index === 0 ? "invisible" : "visible"
                              }`}
                            >
                              <DashCircleFill
                                height={20}
                                width={20}
                                color="red"
                              />
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div
                    style={{
                      color: "#707070",
                      fontSize: "16px",
                      fontWeight: 400,
                      alignItems: "start",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "row"
                    }}
                  >
                    Discounts
                  </div>
                  <div className="d-flex flex-column gap-2">
                    {metaData?.document_metadata?.added_discounts?.map(
                      (tax, index) => (
                        <>
                          {index !== 0 && <div className={styles.label} />}
                          <div className={styles.value}>
                            <InputGroup>
                              <InputGroup.Text>
                                <DashLg />
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                min={0}
                                max={100}
                                step="0.01"
                                value={tax}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const value = e.target.value;

                                  // Update metadata only if the value is not empty
                                  const newData = { ...metaData };
                                  newData["document_metadata"][
                                    "added_discounts"
                                  ][index] = Number(value);

                                  setMetaData(newData);

                                  setUpdatedFields((prevFields) => {
                                    return {
                                      ...prevFields,
                                      document_metadata: {
                                        ...prevFields.document_metadata,
                                        added_discounts:
                                          newData["document_metadata"][
                                            "added_discounts"
                                          ]
                                      }
                                    };
                                  });
                                }}
                              />
                            </InputGroup>
                            <button
                              onClick={() => removeDiscount(index)}
                              disabled={index === 0}
                              className={`border-0 bg-transparent ${
                                index === 0 ? "invisible" : "visible"
                              }`}
                            >
                              <DashCircleFill
                                height={20}
                                width={20}
                                color="red"
                              />
                            </button>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
                <div className={styles.divider} />

                <div className={styles.grid_container}>
                  <div className={styles.label}>Total</div>
                  <div className={styles.value}>
                    <InputGroup>
                      <InputGroup.Text>
                        <CurrencyDollar />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        disabled
                        className={` ${
                          metaData?.document_metadata
                            ?.invoice_extracted_total == calculatedsum
                            ? "text-success fw-bolder border-success"
                            : "text-danger fw-bolder border-danger "
                        }`}
                        value={calculatedsum}
                        width={"100%"}
                      />
                    </InputGroup>
                  </div>

                  <div className={styles?.label}>Extracted Total</div>
                  <div className={styles.value}>
                    <InputGroup>
                      <InputGroup.Text>
                        <CurrencyDollar />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        onChange={(e) => {
                          e.stopPropagation();
                          const newData = { ...metaData };
                          newData["document_metadata"][
                            "invoice_extracted_total"
                          ] = e.target.value;
                          setMetaData(newData);
                          setUpdatedFields((prevFields) => {
                            return {
                              ...prevFields,
                              document_metadata: {
                                ...prevFields.document_metadata,
                                invoice_extracted_total: e.target.value
                              }
                            };
                          });
                          let copyObj = JSON.parse(JSON.stringify(metadata));
                          if (copyObj?.data[0]) {
                            copyObj[
                              "data"
                            ][0].document_metadata.invoice_extracted_total =
                              e.target.value;
                          } else {
                            copyObj[
                              "data"
                            ].document_metadata.invoice_extracted_total =
                              e.target.value;
                          }
                          queryClient.setQueryData(
                            ["document-metadata", payload],
                            copyObj
                          );
                        }}
                        className={` ${
                          metaData?.document_metadata
                            ?.invoice_extracted_total == calculatedsum
                            ? "text-success fw-bolder border-success"
                            : "text-danger fw-bolder border-danger "
                        }`}
                        value={
                          metaData?.document_metadata?.invoice_extracted_total
                        }
                        width={"100%"}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className={styles.divider} />
                <div className={styles.grid_container}>
                  <div className={styles.label}>Difference</div>
                  <div className={styles.value}>
                    <InputGroup>
                      <InputGroup.Text>
                        <CurrencyDollar />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        disabled
                        className={` ${
                          metaData?.document_metadata
                            ?.invoice_extracted_total == calculatedsum
                            ? "text-success fw-bolder border-success"
                            : "text-danger fw-bolder border-danger "
                        }`}
                        value={
                          metaData?.document_metadata
                            ?.invoice_extracted_total == calculatedsum
                            ? 0
                            : (
                                Number(
                                  metaData?.document_metadata
                                    ?.invoice_extracted_total
                                ) - Number(calculatedsum)
                              ).toFixed(2)
                        }
                        width={"100%"}
                      />
                    </InputGroup>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="mx-2">Extracted Total</div>
                <div className="mx-2">
                  <InputGroup>
                    <InputGroup.Text>
                      <CurrencyDollar />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      className={` ${
                        metaData?.document_metadata?.invoice_extracted_total ==
                        calculatedsum
                          ? "text-success fw-bolder border-success"
                          : "text-danger fw-bolder border-danger "
                      }`}
                      onChange={(e) => {
                        const newData = { ...metaData };
                        newData["document_metadata"][
                          "invoice_extracted_total"
                        ] = e.target.value;
                        setMetaData(newData);
                        let copyObj = JSON.parse(JSON.stringify(metadata));
                        if (copyObj?.data[0]) {
                          copyObj[
                            "data"
                          ][0].document_metadata.invoice_extracted_total =
                            e.target.value;
                        } else {
                          copyObj[
                            "data"
                          ].document_metadata.invoice_extracted_total =
                            e.target.value;
                        }
                        queryClient.setQueryData(
                          ["document-metadata", payload],
                          copyObj
                        );

                        setUpdatedFields((prevFields) => {
                          return {
                            ...prevFields,
                            document_metadata: {
                              ...prevFields.document_metadata,
                              invoice_extracted_total: e.target.value
                            }
                          };
                        });
                      }}
                      value={
                        metaData?.document_metadata?.invoice_extracted_total
                      }
                      width={"100%"}
                    />
                  </InputGroup>
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div
            className="d-flex justify-content-end align-content-center align-items-center  mx-2  "
            style={{ height: "40px" }}
          >
            <button
              className="shadow-lg btn mx-1 btn-sm "
              style={{ backgroundColor: "rgb(255, 242, 205)" }}
              onClick={() => {
                // setisReview(!isReview);
              }}
            >
              Engineering Issues
            </button>
            <button
              className="shadow-lg btn btn-success mx-1 btn-sm "
              onClick={() => {
                // autoCategorize();
              }}
            >
              Auto Categorize
            </button>
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              className="cursor-pointer"
              title={
                action_controls?.accept?.disabled
                  ? action_controls?.accept?.reason
                  : "Click to Accept"
              }
            >
              <Tooltip id="" />
              <button
                className="shadow-lg btn mx-1 btn-sm mb-2"
                style={{
                  backgroundColor: "rgb(255, 242, 205)",
                  marginTop: -14
                }}
                onClick={() => {
                  handleAccept();
                }}
                disabled={
                  loadingState.accepting || action_controls?.accept?.disabled
                }
              >
                {loadingState.accepting && (
                  <Spinner
                    className="mx-1"
                    animation="border"
                    role="status"
                    size="sm"
                  />
                )}
                {loadingState.accepting ? "Accepting" : "Accept"}
              </button>
            </span>
            <span
              data-bs-toggle="tooltip"
              className="cursor-pointer"
              data-bs-placement="top"
              title={
                action_controls?.reject?.disabled
                  ? action_controls?.reject?.reason
                  : "Click to Reject"
              }
            >
              <Tooltip id="" />
              <button
                className="shadow-lg btn mx-1 btn-sm btn-danger mb-2"
                style={{ marginTop: -14 }}
                onClick={() => {
                  setShowRejectionModal(true);
                  setRejectionReason(metaData?.rejection_reason);
                }}
                disabled={
                  loadingState?.rejecting || action_controls?.reject?.disabled
                }
              >
                Reject
              </button>
            </span>
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              className="cursor-pointer"
              title={
                action_controls?.save?.disabled
                  ? action_controls?.save?.reason
                  : "Click to Save"
              }
            >
              <Tooltip id="" />
              <button
                style={{ marginTop: -14 }}
                onClick={() => {
                  !action_controls?.save?.disabled && handleSave();
                }}
                className="shadow-lg btn mx-1 btn-sm btn-warning mb-2 d-flex"
                disabled={
                  loadingState?.saving || action_controls?.save?.disabled
                }
              >
                {loadingState.saving && (
                  <Spinner
                    className="mx-1"
                    animation="border"
                    role="status"
                    size="sm"
                  />
                )}
                Save
              </button>
            </span>
            <span
              data-bs-toggle="tooltip"
              className="cursor-pointer"
              data-bs-placement="right"
              title={
                action_controls?.mark_as_not_supported?.disabled
                  ? action_controls?.mark_as_not_supported?.reason
                  : "Click to Mark As Not Supported."
              }
            >
              <button
                style={{ marginTop: 0 }}
                className="shadow-lg btn mx-1 btn-sm btn-warning "
                disabled={action_controls?.mark_as_not_supported?.disabled}
                onClick={() => {
                  setShowInvalidDocumentModal(true);
                }}
              >
                Not Supported
              </button>
            </span>
          </div>
          <Modal
            show={showInvalidDocumentModal}
            onHide={() => setShowInvalidDocumentModal(false)}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Invalid Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p> Are you sure to mark this document as Not Supported ?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setMarking(true);
                  markAsNotSupported(metaData?.document_uuid, {
                    onSuccess: () => {
                      setMarking(false);
                      // queryClient.invalidateQueries({
                      //   queryKey: ["combined-table"]
                      // });

                      // queryClient.invalidateQueries({
                      //   queryKey: ["document-metadata"]
                      // });
                      setShowInvalidDocumentModal(false);
                    },
                    onError: () => setMarking(false)
                  });
                }}
              >
                {marking ? (
                  <>
                    <Spinner
                      className="mx-1"
                      animation="border"
                      role="status"
                      size="sm"
                    />
                    Marking
                  </>
                ) : (
                  "Mark"
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowInvalidDocumentModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showRejectionModal}
            onHide={() => setShowRejectionModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add A Reason to Reject
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FloatingLabel controlId="floatingTextarea2" label="Reason">
                <Form.Control
                  required
                  as="textarea"
                  name="reason"
                  onChange={(e) => setRejectionReason(e.target.value)}
                  defaultValue={rejectionReason}
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  minLength={1}
                />
                <Form.Control.Feedback type="invalid">
                  Please add a reason
                </Form.Control.Feedback>
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                form="reject_form"
                onClick={() => handleRejection()}
                disabled={loadingState.rejecting}
                className="btn btn-danger mx-2"
              >
                {loadingState.rejecting ? "Rejecting..." : "Reject"}
              </Button>
              <Button
                className="btn btn-warning"
                type="button"
                onClick={() => {
                  setShowRejectionModal(false);
                  setRejectionReason("");
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default HumanVerificationTable;
