import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import ResizableCell from "components/Utility/ResizableCell";
import { queryClient } from "lib/react-query";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { globalStoreV2 } from "store/globalStoreV2";
import { invoiceDetailStore } from "store/invoiceDetailsStore";

const CombinedTable = ({ data, document_uuid }) => {
  const { columns, rows, column_count, row_count } =
    data?.data?.processed_table;

  const {
    categoryWiseSum,
    setCategoryWiseSum,
    setTableData,
    tableData,
    operations,
    setOperations,
    setCombinedTableCopy,
    combinedTableCopy,
    setHistory,
    history
  } = invoiceDetailStore();
  const { setAdditionalData, additionalData } = globalStoreV2();
  const [columnNames, setColumnNames] = useState([]);
  const [colUUID, setColUUID] = useState(null);
  useEffect(() => {
    if (data) {
      let copyObj = JSON.parse(JSON.stringify(data));
      const { columns, rows } = copyObj?.data?.processed_table;

      // Create a mapping from column_uuid to column_order
      const columnOrderMap = columns?.reduce((acc, column) => {
        acc[column.column_uuid] = column.column_order;
        return acc;
      }, {});

      // Sort cells in each row based on column_order
      copyObj?.data?.processed_table?.rows?.forEach((row) => {
        row?.cells?.sort((a, b) => {
          return columnOrderMap[a.column_uuid] - columnOrderMap[b.column_uuid];
        });
      });

      queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    }
  }, []);

  const setCachedData = (key, value, index, col) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    const { columns, rows, column_count, row_count } =
      copyObj?.data?.processed_table;
    columns[index][key] = value;

    const filteredColumns = columns.filter(
      (col) => col.selected_column == true
    );

    const filteredRows = rows.map((row) => ({
      ...row,
      cells: row.cells.filter((cell, i) =>
        filteredColumns.some((col) => col.column_order - 1 === i)
      )
    }));

    setTableData({
      ...data,
      data: {
        ...data,
        processed_table: {
          ...data?.data?.processed_table,
          columns: filteredColumns,
          rows: filteredRows
        }
      }
    });

    queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    if (key == "column_name") {
      let newOperation = {
        type: "update_column",
        operation_order: operations?.length + 1,
        data: {
          column_uuid: columns?.find((it) => it?.column_name == value)?.[
            "column_uuid"
          ],
          selected_column: columns?.find((it) => it?.column_name == value)?.[
            "selected_column"
          ],
          column_name: value
        }
      };
      setOperations([...operations, newOperation]);
    } else if (key == "selected_column") {
      let newOperation = {
        type: "update_column",
        operation_order: operations?.length + 1,
        data: {
          column_uuid: col.column_uuid,
          selected_column: value,
          column_name: col.column_name
        }
      };
      setOperations([...operations, newOperation]);
    }
  };

  const saveHistory = () => {
    setHistory([
      ...history,
      {
        tableData: JSON.parse(JSON.stringify(tableData)),
        operations: [...operations]
      }
    ]);
  };
  const [columnIds, setColumnsIds] = useState([]);
  const deleteColumn = (colId) => {
    saveHistory();
    const copyObj = JSON.parse(JSON.stringify(data));

    const { processed_table } = copyObj.data;
    const { columns, rows } = processed_table;

    // queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    const filteredCols = columns.filter((col) => col.column_uuid !== colId);

    let isSelected = columns?.find((col) => col.column_uuid == colId)[
      "selected_column"
    ];

    if (isSelected) {
      let copyObj = JSON.parse(JSON.stringify(data));

      const filteredCols = copyObj?.data?.processed_table?.columns.filter(
        (col) => col.column_uuid !== colId
      );

      const filteredRows = data?.data?.processed_table?.rows.map((row) => {
        const filteredCells = row.cells.filter(
          (cell) => cell.column_uuid !== colId
        );
        return { ...row, cells: filteredCells };
      });

      copyObj.data.processed_table.rows = filteredRows;
      copyObj.data.processed_table.columns = filteredCols;
      let selectedFilteredColsIds = filteredCols
        ?.filter((c) => c.selected_column)
        ?.map(
          ({
            bounding_box,
            column_name,
            column_order,
            selected_column,
            column_uuid
          }) => column_uuid
        );
      const filteredRowsForTableData = data?.data?.processed_table?.rows.map(
        (row) => {
          const filteredCells = row.cells.filter((cell) =>
            selectedFilteredColsIds.includes(cell?.column_uuid)
          );
          return { ...row, cells: filteredCells };
        }
      );

      setTableData({
        ...data,
        data: {
          ...data.data,
          processed_table: {
            ...processed_table,
            columns: filteredCols?.filter((c) => c.selected_column),
            rows: filteredRowsForTableData
          }
        }
      });

      setOperations([
        ...operations,
        {
          type: "delete_column",
          operation_order: operations?.length + 1,
          data: {
            column_uuid: colId
          }
        }
      ]);

      queryClient.setQueryData(["combined-table", document_uuid], copyObj);
      setCombinedTableCopy({
        ...data,
        data: {
          ...data.data,
          processed_table: {
            ...processed_table,
            columns: copyObj.data.processed_table.columns,
            rows: filteredRows
          }
        }
      });
    } else {
      const filteredRows = rows.map((row) => {
        let filteredCells = row.cells.filter(
          (cell) => cell.column_uuid !== colId
        );
        return { ...row, cells: filteredCells };
      });

      processed_table.columns = filteredCols;
      processed_table.rows = filteredRows;

      setOperations([
        ...operations,
        {
          type: "delete_column",
          operation_order: operations?.length + 1,
          data: {
            column_uuid: colId
          }
        }
      ]);
      copyObj.data.processed_table.column_count =
        data.data.processed_table.column_count - 1;
      queryClient.setQueryData(["combined-table", document_uuid], copyObj);
    }
  };
  useEffect(() => {
    if (data?.data?.processed_table?.columns) {
      const columnIdsObjs = Object.values(
        tableData?.data?.processed_table?.columns || {}
      )?.map(
        ({
          bounding_box,
          column_name,
          column_order,
          selected_column,
          ...rest
        }) => rest
      );
      const colIds = columnIdsObjs?.reduce((acc, it, i) => {
        acc = [...acc, it?.column_uuid];
        return acc;
      }, []);
      setColumnsIds(colIds);
      const columnNamesObjs = Object.values(
        tableData?.data?.processed_table?.columns || {}
      )?.map(
        ({
          bounding_box,
          column_uuid,
          column_order,
          selected_column,
          ...rest
        }) => rest
      );
      const colnames = columnNamesObjs?.reduce((acc, it, i) => {
        acc = [...acc, it?.column_name?.toLowerCase()];
        return acc;
      }, []);
      setColumnNames(colnames);
    }
  }, [data]);
console.log(columns,rows)
  return (
    <div
      style={{
        width: "100%",
        height: "580px",
        overflowX: "auto",
        overflowY: "auto"
      }}
    >
      <table className="table table-bordered border-gray table-striped table-responsive">
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <tr>
            {columns?.map(
              (
                { column_uuid, selected_column, column_name, column_order },
                index
              ) => {
                return (
                  <th
                    key={column_uuid}
                    style={{
                      lineHeight: "1.2",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      verticalAlign: "middle"
                    }}
                    className="resizable-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        fontSize: "14px"
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check"
                        id={`checkbox_${column_uuid}`}
                        checked={selected_column}
                        onChange={(e) => {
                          setCachedData(
                            "selected_column",
                            e.target.checked,
                            index,
                            {
                              column_uuid,
                              selected_column,
                              column_name,
                              column_order
                            }
                          );
                        }}
                      />
                    </div>
                  </th>
                );
              }
            )}
          </tr>
          <tr>
            {columns?.map(
              ({ column_uuid, selected_column, column_name, column_order }) => {
                return (
                  <td
                    key={column_uuid}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                    className="resizable-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        fontSize: "14px"
                      }}
                    >
                      {column_name}
                    </div>
                  </td>
                );
              }
            )}
          </tr>
          <tr>
            {columns?.map(
              (
                { column_uuid, selected_column, column_name, column_order },
                i
              ) => {
                return (
                  <th
                    style={{
                      // width: "100px",
                      padding: 6,
                      backgroundColor: "#FFF2CD",
                      textTransform: "capitalize",
                      verticalAlign: "middle"
                    }}
                    key={column_uuid}
                    className="resizable-header"
                  >
                    <ResizableCell width={120} style={{ minWidth: "120px" }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline"
                          className="border w-100 bg-white"
                          style={{
                            lineHeight: "1.2",
                            fontSize: "14px",
                            textTransform: "capitalize"
                          }}
                        >
                          {column_name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: "200px",
                            overflowY: "scroll",
                            overflowX: "hidden"
                          }}
                          // ref={ddRef}
                        >
                          <Dropdown.Item
                            onClick={() => {
                              setColUUID(column_uuid);
                              deleteColumn(column_uuid);
                            }}
                          >
                            <DeleteIcon height={20} width={20} />
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              lineHeight: "1",
                              fontSize: "14px",
                              textTransform: "capitalize"
                            }}
                            eventKey={"category_id"}
                          >
                            {column_name}
                          </Dropdown.Item>
                          {additionalData?.data?.processed_table_header_candidates?.map(
                            (option, index) => (
                              <Dropdown.Item
                                style={{
                                  lineHeight: "1",
                                  fontSize: "14px"
                                }}
                                key={index}
                                disabled={columnNames?.includes(
                                  option?.column_name?.toLowerCase()
                                )}
                                eventKey={option?.column_name}
                                onClick={() => {
                                  setCachedData(
                                    "column_name",
                                    option?.column_name,
                                    i
                                  );
                                }}
                              >
                                {option?.column_name}
                              </Dropdown.Item>
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </ResizableCell>
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {rows?.map((item, index) => {
            return (
              <tr key={index}>
                {item?.cells?.map((cell, i) => {
                  return (
                    <td
                      style={{
                        fontSize: "14px",
                        backgroundColor: `${
                          columns?.find(
                            (col) => col?.column_uuid == cell?.column_uuid
                          )?.["selected_column"]==true
                            ? "#7efc5b"
                            : "#f78879"
                        }`
                      }}
                      key={i}
                    >
                      {cell?.text}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CombinedTable;
