import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { queryClient } from "lib/react-query";
import { toChartData } from "./transform";
import useStatisticsStore from "./store";
import { useGlobalFilterParams } from "store/globalFIlter";
import { instance } from "lib/axios";

const statsQuery = {
  queryKey: ["invoice_statistics"],
  queryFn: async () => {
    try {
      const postData = {
        page_index: 1,
        invoice_size: pageSize,
        mode: "all_invoices",
        filters: {
          human_verification: "both",
          human_verified: "both",
          invoice_type: "all",
          invoice_detection_status: "all",
          vendor_name: null,
          start_date: null,
          end_date: null,
        },
      };

      const response = await axiosInstance.post(
        "/invoice_statistics",
        postData
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
};

const vendorQuery = {
  queryKey: ["invoice_statistics", "vendor_list"],
  queryFn: async () => {
    const data = await queryClient.ensureQueryData(statsQuery);
    const vendors = data.vendor_based_statistics.map((s) => s.vendor_name);
    return vendors;
  },
};
export const useAllStatistics = () => useQuery(statsQuery);

export const useVendorList = () => useQuery(vendorQuery);

const pageSize = 5;

export const useInvoiceTable = (
  pageNumber,
  newFilter,
  invoiceType,
  dateRange,
  vendorName,
  verification,
  invoiceDetectionStatus,
  verified,
  invoiceRerunStatus
) => {
  const {
    selectedVendor,
    tableFilter,
    activeTab,
    allPagination,
    vendorPagination,
  } = useStatisticsStore();
  const filters = useGlobalFilterParams();
  return useQuery({
    // placeholderData: (previousData, q) => previousData,
    queryKey: [
      "invoice_statistics",
      "table",
      pageNumber,
      activeTab,
      selectedVendor,
      tableFilter,
      newFilter,
      invoiceType,
      dateRange,
      vendorName,
      verification,
      invoiceDetectionStatus,
      verified,
      invoiceRerunStatus,
    ],
    queryFn: async () => {
      try {
        const postData = {
          page_index: pageNumber,
          invoice_size: 5,
          mode: activeTab === "all" ? "all_invoices" : "vendor_based_invoices",
          filters: filters,
        };

        const response = await axiosInstance.post(
          "/invoice_statistics",
          postData
        );


        return {
          tableData: response.data.data.table_data,
          totalPages: response.data.data.table_data.total_pages,
        };
      } catch (error) {
        throw error;
      }
    },
  });
};

export const useInvoiceStatistics = (
  pageNumber,
  newFilter,
  invoiceType,
  dateRange,
  vendorName,
  verification,
  invoiceDetectionStatus,
  verified,
  invoiceRerunStatus,
) => {
  const {
    selectedVendor,
    tableFilter,
    activeTab,
    allPagination,
    vendorPagination,
  } = useStatisticsStore();
  const filters = useGlobalFilterParams();
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: [
      "invoice_statistics",
      "table",
      pageNumber,
      activeTab,
      selectedVendor,
      tableFilter,
      newFilter,
      invoiceType,
      dateRange,
      vendorName,
      verification,
      invoiceDetectionStatus,
      verified,
      invoiceRerunStatus,
    ],
    queryFn: async () => {
      try {
        const postData = {
          page_index: pageNumber,
          invoice_size: 5,
          mode: activeTab === "all" ? "all_invoices" : "vendor_based_invoices",
          filters: filters,
        };

        const response = await axiosInstance.post(
          "/invoice_statistics",
          postData
        );
   
        return toChartData(response.data.data.statistics,response.data.data.statistics_in_percentage);
      } catch (error) {
        throw error;
      }
    },
  });
};



export const useGetInvoiceStats=(payload)=>{
  return useQuery({
    queryKey:['document-stats',payload],
    queryFn:async()=>{
      const {
        start_date,
        end_date,
        auto_accepted,
        human_verified,
        detected,
        invoice_type,
        rerun_status,
        restaurant,
        vendor,
        page_size,
        page,
        clickbacon_status,
        sort_order
      } = payload;
      const apiUrl = `/api/document/statistics/?page_size=${page_size}&page=${page}&invoice_type=${invoice_type}&end_date=${end_date}&start_date=${start_date}&auto_accepted=${auto_accepted}&human_verified=${human_verified}&detected=${detected}&rerun_status=${rerun_status}&clickbacon_status=${clickbacon_status}&restaurant=${restaurant}&vendor=${vendor}&sort_order=${sort_order}`;

      try {
        const response = await instance.get(apiUrl);
        return response?.data
      } catch (error) {
        return error?.response?.data
      }
    }
  })
}