import "./App.css";
import React from "react";
import { BrowserRouter} from "react-router-dom";
import Router from "./routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('Service Worker registered with scope:', registration.scope);
  }).catch(error => {
    console.log('Service Worker registration failed:', error);
  });
}
function App() { 

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
