import ColorIndications from "assets/colorIndications";
import Skeleton from "components/common/Skeleton";
import { HomePagination } from "components/layout/HomePagination";
import { useState } from "react";
import { PatchCheckFill } from "react-bootstrap-icons";
import Table from "react-bootstrap/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import useInvoicePaginationActions from "store/invoicePagination";
import { formatDate } from "utils/format";
import useStatisticsStore from "../store";

const TH = ({ children }) => (
  <th className="text-center p-2" style={{ backgroundColor: "#FFF2CD" }}>
    {children}
  </th>
);

const stopPropagation = (event) => {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

export const StatisticsTable = ({data,isLoading,totalPages}) => {
  const [searchParams]=useSearchParams()
  let page = searchParams.get("page") || 1;
  const navigate = useNavigate();
  const activeTab = useStatisticsStore((state) => state.activeTab);

  const { setTempValue, setPageNumber } = useInvoicePaginationActions();
  const [reasonsVisibility, setReasonsVisibility] = useState(
    Array(11).fill(false)
  );
  const handleReasonsToggle = (e, i) => {
    stopPropagation(e);
    setReasonsVisibility((l) => l.map((e, j) => (j === i ? !e : e)));
  };
 

  return (
    <div
    className="mx-5"
    style={{
      marginTop: "35px"
    }}
  >
    <>
      <ColorIndications />
      <Table
        striped
        bordered
        hover
        variant="light"
        responsive
        className="shadow-sm"
      >
        <thead>
          <tr>
            <th>InvoiceID</th>
            <th>Source/Channel</th>
            <th>Restaurant</th>
            <th>Vendor</th>
            <th>Load Date</th>
            <th>Balance Status</th>
            <th>Final Status</th>
            <th>clickBACON Status</th>
            <th>Failure Cause Code</th>
            <th>Accepted/Rejected</th>
            <th>Rejected Reasons</th>
            <th>Invoice Type</th>
            <th>Human Verification Date</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? [0, 1, 2, 3, 4]?.map((_, index) => {
                return (
                  <tr key={index}>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map(
                      (_, i) => {
                        return (
                          <td
                            key={i}
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })
            : data?.length > 0 &&
              data?.map((item, i) => (
                <>
                  <tr
                    onClick={() => {
                      navigate(
                        `/invoice-details/?page_number=${
                          (page - 1) * 8 + (i + 1)
                        }`
                      );
                    }}
                    key={`${item.invoice_index}${i}`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    style={{ height: "65px", cursor: "pointer" }} // Set the height here
                  >
                    {/* <Tooltip id={item?.invoice_index} /> */}
                    <td>{item?.invoice_number}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item.channel}
                    </td>
                    <td>
                      {item?.restaurant?.restaurant_name ||
                        item?.restaurant?.restaurant_id}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item?.vendor?.vendor_name}
                      {item?.vendor?.human_verified && (
                        <PatchCheckFill
                          style={{ color: "blue", marginLeft: "5px" }}
                        />
                      )}
                    </td>
                    <td>{formatDate(item?.date_uploaded)}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item?.balance_type}
                    </td>

                    <td>
                      {!["auto", "manual"].includes(
                        item?.balance_type?.toLowerCase()
                      ) ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={"red"}
                        >
                          <circle fill={"red"} cx="8" cy="8" r="8" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={"green"}
                        >
                          <circle fill={"green"} cx="8" cy="8" r="8" />
                        </svg>
                      )}
                    </td>
                    <td>{item?.clickbacon_status}</td>

                    <td style={{ verticalAlign: "top" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={
                          item.document_failed_cause_code === 0 ||
                          item.document_failed_cause_code === 1 ||
                          item.document_failed_cause_code === 2 ||
                          item.document_failed_cause_code === 3 ||
                          item.document_failed_cause_code === 4
                            ? "red"
                            : item.document_failed_cause_code === 5
                            ? "#FFEF00"
                            : item.document_failed_cause_code === 6
                            ? "orange"
                            : item.document_failed_cause_code === -1
                            ? "green"
                            : "black"
                        }
                      >
                        <circle
                          fill={
                            item.document_failed_cause_code === 0 ||
                            item.document_failed_cause_code === 1 ||
                            item.document_failed_cause_code === 2 ||
                            item.document_failed_cause_code === 3 ||
                            item.document_failed_cause_code === 4
                              ? "red"
                              : item.document_failed_cause_code === 5
                              ? "#FFEF00"
                              : item.document_failed_cause_code === 6
                              ? "orange"
                              : item.document_failed_cause_code === -1
                              ? "green"
                              : "black"
                          }
                          cx="8"
                          cy="8"
                          r="8"
                        />
                      </svg>
                    </td>

                    <td style={{ textTransform: "capitalize" }}>
                      {item?.auto_accepted === true
                        ? "Auto Accepted"
                        : item?.rejected === true
                        ? "Rejected"
                        : item?.human_verified == true
                        ? "Accepted"
                        : ""}
                    </td>

                    <td>{item?.rejection_reason}</td>
                    <td>{item?.invoice_type}</td>
                    <td>{formatDate(item?.human_verified_date)}</td>
                  </tr>
                </>
              ))}
        </tbody>
      </Table>
      <HomePagination totalPages={totalPages} />
    </>
  </div>
  );
};
