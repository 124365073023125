import "./direction.css";
import { useVendorList } from "modules/invoiceCreation/components/MetaData";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
import { useGetVendorsList } from "components/api/api";
import useFilterStore from "store/persistentFilterStore";
const Direction = () => {
  const navigate = useNavigate();
  const getNestedFilterValue = useGetNestedFilterValue();
  const rest_id = getNestedFilterValue(["filters", "restaurant_id"]);
  const { data, isLoading } = useGetVendorsList(true);
  const [show, setshow] = useState(false);
  const [selected, setSelected] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const clickHandler = (id) => {
    if (selected === "vendor-verification") {
      navigate(`/vendor-consolidation-v2/${id}`);
    } else {
      navigate(`/fast-item-master-verification-v2/${id}`);
    }
  };

  // Filter vendors based on search query
  const filteredVendors = data?.data?.vendor_names?.filter((vendor) =>
    vendor.vendor_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { setDefault } = useFilterStore();
  useEffect(() => {
    setDefault();
  }, []);

  return (
    <Container
      fluid
      className="mt-0"
      style={{ height: "100%", overflow: "hidden" }}
    >
      <Row style={{ height: "100vh", overflow: "hidden" }}>
        {/* <NavHeader view="direction" /> */}
        <nav
          className="navbar p-3 shadow-sm"
          style={{
            backgroundColor: "#FDFFD0",
            position: "fixed",
            top: "0",
            bottom: "100",
            zIndex: "100",
            width: "100%"
          }}
        >
          <span
            style={{
              marginLeft: "20px",
              fontSize: "1.4em",
              fontWeight: "bold"
            }}
          >
            Invoice Intelligence Platform
          </span>
        </nav>
        <Col
          md={4}
          xs={12}
          className="bg-light p-4  "
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div
            style={{
              marginBottom: "0%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              height: "100%",
              width: "100%"
            }}
          >
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                navigate("/home");
              }}
            >
              Invoice Balancing
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                setSelected("vendor-verification");
                setshow(true);
              }}
            >
              Vendor & Branch Verification
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                setSelected("item-master");
                setshow(true);
              }}
            >
              Fast Item Master Verification
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/user-activity`);
              }}
            >
              User Activity
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/invoice-assignment`);
              }}
            >
              Invoice Assignment
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/home?assigned_to=${localStorage.getItem("user_id")}`);
              }}
            >
              My Tasks
            </button>
          </div>
        </Col>

        <Col md={8} className="d-none d-md-block p-0">
          <iframe
            title="direction"
            src={`${process.env.PUBLIC_URL}/direction.svg`}
            alt="Registration"
            className="img-fluid "
            style={{ height: "900px", width: "600px", marginTop: "10%" }}
          />
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={() => setshow(!show)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Available Vendors
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
          <input
            type="text"
            placeholder="Search vendor name"
            className="vendorSearch"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {filteredVendors
            ?.sort((a, b) => b.human_verified - a.human_verified)
            ?.map((vendor, index) => (
              <p
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  clickHandler(vendor?.vendor_id);
                }}
                className="vendorNames"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px 20px 5px 10px",
                  border: "0.5px solid gray",
                  borderRadius: "4px",
                  cursor: "pointer"
                }}
              >
                <span>{vendor.vendor_name}</span>{" "}
                <span>
                  {vendor.human_verified && <PatchCheckFill fill="blue" />}
                </span>
              </p>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setshow(!show)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Direction;
