import { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, Spinner, Table } from "react-bootstrap";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  Download,
  PatchCheckFill,
  PenFill,
  SaveFill,
  Trash3Fill,
  XCircleFill,
  ZoomIn,
  ZoomOut
} from "react-bootstrap-icons";
import { Document, Page } from "react-pdf";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { globalStoreV2 } from "store/globalStoreV2";
import { formatDate } from "utils/format";
import {
  getVendorBranchPdfs,
  useDeleteVendorBranch,
  useGetVendorNames,
  useMigrateVendorBranch,
  useUpdateBranchDetails
} from "../api";
import { instance } from "lib/axios";
const PdfViewerComponent = ({ vendorId, branchId, setViewInvoice }) => {
  const [pdfs, setPdfs] = useState([]);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const getPdfs=async()=>{
      setLoading(true);

      try {
         const response=await instance.get(`/api/vendor-branch/${branchId}/pdf/`);
         setPdfs(response?.data?.data)
         setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    getPdfs()
  }, [branchId, vendorId]);

  const handlePrevClick = () => {
    if (currentPdfIndex > 0) {
      setCurrentPdfIndex(currentPdfIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPdfIndex < pdfs.length - 1) {
      setCurrentPdfIndex(currentPdfIndex + 1);
    }
  };

  const currentPdf = pdfs.length > 0 ? pdfs[currentPdfIndex] : null;
  const currentPdfUrl = currentPdf ? currentPdf?.document_link : null;
  const currentPdfSource = currentPdf ? currentPdf?.document_source : null;
  return (
    <div className="container border border-warning rounded p-3 mb-4">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="row justify-content-center mt-3">
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-primary w-100"
                onClick={handlePrevClick}
                disabled={currentPdfIndex === 0}
              >
                Previous
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-primary w-100"
                onClick={handleNextClick}
                disabled={currentPdfIndex === pdfs.length - 1}
              >
                Next
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-danger w-100"
                onClick={() => setViewInvoice(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div className="">
            <div className="">
              {currentPdfSource === "google_drive" ? (
                <iframe
                  title="pdf"
                  src={`${currentPdfUrl?.replace(
                    "uc?id=",
                    "file/d/"
                  )}/preview?embedded=true`}
                  width="100%"
                  height="470"
                  allow="autoplay"
                ></iframe>
              ) : currentPdfSource === "azure_blob" ||
                currentPdfSource === "clickbacon" ? (
                <DocumentComponent pdfUrl={currentPdfUrl} />
              ) : (
                <div>No PDFs available</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const DocumentComponent = ({ pdfUrl }) => {
  const [pdfScale, setPdfScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNum(1);
  }
  return (
    <>
      <div
        className="ml-50 p-2 rounded"
        style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "16px" }}
        >
          <ZoomIn
            height={20}
            width={20}
            disabled={pdfScale >= 8}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s * 2)}
          />
          <ZoomOut
            height={20}
            width={20}
            disabled={pdfScale <= 0.1}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s / 2)}
          />
          <button
            type="button"
            disabled={pageNum <= 1}
            onClick={previousPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNum >= numPages}
            onClick={nextPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Next
          </button>
          <ArrowClockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
          />
          <ArrowCounterclockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
          />
          <div>
            <span>
              Page {pageNum} of {numPages}
            </span>
            <Download
              height={20}
              width={20}
              className="cursor-pointer mx-4"
              onClick={() => window.open(pdfUrl, "_blank")}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "470px", overflow: "auto" }}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNum} scale={pdfScale} rotate={rotation} />
        </Document>
      </div>
    </>
  );
};

export default function VendorBranchesTable({ data }) {
  const { vendorID } = useParams();
  const [edit, setEdit] = useState(null);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [updatedName, setUpdatedname] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [branchToMigrate, setBranchToMigrate] = useState(null);
  const [showMigrateModal, setShowMigrateModal] = useState(false);
  const [vendorBranchIdToDelete, setVendorBranchIdToDelete] = useState(null);
  const [vendorBranchNameToDelete, setVendorBranchNameToDelete] =
    useState(null);
  const [migrateToVendor, setMigrateToVendor] = useState(null);
  const [migrating, setMigrating] = useState(false);
  const [new_vendor_id, setNewVendorId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const { masterVendor, setMasterVendor, vendorsToMerge, setVendorsToMerge } =
    globalStoreV2();
  const [searchQuery, setSearchQuery] = useState("");
  const { data: vendorNames, isLoading } = useGetVendorNames();
  const { mutate: saveAddress } = useUpdateBranchDetails();
  const { mutate: migrateBranch } = useMigrateVendorBranch();
  const { mutate: deleteBranch } = useDeleteVendorBranch();
  const handleRenameVendor = (branch_id, vendor_address) => {
    setSaving(true);
    saveAddress(
      { branch_id, payload: { vendor_address } },
      {
        onSuccess: () => {
          setSaving(false);
          setUpdatedname("");
          setEdit(null);
        },
        onError: () => {
          setSaving(false);
        }
      }
    );
  };

  return (
    <div className="mx-5" style={{ marginTop: "50px", display: "flex" }}>
      <div
        className={`table-responsive  ${
          viewInvoice ? "col-md-6 mx-2" : "col-md-12"
        }`}
      >
        <Table
          striped
          bordered
          hover
          variant="light"
          responsive
          className="shadow-sm"
        >
          <thead>
            <tr>
              <th style={{ width: "40%", verticalAlign: "middle" }}>
                Vendor Address{" "}
              </th>
              <th style={{ width: "20%" }}>Document Count</th>
              <th style={{ width: "20%" }}>Created Date</th>
              <th style={{ width: "10%" }}>Select Master</th>
              <th style={{ width: "10%" }}>Select for Merge</th>
              <th style={{ width: "10%" }}>Migrate</th>
              <th style={{ width: "10%" }}>View Invoice</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.branches?.map((item, index) => (
              <tr key={index}>
                <td>
                  {edit !== item.branch_id ? (
                    // {true ? (
                    <Link
                      to={`/vendor-v2/${vendorID}/branch/${item.branch_id}`}
                      style={{ color: "black", textDecoration: "underline" }}
                    >
                      {item.vendor_address}{" "}
                      {item.human_verified && <PatchCheckFill fill="blue" />}
                    </Link>
                  ) : (
                    <textarea
                      className="form-control"
                      type="text"
                      value={updatedName}
                      onChange={(e) => setUpdatedname(e.target.value)}
                    />
                  )}
                </td>
                <td>{item.document_count}</td>
                <td>{formatDate(item.created_date)}</td>
                <td>
                  <input
                    className="form-check-input border border-dark"
                    type="radio"
                    name="master"
                    value={item.branch_id}
                    checked={masterVendor === item.branch_id}
                    onChange={(e) => setMasterVendor(e.target.value)}
                    disabled={vendorsToMerge?.includes(item.branch_id)}
                  />
                </td>
                <td>
                  <input
                    className="form-check-input border border-dark"
                    type="checkbox"
                    value={item.branch_id}
                    checked={vendorsToMerge.includes(item.branch_id)}
                    disabled={
                      item?.human_verified || masterVendor === item.branch_id
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setVendorsToMerge([...vendorsToMerge, e.target.value]);
                      } else {
                        setVendorsToMerge(
                          vendorsToMerge.filter((id) => id !== e.target.value)
                        );
                      }
                    }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setShowMigrateModal(true);
                      setBranchToMigrate(item?.branch_id);
                    }}
                  >
                    Migrate
                  </button>
                </td>
                <td>
                  {viewInvoice && selectedBranch === item.branch_id ? (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        setViewInvoice(false);
                        setSelectedBranch(null);
                      }}
                    >
                      Hide
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setViewInvoice(true);
                        setSelectedBranch(item.branch_id);
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
                <td>
                  {edit === item.branch_id && saving == false ? (
                    // {false ? (
                    <div className="d-flex">
                      <SaveFill
                        className="mx-2 my-2"
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={() => {
                          if (updatedName === item.vendor_address) {
                            setEdit(null);
                            setUpdatedname("");
                            return;
                          }
                          handleRenameVendor(item.branch_id, updatedName);
                        }}
                      />
                      <XCircleFill
                        className="mx-2 my-2"
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          setEdit(null);
                          setUpdatedname("");
                        }}
                      />
                    </div>
                  ) : edit == item.branch_id && saving ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <PenFill
                      className="mx-2 my-2"
                      style={{
                        cursor: "pointer",
                        color: edit ? "grey" : "black"
                      }}
                      onClick={() => {
                        setEdit(item?.branch_id);
                        setUpdatedname(item?.vendor_address);
                      }}
                    />
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setVendorBranchIdToDelete(item.branch_id);
                      setVendorBranchNameToDelete(item.vendor_address);
                      setShowDeleteModal(true);
                    }}
                  >
                    <Trash3Fill />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {viewInvoice && (
        <PdfViewerComponent
          setViewInvoice={setViewInvoice}
          vendorId={vendorID}
          branchId={selectedBranch}
        />
      )}
      <ToastContainer />
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setVendorBranchIdToDelete(null);
          setVendorBranchNameToDelete(null);
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          <strong>{vendorBranchNameToDelete}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            disabled={deleting}
            onClick={() => {
              setDeleting(true);
              setShowDeleteModal(false);
              deleteBranch(vendorBranchIdToDelete, {
                onSuccess: () => {
                  setBranchToMigrate(null);
                  setVendorBranchIdToDelete(null);
                  setDeleting(false);
                },
                onError: () => {
                  setDeleting(false);
                }
              });
            }}
          >
            {deleting ? "Deleting..." : "Delete"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowDeleteModal(false);
              setVendorBranchIdToDelete(null);
              setVendorBranchNameToDelete(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>{" "}
      {/* Vendor Migrate Modal */}
      <Modal
        show={showMigrateModal}
        onHide={() => {
          setMigrateToVendor(null);
          setShowMigrateModal(false);
          setBranchToMigrate(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Vendor Branch Migrate Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="branch">Branch</label>
          <input
            type="text"
            className="form-control"
            value={
              data?.branches?.find(
                (branch) => branch.branch_id === branchToMigrate
              )?.vendor_address
            }
            disabled
          />
          <label htmlFor="vendor">Select Vendor to Migrate to:</label>
          <Dropdown
            onSelect={(v) => {
              if (v === "-1") {
                return;
              }
              setMigrateToVendor(v);
            }}
          >
            <Dropdown.Toggle
              variant="outline"
              className="border w-100 bg-white h-100"
              style={{
                lineHeight: "1.2",
                fontSize: "14px",
                textAlign: "left"
              }}
            >
              {vendorNames?.data?.vendor_names?.find(
                (vendor) => vendor.vendor_id === migrateToVendor
              )?.vendor_name || "All Vendors"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxWidth: "300px",
                minHeight: "100px",
                maxHeight: "250px",
                overflowY: "scroll",
                overflowX: "scroll"
              }}
            >
              {isLoading ? (
                <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <FormControl
                    value={searchQuery}
                    type="text"
                    className="form-control mx-auto mb-4 sticky-top "
         
                    placeholder="Search"
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    style={{
                      width: "90%",
                      top:"-4px"
                    }}
                  />
                  <Dropdown.Item eventKey={null}>{"All Vendors"}</Dropdown.Item>
                  {vendorNames?.data?.vendor_names
                    ?.filter((it) =>
                      it?.vendor_name
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase())
                    )
                    ?.map((vendor, i) => (
                      <Dropdown.Item
                        className="d-flex justify-content-between align-items-center"
                        key={vendor.vendor_id}
                        onClick={() => setNewVendorId(vendor?.vendor_id)}
                        eventKey={vendor.vendor_id}
                      >
                        {vendor.vendor_name}
                        {vendor.human_verified && (
                          <PatchCheckFill fill="blue" />
                        )}
                      </Dropdown.Item>
                    ))}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
          {/* <CustomDropdown
            data={vendorData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            Objectkey={"vendor_id"}
            displayKey={"vendor_name"}
            className="w-auto"
            maxWidth="100%"
            minWidth="100%"
            value={
              vendorData?.find((vendor) => vendor.vendor_id === migrateToVendor)
                ?.vendor_name || "All Vendors"
            }
            // setValue={setValue}
            // Objectkey={'label'}
            showVerificationBadge={true}
            verification_key={"human_verified"}
            onSelect={(v) => {
              setMigrateToVendor(v);
            }}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={() => {
              setMigrating(true);
              migrateBranch(
                { branch_id: branchToMigrate, new_vendor_id },
                {
                  onSuccess: () => {
                    setMigrating(false);
                    setNewVendorId(null);
                    setBranchToMigrate(null);
                  },
                  onError: () => {
                    setMigrating(false);
                  }
                }
              );
            }}
            disabled={migrating}
          >
            {migrating ? "Migrating..." : "Migrate"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowMigrateModal(false);
              setMigrateToVendor(null);
              setBranchToMigrate(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
