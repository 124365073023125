import { useQuery } from "@tanstack/react-query";
import { useGetRestaurantList } from "components/api/api";
import CustomDropdown from "components/CustomDropdown";
import useUpdateParams from "hooks/useUpdateParams";
import { axiosInstance } from "lib/axios";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFilterStore from "store/persistentFilterStore";
export const useRestaurantList = () =>
  useQuery({
    queryKey: ["restaurants"],
    queryFn: async () => {
      const res = await axiosInstance.get("/get_restaurant_ids");
      return res.data.restaurants;
    }
  });

export const RestaurantDropdown = () => {
  const updateParams = useUpdateParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams] = useSearchParams();
  let restaurant_id =
    searchParams.get("restaurant_id") || searchParams.get("restaurant") || "";
  const { setFilters } = useFilterStore();
  const { data, isLoading } = useGetRestaurantList();
  return (
    <CustomDropdown
      data={
        data
          ? [
              { restaurant_name: "None", restaurant_id: undefined },
              ...data?.data
            ]
          : []
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      
      className="w-auto"
      maxWidth="200px"
      minWidth="130px"
      menuWidth="300px"
      Objectkey="restaurant_id"
      displayKey="restaurant_name"
      value={
        data?.data?.find((it) => it?.restaurant_id == restaurant_id)?.[
          "restaurant_name"
        ] ||
        data?.data?.find((it) => it?.restaurant_id == restaurant_id)?.[
          "restaurant_id"
        ] ||
        "All Restaurants"
      }
      onSelect={(v) => {
        updateParams({ restaurant_id: v, page: 1, restaurant: v });
        setFilters({ restaurant_id: v, page: 1, restaurant: v });
      }}
    />
  );
};
