import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "lib/axios";
import { toast } from "react-toastify";

export const useGetDocumentMetadata = (payload) => {
  return useQuery({
    queryKey: ["document-metadata", payload],
    queryFn: async () => {
      let apiUrl = ``;

      if (payload.document_uuid) {
        apiUrl = `/api/document/${payload.document_uuid}/metadata/`;
      } else {
        apiUrl = `/api/document/metadata/?page=${payload?.page}&page_size=1&vendor=${payload?.vendor_id}&invoice_type=${payload?.invoice_type}&invoice_detection_status=${payload?.invoice_detection_status}&rerun_status=${payload?.rerun_status}&auto_accepted=${payload?.auto_accepted}&start_date=${payload?.start_date}&end_date=${payload?.end_date}&clickbacon_status=${payload.clickbacon_status}&human_verified=${payload?.human_verified}&sort_order=${payload?.sort_order}&restaurant=${payload?.restaurant}&human_verification_required=${payload?.human_verification}&assigned_to=${payload?.assigned_to||""}`;
      }

      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useUpdateDocumentMetadata = () => {
  return useMutation({
    mutationFn: async ({ data, document_uuid }) => {
      const response = await instance.put(
        `/api/document/${document_uuid}/metadata/`,
        { ...data }
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 2000
        }
      );
    }
  });
};

export const useFindDuplicateInvoices = (document_uuid) => {
  return useQuery({
    queryKey: ["duplicate-invoices", document_uuid],
    queryFn: async () => {
      const apiUrl = `/api/document/${document_uuid}/find-duplicates/`;

      try {
        if (document_uuid) {
          const response = await instance.get(apiUrl);
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useGetRawTable = (document_uuid) => {
  return useQuery({
    queryKey: ["raw-table", document_uuid],
    queryFn: async () => {
      // /api/document/:document_uuid/items/

      try {
        if (document_uuid) {
          const response = await instance.get(
            `/api/document/${document_uuid}/items/`
          );
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useGetCombinedTable = (document_uuid) => {
  return useQuery({
    queryKey: ["combined-table", document_uuid],
    queryFn: async () => {
      // /api/document/:document_uuid/items/

      try {
        if (document_uuid) {
          const response = await instance.get(
            `/api/document/${document_uuid}/table/`
          );
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useMarkAsNotSupported = () => {
  return useMutation({
    mutationFn: async (document_uuid) => {
      const response = await instance.post(
        `/api/document/${document_uuid}/mark-as-unsupported/`
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 2000
        }
      );
    }
  });
};

export const useUpdateDocumentTable = () => {
  return useMutation({
    mutationFn: async ({ data, document_uuid }) => {
      const response = await instance.put(
        `/api/document/${document_uuid}/table/`,
        {
          operations: data
        }
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${
          data?.data?.updated_fields?.length > 0
            ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
            : ``
        }`,
        {
          autoClose: 2000
        }
      );
    }
  });
};

export const useExtractOcrText = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await instance.post(
        `/api/utils/ocr-text-extract/`,
        data
      );
      return response?.data;
    }
  });
};

export const useAutoCalculate = () => {
  return useMutation({
    mutationFn: async ({ document_uuid, row }) => {
      const response = await instance.post(
        `/api/document/${document_uuid}/table/auto-calculate-row/`,
        {
          ...row
        }
      );

      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      return data;
    }
  });
};

export const useUpdateVendorOrBranch = () => {
  return useMutation({
    mutationFn: async ({ document_uuid, data, Key }) => {
      let apiUrl = ``;

      if (Key == "vendor") {
        apiUrl = `/api/document/${document_uuid}/update-vendor/`;
      } else {
        apiUrl = `/api/document/${document_uuid}/update-branch/`;
      }

      let response = await instance.post(apiUrl, { ...data });
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(data?.message);
    }
  });
};
