import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFetchVendorNotes, useAddVendorNote } from "./api";
import { SendPlusFill } from "react-bootstrap-icons";
import useAppStore from "store/global";
import DOMPurify from "dompurify";
import { useGetVendorNotes } from "modules/vendorConsolidation/api";
import { useParams, useSearchParams } from "react-router-dom";
import useUpdateParams from "hooks/useUpdateParams";
import { useCreateVendorNote } from "modules/vendorConsolidation/api";

const VendorNote = ({ message }) => {
  const createMarkup = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html).replace(/\n/g, "<br>");
    return { __html: sanitizedHtml };
  };

  const user = localStorage.getItem("username");
  const isOwnMessage = message.created_by === user;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "10px",
        justifyContent: isOwnMessage ? "flex-end" : "flex-start",

      }}
    >
      {!isOwnMessage && (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold"
          }}
          className="bg-primary"
        >
          cB
        </div>
      )}
      <div
        style={{
          backgroundColor: isOwnMessage ? "#d4edda" : "#f8f9fa",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: isOwnMessage ? "10px" : "10px",
          marginRight: isOwnMessage ? "10px" : "0px",
          maxWidth: "80%",
          position: "relative",
          marginBottom: "10px",
          alignSelf: isOwnMessage ? "flex-end" : "flex-start"
        }}
      >
        <div dangerouslySetInnerHTML={createMarkup(message.note)} />
        <div
          style={{
            float: "right",
            bottom: "5px",
            right: "10px",
            color: "gray",
            fontSize: "12px"
          }}
        >
          {new Date(message.created_date).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

export default function Notes({ name, show, setShow, vendorId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const updateParams = useUpdateParams();
  const [notes, setNotes] = useState([]);
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page_Index") || 1;
  const [hasMore, setHasMore] = useState(true);
  const [note, setNote] = useState("");
  const [creating, setCreating] = useState(false);
  const handleClose = () => setShow(false);
  // const { mutate, isPending } = useAddVendorNote();
  const { vendorID: vendor_id } = useParams();

  const { data, isLoading, isError } = useGetVendorNotes({
    vendor_id:vendor_id||vendorId,
    page: page
  });
  const { mutate: createNote } = useCreateVendorNote();
  useEffect(() => {
    if (data?.data?.length > 0) {
      setNotes((prevNotes) => [
        ...prevNotes,
        ...data?.data?.filter(
          (newNote) =>
            !prevNotes?.some((prevNote) => prevNote?.note === newNote?.note)
        )
      ]);
    }
  }, [data]);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            height: "calc(100% - 60px)",
            zIndex:10000000,
            background:"white"
          }}
        >
          {isError && <div>Error</div>}
          <div
            id="scrollableDiv"
            style={{
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
              background:"white",
              zIndex:100000000
            }}
          >
            <InfiniteScroll
            
              dataLength={notes?.length}
              next={() => {
                if (!data?.is_final_page) {
                  updateParams({ page_Index: Number(page) + 1 });
                }
              }}
              hasMore={!data?.is_final_page}
              loader={isLoading && <div>Loading...</div>}
              endMessage={<div></div>}
              scrollableTarget="scrollableDiv"
              inverse={true}
            >
              {notes
                ?.sort(
                  (a, b) => new Date(a.created_date) - new Date(b.created_date)
                )
                ?.map((note) => (
                  <VendorNote key={note?.note} message={note} />
                ))}
            </InfiniteScroll>
          </div>
        </Offcanvas.Body>
        {/* {localStorage.getItem("role") === "manager" && ( */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            borderTop: "1px solid #dee2e6",
            backgroundColor: "#f8f9fa"
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Type a message..."
            style={{ flex: 1, marginRight: "10px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <button
            className="btn btn-primary"
            disabled={creating || isLoading || note.trim() === ""}
            onClick={() => {
              setCreating(true);
              createNote(
                { vendor_id:vendor_id||vendorId, note },
                {
                  onSuccess: () => {
                    updateParams({ page_Index: 1 });
                    setCreating(false);
                    setNote("");
                  },
                  onError: () => {
                    setCreating(false);
                  }
                }
              );
            }}
          >
            <SendPlusFill fill="white" />
          </button>
        </div>
        {/* )} */}
      </Offcanvas>
    </>
  );
}
