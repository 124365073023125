import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";
import "../App.css";
const CustomDropdown = ({
  data,
  onSelect,
  displayKey,
  value,
  Objectkey,
  className,
  minWidth = "100px",
  maxWidth = "350px",
  showVerificationBadge = false,
  verification_key = "human_verified",
  maxHeight = "200px",
  alternateObjectKey = null,
  menuWidth = "350px"
}) => {
  const handleSelect = (item, item2 = null) => {
    if (alternateObjectKey == null) {
      onSelect(item);
    } else {
      onSelect(item, item2);
    }
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (!data) return;

    if (searchTerm === "") {
      setFilteredData(data);
    } else {
      const filtered = data?.filter((item) =>
        typeof item === "string"
          ? item?.toLowerCase().includes(searchTerm?.trim()?.toLowerCase())
          : item?.[`${displayKey}`]
              ?.toLowerCase()
              ?.includes(searchTerm.trim().toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, data, displayKey]);

  return (
    <Dropdown
      show={isOpen}
      onToggle={(nextShow) => setIsOpen(nextShow)}
      className={` ${className}  custom-dropdown-container py-0`}
      style={{ cursor: "pointer" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown.Toggle
        as={Form.Control}
        readOnly
        value={value || "Select an option"}
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-toggle"
        style={{
          textAlign: "left",
          cursor: "pointer",
          maxWidth: maxWidth,
          minWidth: minWidth,
          maxHeight: maxHeight
        }}
      />
      <Dropdown.Menu
        className="custom-dropdown-menu bg-white"
        style={{ minWidth: menuWidth, maxWidth: menuWidth }}
      >
        <div style={{ position: "sticky", top: "-8px", margin: "2px" }}>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" "
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        {filteredData?.length === 0 && (
          <Dropdown.Item disabled>No results found</Dropdown.Item>
        )}
        {filteredData?.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              if (alternateObjectKey == null) {
                console.log("coming here");
                handleSelect(
                  typeof item === "string" ? item : item?.[`${Objectkey}`]
                );
              } else {
                handleSelect(
                  typeof item === "string" ? item : item?.[`${Objectkey}`],
                  item?.[`${alternateObjectKey}`]
                );
              }
            }}
          >
            {typeof item === "string"
              ? item
              : item?.[`${displayKey}`] || item?.[`${Objectkey}`]}

            {showVerificationBadge && item[`${verification_key}`] == true && (
              <PatchCheckFill color="blue" size={20} />
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
