import useUpdateParams from "hooks/useUpdateParams";
import { useEffect, useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import {
  ChevronDoubleDown,
  ChevronDoubleUp,
  DashCircle,
  PatchCheckFill,
  Trash3Fill,
} from "react-bootstrap-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { globalStoreV2 } from "store/globalStoreV2";
import useFilterStore from "store/persistentFilterStore";
import { formatDate } from "utils/format";

export default function VendorTable({
  data = [],
  handleDeleteVendor,
  isDeleting,
  isAdding,
}) {
  const navigate = useNavigate();
  const { setActual_vendor_name } = globalStoreV2();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vendorIdToDelete, setVendorIdToDelete] = useState(null);
  const [vendorNameToDelete, setVendorNameToDelete] = useState(null);
  const [searchParams] = useSearchParams();
  let branch_count_order = searchParams.get("branch_count_order") || "all";
  let verified_branch_count_order =
    searchParams.get("verified_branch_count_order") || "all";
  let verified_item_count_order =
    searchParams.get("verified_item_count_order") || "all";
  let item_count_order = searchParams.get("item_count_order") || "all";
  let document_count_order = searchParams.get("document_count_order") || "all";
  let created_date_order = searchParams.get("created_date_order") || "all";

  const { filters, setFilters } = useFilterStore();

  useEffect(() => {
    setFilters(Object.fromEntries(searchParams.entries()));
  }, [searchParams, setFilters]);

  const updateParams = useUpdateParams();

  // Enhanced render function for sorting icons
  const renderSortIcon = (order, param) => {
    if (order === "all") {
      return (
        <DashCircle
          className="cursor-pointer"
          fill="gray"
          title="Click to sort by ascending"
          onClick={() => updateParams({ [param]: "asc" })}
        />
      );
    }
    if (order === "asc") {
      return (
        <ChevronDoubleUp
          className="cursor-pointer"
          fill="green"
          title="Currently sorted ascending. Click to sort by descending"
          onClick={() => updateParams({ [param]: "desc" })}
        />
      );
    }
    return (
      <ChevronDoubleDown
        className="cursor-pointer"
        fill="red"
        title="Currently sorted descending. Click to reset sorting"
        onClick={() => updateParams({ [param]: "all" })}
      />
    );
  };

  return (
    <div
      className="mx-5"
      style={{
        marginTop: "20px",
      }}
    >
      <Table
        striped
        bordered
        hover
        variant="light"
        responsive
        className="shadow-sm"
      >
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Vendor Category</th>
            <th>Created Date  {renderSortIcon(created_date_order, "created_date_order")} </th>
            <th>
              Document Count {renderSortIcon(document_count_order, "document_count_order")}
            </th>
            <th>
              Branch Count {renderSortIcon(branch_count_order, "branch_count_order")}
            </th>
            <th>
              Verified Branch Count{" "}
              {renderSortIcon(verified_branch_count_order, "verified_branch_count_order")}
            </th>
            <th>
              Item Count {renderSortIcon(item_count_order, "item_count_order")}
            </th>
            <th>
              Verified Item Count{" "}
              {renderSortIcon(verified_item_count_order, "verified_item_count_order")}
            </th>
            <th>Verified By</th>
            <th>View Invoice</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>
                <Link
                  onClick={() => setActual_vendor_name(item?.vendor_name)}
                  to={`/vendor-consolidation-v2/${item?.vendor_id}`}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {item.vendor_name}{" "}
                  {item?.human_verified && <PatchCheckFill fill="blue" />}
                </Link>
              </td>
              <td>{item?.vendor_category}</td>
              <td>{formatDate(item?.created_date)}</td>
              <td>{item?.document_count}</td>
              <td>{item?.branch_count}</td>
              <td>{item?.verified_branch_count}</td>
              <td>{item?.item_count}</td>
              <td>{item?.verified_item_count}</td>
              <td>{item?.verified_by?.username}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{
                    backgroundColor: "#2d2d2d",
                    borderColor: "#2d2d2d",
                  }}
                  onClick={() => {
                    navigate(`/invoice-details?vendor=${item?.vendor_id}`);
                  }}
                >
                  View
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setVendorIdToDelete(item?.vendor_id);
                    setVendorNameToDelete(item?.vendor_name);
                    setShowDeleteModal(true);
                  }}
                >
                  <Trash3Fill />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {data?.length === 0 && (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            fontSize: "16px",
            fontWeight: 700,
            marginTop: 40,
          }}
        >
          No Data Found
        </p>
      )}
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setVendorIdToDelete(null);
          setVendorNameToDelete(null);
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this vendor?
          <table className="table">
            <tbody>
              <tr>
                <td>Vendor Name</td>
                <td>{vendorNameToDelete}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setVendorIdToDelete(null);
              setVendorNameToDelete(null);
              setShowDeleteModal(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeleteVendor(vendorIdToDelete)}
          >
            {isDeleting ? (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Spinner style={{ height: 20, width: 20 }} /> Deleting..
              </span>
            ) : (
              "Delete"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
