import ResizableCell from "components/Utility/ResizableCell";
import React from "react";

const RawTable = ({ data }) => {
  const extractTableData = (data) => {
    // Extract headers
    const headers = Object.keys(data)
      .map((key) => {
        const firstObject = data[key][0];
        return firstObject ? firstObject.actual_text : null;
      })
      .filter((header) => header);
    const lineItems = Object.keys(data[1] || {}).map((rowIndex) => {
      return Object.keys(data).reduce((item, key) => {
        const cell = data[key][rowIndex];
        item.push(cell ? cell.actual_text : null);
        return item;
      }, []);
    });

    return {
      headers,
      lineItems
    };
  };

  const { headers, lineItems } = extractTableData(data);

  return (
    <div
      style={{
        width: "100%",
        height: "580px",
        overflowX: "auto",
        overflowY: "auto"
      }}
    >
      <table className="table table-striped table-responsive">
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <tr>
            {headers?.map((header, i) => {
              return (
                <th
                  key={i}
                  style={{
                    lineHeight: "1.2",
                    fontSize: "14px",
                    backgroundColor: "#FFF2CD",
                    textTransform: "capitalize",
                    verticalAlign: "middle"
                  }}
                  className="resizable-header"
                >
                  <ResizableCell width={100}>
                    <div
                      style={{
                        lineHeight: "1.5",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {header}
                    </div>
                  </ResizableCell>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {lineItems?.slice(1, lineItems?.length)?.map((item, index) => {
            return (
              <tr key={index}>
                {item?.map((it, i) => {
                  return <td key={i}>{it}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RawTable;
