import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { login } from "../api";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const { mutate, isPending } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      toast.success(data.message, {
        autohide: true,
        delay: 200
      });
      setFormData({
        username: "",
        password: ""
      });
      navigate("/");
    },
    onError: (data) => {
      toast.error(data?.response?.data.message, {
        autohide: true,
        delay: 200
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("login");
    }
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(formData);
  };

  useEffect(()=>{
        if(localStorage.getItem('token')){
          navigate('/')
        }
  },[])
  return (
    <>
      <ToastContainer />
      <Container
        fluid
        className="mt-0"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Row style={{ height: "100vh", overflow: "hidden" }}>
          <Col
            md={4}
            xs={12}
            className="bg-light p-4  "
            style={{ height: "100%", overflow: "hidden" }}
          >
            <Form
              onSubmit={handleSubmit}
              style={{
                marginTop: "40%"
              }}
            >
              <h2 className="text-center">Login Here</h2>
              <Form.Group controlId="formUsername" className="text-start">
                <Form.Label className="">Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your username or email"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="text-start mt-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button
                variant="primary"
                disabled={isPending}
                type="submit"
                className="my-4"
                style={{
                  width: "100%"
                }}
              >
                {isPending ? "Logging in..." : "Login"}
              </Button>
            </Form>
            {/* <p className="my-1 text-center">Not a user <Link to="/register">Register Here</Link></p> */}
          </Col>

          {/* Right 60% - Image */}
          <Col md={8} className="d-none d-md-block p-0">
            <img
              src={`${process.env.PUBLIC_URL}/login.jpg`}
              alt="Registration"
              className="img-fluid "
              style={{ height: "700px", marginTop: "2%" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
