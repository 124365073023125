import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "lib/axios";
import { axiosInstance } from "lib/axios";

export const useFetchDuplicateInvoices = (documentUUID) => {
  return useQuery({
    queryKey: ["duplicateInvoices", documentUUID],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/find_duplicate_invoices?document_uuid=${documentUUID}`
      );
      return response.data;
    },
    enabled: !!documentUUID
  });
};
export const useGetInvoiceListing = (payload) => {
  const {
    start_date,
    end_date,
    auto_accepted,
    human_verification,
    human_verified,
    detected,
    invoice_type,
    rerun_status,
    restaurant,
    vendor,
    page_size,
    page,
    clickbacon_status,
    sort_order,
    assigned_to
  } = payload;
  const apiUrl = `/api/document/?page_size=${page_size}&page=${page}&invoice_type=${invoice_type}&end_date=${end_date}&start_date=${start_date}&auto_accepted=${auto_accepted}&human_verification_required=${human_verification}&invoice_detection_status=${detected}&rerun_status=${rerun_status}&clickbacon_status=${clickbacon_status}&restaurant=${restaurant}&vendor=${vendor}&sort_order=${sort_order}&human_verified=${human_verified}&assigned_to=${
    assigned_to || ""
  }`;

  return useQuery({
    queryKey: ["invoices", payload],
    queryFn: async () => {
      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useGetVendorsList = (non_summary_vendors) => {
  return useQuery({
    queryKey: ["vendors-list"],
    queryFn: async () => {
      try {
        if(non_summary_vendors){
          const response = await instance.get(`/api/vendor/names/?non_summary_vendors=true`);
          return response?.data;

        }else{
          const response = await instance.get(`/api/vendor/names/`);
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useGetRestaurantList = () => {
  return useQuery({
    queryKey: ["restaurants-list"],
    queryFn: async () => {
      try {
        const response = await instance.get(`/api/restaurant/`);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useGetSchedularsRestaurantList = () => {
  return useQuery({
    queryKey: ["schedulars-restaurants-list"],
    queryFn: async () => {
      try {
        const response = await instance.get(`/api/invoice-balancer-scheduler/restaurant/`);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useSearchInvoice = () => {
  return useMutation({
    mutationFn: async (payload) => {
      // Build query parameters dynamically
      const queryParams = new URLSearchParams();
      for (const key in payload) {
        if (payload[key] !== undefined && payload[key] !== null) {
          queryParams.append(key, payload[key]);
        }
      }

      const apiUrl = `/api/document/search/?${queryParams.toString()}`;
      const response = await instance.get(apiUrl);

      return response?.data;
    },
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.error("Error fetching invoices:", error);
      throw error; // Optionally rethrow to allow component-specific error handling.
    }
  });
};
export const useGetVendorAddresses = (vendor_id) => {
  return useQuery({
    queryKey: ["vendor-addresses", vendor_id],
    queryFn: async () => {
      if (vendor_id) {
        try {
          const response = await instance.get(
            `/api/vendor-branch/${vendor_id}/?vendor_address`
          );
          return response?.data;
        } catch (error) {
          return error?.response?.data;
        }
      }
    }
  });
};

export const usePrefetchPdfLinks = (payload) => {
  return useQuery({
    queryKey: ["prefetch-pdfs", payload],
    queryFn: async () => {
      const {
        start_date,
        end_date,
        auto_accepted,
        human_verification,
        human_verified,
        detected,
        invoice_type,
        rerun_status,
        restaurant,
        vendor,
        page_size,
        page,
        clickbacon_status,
        sort_order
      } = payload;

      if (Object.keys(payload)?.length > 0) {
        const apiUrl = `/api/document/prefetch-links/?page_size=${page_size}&page=${page}&invoice_type=${invoice_type}&end_date=${end_date}&start_date=${start_date}&auto_accepted=${auto_accepted}&human_verification=${human_verification}&detected=${detected}&rerun_status=${rerun_status}&clickbacon_status=${clickbacon_status}&restaurant=${restaurant}&vendor=${vendor}&sort_order=${sort_order}&human_verified=${human_verified}`;

        try {
          const response = await instance.get(apiUrl);
          return response?.data?.data;
        } catch (error) {
          return error?.response?.data;
        }
      }
    }
  });
};
