import { useEffect, useState } from "react";
import useAppStore from "store/global";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { InvoicePagination } from "./layout/InvoicePagination";
import CategoryWiseSumTable from "./Tables/CategoryWiseSumTable";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ZoomIn,
  ZoomOut,
  Download,
  CameraFill,
  Clipboard2Fill,
  LockFill,
  BoundingBox
} from "react-bootstrap-icons";
import { useMutation } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import { axiosInstance } from "lib/axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PDFComponent = ({
  pagination,
  single = false,
  hoveredCellCoordinates,
  setHoveredCellCoordinates,
  multipleBoundingBoxes,
  setMultipleBoundingBoxes,
  loading,
  toggleMultipleBoundingBoxes,
  singleCellCoordinates
}) => {
  const {
    numPages,
    setNumPages,
    pdfUrl,
    pageNum,
    setPageNum,
    scale: pdfScale,
    setScale: setPdfScale,
    rotation,
    setRotation,
    pdfSource,
    setvendorName,
    invoiceType,
    lockZoomAndScroll,
    setLockZoomAndScroll,
    setSelectPdfPortion,
    selectPdfPortion
  } = useAppStore();
  const iframeUrl = pdfUrl
    ? `${pdfUrl.replace("uc?id=", "file/d/")}/preview?embedded=true`
    : "";
    

  const [initialDistance, setInitialDistance] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [text, setText] = useState("");

  const { mutate, isPending } = useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post(
        "/extract_text_from_document",
        data
      );
      return response.data;
    },
    onError: (error) => {
      console.log("Error", error);
    },
    onSuccess: (data) => {
      setIsModalOpen(true);
      setText(data.text);
    }
  });

  const handleMouseDown = (e) => {
    if (!selectPdfPortion) {
      return;
    }
    const pdfWrapper = document.getElementById("react-pdf__Wrapper");
    const rect = pdfWrapper.getBoundingClientRect();
    const scrollX = pdfWrapper.scrollLeft;
    const scrollY = pdfWrapper.scrollTop;

    setIsSelecting(true);
    setStartX(e.clientX - rect.left + scrollX);
    setStartY(e.clientY - rect.top + scrollY);
  };

  const handleMouseMove = (e) => {
    if (!selectPdfPortion) {
      return;
    }
    if (isSelecting) {
      const pdfWrapper = document.getElementById("react-pdf__Wrapper");
      const rect = pdfWrapper.getBoundingClientRect();
      const scrollX = pdfWrapper.scrollLeft;
      const scrollY = pdfWrapper.scrollTop;

      setEndX(e.clientX - rect.left + scrollX);
      setEndY(e.clientY - rect.top + scrollY);
    }
  };

  const handleMouseUp = () => {
    if (!selectPdfPortion) {
      return;
    }
    setIsSelecting(false);

    if (Math.abs(startX - endX) < 10 || Math.abs(startY - endY) < 10) {
      return;
    }

    captureSelectedArea();
  };

  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    setMultipleBoundingBoxes(false);
    toggleMultipleBoundingBoxes(null, 0);
    changePage(-1);
  }

  function nextPage() {
    setMultipleBoundingBoxes(false);
    toggleMultipleBoundingBoxes(null, 0);
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNum(1);
  }

  const handleKeyPress = (event) => {
    if (event.key === "+" && event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      setPdfScale(pdfScale * 2);
    }
    if (event.key === "_" && event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      setPdfScale(pdfScale / 2);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [pdfScale]);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false); // Hide loading indicator when iframe loads
  };

  const handleError = (error) => {
    console.error("Error loading PDF:", error);
    setIsLoading(false); // Hide loading indicator on error
  };
  const captureSelectedArea = () => {
    const canvas = document.createElement("canvas");
    const pdfCanvas = document.querySelector(".react-pdf__Page__canvas"); // Get the PDF page canvas

    const scaleFactor = pdfCanvas.width / pdfCanvas.offsetWidth;

    canvas.width = Math.abs(endX - startX) * scaleFactor;
    canvas.height = Math.abs(endY - startY) * scaleFactor;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      pdfCanvas,
      Math.min(startX, endX) * scaleFactor,
      Math.min(startY, endY) * scaleFactor,
      Math.abs(endX - startX) * scaleFactor,
      Math.abs(endY - startY) * scaleFactor,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const base64Image = canvas.toDataURL("image/png");
    setImage(base64Image);
    const formData = new FormData();

    canvas.toBlob((blob) => {
      formData.append("image", blob, "selected_area.png");
      mutate(formData);
    }, "image/png");

    // reset the selection states
    setIsSelecting(false);
    setStartX(0);
    setStartY(0);
    setEndX(0);
    setEndY(0);
  };

  return (
    <>
      {(pdfSource === "azure_blob" || pdfSource === "clickbacon") && (
        <div
          className="ml-50 p-2 rounded"
          style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ gap: "16px" }}
          >
            <ZoomIn
              height={20}
              width={20}
              disabled={pdfScale >= 8}
              className="cursor-pointer"
              onClick={() => setPdfScale(pdfScale * 2)}
            />
            <ZoomOut
              height={20}
              width={20}
              disabled={pdfScale <= 0.1}
              className="cursor-pointer"
              onClick={() => setPdfScale(pdfScale / 2)}
            />
            <button
              type="button"
              disabled={pageNum <= 1}
              onClick={previousPage}
              className="btn btn-sm btn-outline-secondary"
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNum >= numPages}
              onClick={nextPage}
              className="btn btn-sm btn-outline-secondary"
            >
              Next
            </button>
            <ArrowClockwise
              height={20}
              width={20}
              className="cursor-pointer"
              onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)}
            />
            <ArrowCounterclockwise
              height={20}
              width={20}
              className="cursor-pointer"
              onClick={() => setRotation(rotation === 0 ? 270 : rotation - 90)}
            />
            <div>
              <span>
                Page {pageNum} of {numPages}
              </span>
              <Download
                height={20}
                width={20}
                className="cursor-pointer mx-4"
                onClick={() => window.open(pdfUrl, "_blank")}
              />
              <LockFill
                height={20}
                width={20}
                className="cursor-pointer"
                onClick={() => setLockZoomAndScroll(!lockZoomAndScroll)}
                style={{
                  color: lockZoomAndScroll ? "green" : "black"
                }}
              />
              <BoundingBox
                height={20}
                width={20}
                className="cursor-pointer mx-2"
                onClick={() => setSelectPdfPortion(!selectPdfPortion)}
                style={{
                  color: selectPdfPortion ? "green" : "black"
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div
        id="react-pdf__Wrapper"
        style={{
          height: "580px",
          overflow: "auto",

          filter: isPending ? "blur(4px)" : "none"
        }}
      >
        {pdfSource === "azure_blob" || pdfSource === "clickbacon" ? (
          <>
            <Document
              className="check-class"
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                // className="overflow-scroll"
                pageNumber={pageNum}
                scale={pdfScale}
                rotate={rotation}
                renderTextLayer={false}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              >

                {isPending && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0"
                    }}
                  >
                    <Spinner />
                  </div>
                )}
                {isSelecting && (
                  <div
                    style={{
                      position: "absolute",
                      left: Math.min(startX, endX),
                      top: Math.min(startY, endY),
                      width: Math.abs(endX - startX),
                      height: Math.abs(endY - startY),
                      border: "2px dashed blue",
                      backgroundColor: "rgba(0, 0, 255, 0.1)"
                    }}
                  />
                )}
                {singleCellCoordinates &&
                  singleCellCoordinates.page === pageNum && (
                    <>
                      <div
                        id="bound-box"
                        style={{
                          position: "absolute",
                          left:
                            Math.min(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.x
                              )
                            ) + "px",
                          top:
                            Math.min(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.y
                              )
                            ) + "px",
                          width:
                            Math.max(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.x
                              )
                            ) -
                            Math.min(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.x
                              )
                            ) +
                            "px",
                          height:
                            Math.max(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.y
                              )
                            ) -
                            Math.min(
                              ...singleCellCoordinates.coordinates.map(
                                (coordinate) => coordinate.y
                              )
                            ) +
                            "px",
                          border: "1px solid red",
                          background: "rgba(144,238,144,0.4)",
                          borderRadius: "4px",
                          transform: "scale(1.1)",
                          pointerEvents: "none"
                        }}
                      />
                    </>
                  )}
                {hoveredCellCoordinates && multipleBoundingBoxes && (
                  <>
                    {hoveredCellCoordinates.map((box, index) => {
                      // Each box is defined by 4 points, so we need to calculate the bounding box for each set of 4 coordinates.
                      if (index % 4 === 0) {
                        const coordinates = hoveredCellCoordinates.slice(
                          index,
                          index + 4
                        );
                        const xValues = coordinates.map(
                          (coord) => coord.coordinates.x
                        );
                        const yValues = coordinates.map(
                          (coord) => coord.coordinates.y
                        );

                        const minX = Math.min(...xValues);
                        const maxX = Math.max(...xValues);
                        const minY = Math.min(...yValues);
                        const maxY = Math.max(...yValues);

                        return (
                          <div
                            key={index}
                            id="bound-box"
                            style={{
                              position: "absolute",
                              left: `${minX}px`,
                              top: `${minY}px`,
                              width: `${maxX - minX}px`,
                              height: `${maxY - minY}px`,
                              padding: "5px",
                              background: "rgba(144,238,144,0.4)",
                              borderRadius: "2px",
                              transform: "scale(1.1)",
                              pointerEvents: "none"
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </Page>
            </Document>
          </>
        ) : (
          <>
            {iframeUrl && (
              <iframe
                title="pdf"
                src={iframeUrl}
                onLoad={handleLoad}
                onError={handleError}
                width="100%"
                height="570"
                allow="autoplay"
                style={{ display: isLoading ? "none" : "block" }} // Hide iframe while loading
              ></iframe>
            )}
            {!iframeUrl && pdfUrl ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row"
                }}
              >
                <Spinner />
              </div>
            ) : (
           <></>
            )}{" "}
            {/* Handle empty URL */}
          </>
        )}
      </div>
      {pagination && (
        <InvoicePagination
          setHoveredCellCoordinates={setHoveredCellCoordinates}
        />
      )}
      {invoiceType !== "Summary Invoice" && <CategoryWiseSumTable />}
      <div>{/* <Pagination simple defaultCurrent={2} total={50} /> */}</div>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Selected Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPending ? (
            <Spinner />
          ) : (
            <>
              <div className="border p-2 rounded mb-2 border-white shadow-sm">
                <div className="d-flex justify-content-between mb-2">
                  <h5 className="text-bold m-0">Extracted Text</h5>
                  <Clipboard2Fill
                    height={20}
                    width={20}
                    className="cursor-pointer"
                    fill="green"
                    onClick={() => {
                      navigator.clipboard.writeText(text);
                      toast.info("Text copied to clipboard");
                    }}
                  />
                </div>
                <textarea
                  className="form-control"
                  rows="10"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-center border p-2 rounded mb-2 border-white shadow-sm">
                <img src={image} alt="selected area" width={500} />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};


