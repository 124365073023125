import { SimpleBarChart } from "@carbon/charts-react";
import "@carbon/charts-react/styles.css";
import { useWindowSize } from "usehooks-ts";
import React from "react";
import { Spinner } from "components/common/Spinner";

const truncation = ({ width }) => {
  let threshold = 25,
    numCharacter = 20;
  if (width < 1200) {
    threshold = 20;
    numCharacter = 16;
  }
  return {
    type: "end_line", //"none"
    threshold,
    numCharacter
  };
};
const options = (size) => {
  let height = "350px";
  if (size.width < 900) {
    height = "400px";
  }

  return {
    title: "Invoice Statistics",
    axes: {
      left: {
        mapsTo: "value",
        title: "Count" // Updated to reflect actual value being used for Y-axis
      },
      bottom: {
        scaleType: "labels",
        mapsTo: "group",
        truncation: truncation(size),
        title: "Invoice Categories"
      }
    },
    legend: {
      alignment: "center",
      truncation: { type: "none" }
    },
    height,
    tooltip: {
      customHTML: (model) => {
        if (model && model.length > 0) {
          const data = model[0]; // Extract the first item from the model for the tooltip
          return `<div style="font-size: 12px; padding: 5px;">
                    <div style="display: flex; justify-content: space-between;">
                      <p><strong>X-Value:</strong></p>
                      <p>${data.group}</p>
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <p><strong>Y-Value (Count):</strong></p>
                      <p>${data.value}</p>
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <p><strong>Group:</strong></p>
                      <p>${data.group}</p>
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <p><strong>Percentage:</strong></p>
                      <p>${data.percentage || "N/A"}</p>
                    </div>
                  </div>`;
        } else {
          return "Tooltip data not available";
        }
      }
    }
  };
};

const formatData = (stats) => {
  return [
    {
      group: "Total Invoices",
      value: stats?.total_invoice.count,
      percentage: stats?.total_invoice.percentage
    },
    {
      group: "Detected Invoices",
      value: stats?.detected_invoices.count,
      percentage: stats?.detected_invoices.percentage
    },
    {
      group: "Undetected Invoices",
      value: stats?.undetected_invoices.count,
      percentage: stats?.undetected_invoices.percentage
    },

    {
      group: "Auto Balanced Invoices",
      value: stats?.auto_balanced_invoices.count,
      percentage: stats?.auto_balanced_invoices.percentage
    },
    {
      group: "Manual Balanced Invoices",
      value: stats?.manual_balanced_invoices.count,
      percentage: stats?.manual_balanced_invoices.percentage
    },
    {
      group: "Unbalanced Invoices",
      value: stats?.unbalanced_invoices.count,
      percentage: stats?.unbalanced_invoices.percentage
    },

    {
      group: "Rejected Invoices",
      value: stats?.rejected_invoices.count,
      percentage: stats?.rejected_invoices.percentage
    },
    {
      group: "Accepted Invoices",
      value: stats?.accepted_invoices.count,
      percentage: stats?.accepted_invoices.percentage
    },
    {
      group: "Auto Accepted Invoices",
      value: stats?.auto_accepted_invoices.count,
      percentage: stats?.auto_accepted_invoices.percentage
    },

    {
      group: "clickBACON Synced Documents",
      value: stats?.clickbacon_synced_documents.count,
      percentage: stats?.clickbacon_synced_documents.percentage
    },
    {
      group: "Archived Invoices",
      value: stats?.archived_documents.count,
      percentage: stats?.archived_documents.percentage
    },
    {
      group: "Other Type Invoices",
      value: stats?.other_type_documents.count,
      percentage: stats?.other_type_documents.percentage
    }
  ];
};

export const BarChart = ({ stats, isLoading }) => {
  const size = useWindowSize(); // Get window size for responsive behavior
  const data = formatData(stats); // Format the provided statistics data

  return (
    <div className="px-5 col-xl-8 mx-auto py-2" style={{ minWidth: "500px" }}>
      {isLoading ? (
        <div className="d-flex justify-content-center align-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <SimpleBarChart
          data={data || []}
          options={options(size)}
        ></SimpleBarChart>
      )}
    </div>
  );
};
