import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import useUpdateParams from "hooks/useUpdateParams";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetSimilarVendors } from "./api";
import Header from "./components/Header";
import VendorCombineTable from "./components/VendorCombineTable";
import { globalStoreV2 } from "store/globalStoreV2";
import { Button, Form, FormControl } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

export default function VendorCombine() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  const [similarVendors, setSimilarVendors] = useState([]);
  const similarity_value = searchParams.get("similarity") || 50;
  let mode = searchParams.get("mode");
  let vendor_name_search = searchParams.get("vendor_name_search");
  const [actualVendorName, setActualVendorName] = useState("");
  const [similarity, setSimilarity] = useState(similarity_value);
  const { vendorID } = useParams();
  const [checkedVendors, setCheckedVendors] = useState([]);
  const handleCheckedChange = (value, vendor_id, vendor_name) => {
    if (value == true) {
      setCheckedVendors([
        ...checkedVendors,
        {
          vendor_id,
          vendor_name
        }
      ]);
    } else {
      let fil = checkedVendors?.filter((item) => item?.vendor_id !== vendor_id);
      setCheckedVendors(fil);
    }
  };
  const { data, isLoading } = useGetSimilarVendors({
    vendor_id: vendorID,
    similarity:vendor_name_search?null: similarity_value,
    vendor_name_search,
    mode
    
  });
  const { actual_vendor_name } = globalStoreV2();
  const [vendorName, setVendorName] = useState(vendor_name_search);
  return (
    <>
      <NavHeader view="Combine Vendors" />
      <Header text={`Combine Vendors  ( ${actual_vendor_name} )`}>
        <div
          style={{
            display: "flex",
            maxHeight: "50px",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          {mode == "search" && (
            <div
              style={{
                display: "flex",
                maxHeight: "50px",
                alignContent: "center",
                alignItems: "center"
              }}
            >
              <FormControl
                placeholder="Search Vendor Name"
                value={vendorName}
                onChange={(e) => {
                  if (e.target.value == "") {
                    setVendorName(e.target.value);
                    updateParams({ vendor_name_search: undefined });
                  } else {
                    setVendorName(e.target.value);
                  }
                }}
              />
              <Button
                variant="light"
                onClick={() => updateParams({ vendor_name_search: vendorName })}
              >
                <Search />
              </Button>
            </div>
          )}

          <Form.Check
            type="switch"
            id="highlight"
            checked={mode == "search" ? true : false}
            style={{ minWidth: "200px" }}
            className="d-flex justify-content-end mx-2 align-items-center  gap-2"
            onChange={(e) => {
              updateParams({
                mode: e.target.checked ? "search" : undefined,
                vendor_name_search: e.target.checked ? undefined : undefined
              });
              setVendorName(undefined);
            }}
            label="Search Manually"
          />
        </div>
      </Header>
      <ToastContainer />
      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <VendorCombineTable
            vendorData={mode == "search" ? data : data}
            actualVendorName={actualVendorName}
            setActualVendorName={setActualVendorName}
            // handleCombineVendors={handleCombineVendors}
          />
          <div className="my-4 container">
            <label>Similarity : {similarity} </label>
            <input
              class="form-range d-block mx-auto my-2 bg-secondary"
              type="range"
              value={similarity}
              onChange={(e) => setSimilarity(parseInt(e.target.value))}
              min={0}
              max={100}
              step="1"
              style={{ width: "50%" }}
            />
          </div>
          <div className="my-4 container">
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-info d-block mx-auto my-2"
              onClick={() => {
                updateParams({ similarity: similarity });
              }}
              style={{ width: "50%" }}
            >
              Find Similar Vendors
            </button>
          </div>
        </>
      )}
    </>
  );
}
