import { useQuery, useMutation } from "@tanstack/react-query";
import { instance } from "lib/axios";
import { axiosInstance } from "lib/axios";
import { queryClient } from "lib/react-query";
import { toast } from "react-toastify";
export const useFetchDocumentComments = (documentId, pageIndex, show) => {
  return useQuery({
    queryKey: ["documentComments", documentId, pageIndex],
    queryFn: async () => {

      if(!documentId){
        return
      }
      try {
        let response = await instance.get(
          `/api/document/${documentId}/clickbacon-notes/?page=${pageIndex}&page_size=10`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {},
    refetchOnWindowFocus: false
  });
};

export const useAddClickBaconDocumentNote = () => {
  return useMutation({
    mutationFn: async ({ document_uuid, note }) => {
      console.log(note, document_uuid);
      try {
        let response = await instance.post(
          `/api/document/${document_uuid}/clickbacon-notes/`,
          { note }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      toast.success(data.message);
      queryClient.invalidateQueries(["documentComments"]);
    },
    onError: async (error, variables, context) => {
      toast.error(error.message);
    }
  });
};

export const useFetchVendorNotes = (vendorId, pageIndex, show) => {
  return useQuery({
    queryKey: ["vendorNotes", vendorId, pageIndex],
    queryFn: async () => {
      try {
        let response = await axiosInstance.get(
          `vendor_note/get_vendor_notes?vendor_id=${vendorId}&page_index=${pageIndex}&page_size=10`
        );
        response.data.page_index = pageIndex;
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(
        ["vendorNotes", vendorId, pageIndex],
        data
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!show
  });
};

export const useAddVendorNote = () => {
  return useMutation({
    mutationFn: async (data) => {
      try {
        let response = await axiosInstance.post(
          "/vendor_note/create_vendor_note",
          data
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      toast.success(data.message);
    },
    onError: async (error, variables, context) => {
      toast.error(error.message);
    },
    onError: async (error, variables, context) => {},
    onSettled: async (data, variables, context) => {
      await queryClient.invalidateQueries(["vendorNotes", variables.vendorId]);
    }
  });
};
