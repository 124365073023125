import { useGetRestaurantList } from "components/api/api";
import { NavHeader } from "components/layout/NavHeader";
import { useFetchAllUsers } from "modules/userActivity/api";
import Header from "modules/vendorConsolidation/components/Header";
import { useState } from "react";
import { Button, Form, FormControl, Modal, Table } from "react-bootstrap";
import { PlusCircle, Trash3Fill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
    useCreateSchedular
} from "../api";
import { useGetSchedularsRestaurantList } from "components/api/api";

const CreateSchedular = () => {
  const [showBalancerModal, setShowBalancerModal] = useState(false);
  const [showRestaurantModal, setShowRestaurantModal] = useState(false);
  const [newBalancers, setNewBalancers] = useState([]);
  const [newRestaurants, setNewRestaurants] = useState([]);
  const [restaurantSearch, setRestaurantSearch] = useState("");
  const [active_balancers, setActiveBalancers] = useState([]);
  const [active_restaurants, setActiveRestaurants] = useState([]);
  const [name, setName] = useState("");
  const [balancerSearch, setBalancerSearch] = useState("");
  const [verification_hours, setVerificationHours] = useState(0);
  const [verification_minutes, setVerificationMinutes] = useState(0);
  const [scheduler_status, setSchedularStatus] = useState(false);
  const { data: users, isLoading: loadingUsers } = useFetchAllUsers();
  const [saving, setSaving] = useState(false);
  const { mutate, isPending } = useCreateSchedular();
  const { data: restaurants, isLoading: loadingRestaurants } =
  useGetSchedularsRestaurantList();
  const navigate = useNavigate();
  const deleteBalancer = (balancer) => {
    let filteredBalancer = active_balancers?.filter(
      (b) => b?.id !== balancer?.id
    );
    setActiveBalancers(filteredBalancer);
  };

  const deleteRestaurant = (restaurant) => {
    let filteredRes = active_restaurants?.filter(
      (r) => r?.restaurant_id !== restaurant?.restaurant_id
    );
    setActiveRestaurants(filteredRes);
  };

  const addBalancers = () => {
    setActiveBalancers([...active_balancers, ...newBalancers]);
    setNewBalancers([]);
    setShowBalancerModal(false);
  };

  const addRestaurants = () => {
    setActiveRestaurants([...active_restaurants, ...newRestaurants]);
    setNewRestaurants([]);
    setShowRestaurantModal(false);
  };

  const updateData = () => {
    setSaving(true);
    let payload = {
      scheduler_status: scheduler_status,
      active_restaurants: active_restaurants?.map((r) => r.restaurant_id),
      active_balancers: active_balancers?.map((b) => b.id),
      verification_hours: verification_hours,
      verification_minutes: verification_minutes,
      name: name
    };

    mutate(payload, {
      onSuccess: (data) => {
        toast.success(data?.message);

        setSaving(false);
        navigate(`/schedulars`);
      },
      onError: () => {
        setSaving(false);
      }
    });
  };

  return (
    <div>
      <ToastContainer />
      <NavHeader />
      <Header text="Create Schedular " />

      <div className="p-5">
        <div className="border" style={{ padding: "20px" }}>
          {/* Scheduler Status */}
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <p className="mb-2">Scheduler Status:</p>
            <Form.Check
              checked={scheduler_status}
              type="switch"
              onChange={(e) => {
                setSchedularStatus(e.target.checked);
              }}
            />
          </div>
          <FormControl
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Schedular Name"
            className="my-2"
          />
          <div>
            <Table striped hovered bordered>
              <thead>
                <tr>
                  <td>Verification Hours</td>
                  <td>Verification Minutes</td>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="justify-content-center align-content-center d-flex">
                    <FormControl
                      type="number"
                      value={verification_hours}
                      onChange={(e) => {
                        setVerificationHours(e.target.value);
                      }}
                    />
                  </td>
                  <td className="justify-content-center align-content-center ">
                    <FormControl
                      type="number"
                      max={60}
                      value={verification_minutes}
                      onChange={(e) => {
                        setVerificationMinutes(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* Main Table for Restaurants and Balancers */}
          <div style={{ maxHeight: "500px", overflow: "auto" }}>
            <Table
              striped
              bordered
              hover
              style={{ marginTop: "20px", maxHeight: "300px" }}
            >
              <tbody style={{ maxHeight: "200px", overflow: "auto" }}>
                <tr>
                  <td>
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      style={{ maxHeight: "200px", overflow: "auto" }}
                    >
                      <thead>
                        <tr style={{ position: "sticky", top: 0 }}>
                          <th className="d-flex  gap-2 w-100">
                            <span className="w-50 text-end"> Restaurant </span>
                            <div className="w-50 d-flex justify-content-end">
                              <Button
                                size="sm"
                                className=" d-flex justify-content-center align-content-center"
                                onClick={() => setShowRestaurantModal(true)}
                              >
                                <PlusCircle />
                              </Button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: "200px", overflow: "auto" }}>
                        {active_restaurants?.map((res) => (
                          <tr key={res.restaurant_id}>
                            <td>
                              <div className="d-flex justify-content-center gap-4">
                                <span>{res?.restaurant_name}</span>
                                <span>
                                  <Trash3Fill
                                    onClick={() => {
                                      deleteRestaurant(res);
                                    }}
                                    className="cursor-pointer"
                                    fill="red"
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      style={{ maxHeight: "200px", overflow: "auto" }}
                    >
                      <thead>
                        <tr style={{ position: "sticky", top: 0 }}>
                          <th className="d-flex  gap-2 w-100">
                            <span className="w-50 text-end"> Balancer </span>
                            <div className="w-50 d-flex justify-content-end">
                              <Button
                                size="sm"
                                className=" d-flex justify-content-center align-content-center"
                                onClick={() => setShowBalancerModal(true)}
                              >
                                <PlusCircle />
                              </Button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: "200px", overflow: "auto" }}>
                        {active_balancers?.map((b) => (
                          <tr key={b?.id}>
                            <td>
                              <div className="d-flex justify-content-center gap-4">
                                <span>{b?.username}</span>
                                <span>
                                  <Trash3Fill
                                    onClick={() => {
                                      deleteBalancer(b);
                                    }}
                                    className="cursor-pointer"
                                    fill="red"
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Modal
            show={showRestaurantModal}
            onHide={() => setShowRestaurantModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Restaurant</Modal.Title>
            </Modal.Header>
            {restaurants?.data?.filter((r) => r?.restaurant_id)?.length ==
            active_restaurants?.length ? (
              <p
                style={{
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 10,
                  width: "100%",
                  margin: 50
                }}
                className="text-center justify-content-center align-content-center"
              >
                Already Selected All The Restaurants.
              </p>
            ) : (
              <Modal.Body>
                <FormControl
                  placeholder="Search Restaurants"
                  value={restaurantSearch}
                  onChange={(e) => setRestaurantSearch(e.target.value)}
                />
                <Form.Check
                  type="checkbox"
                  label="Select All Restaurants"
                  className="my-2"
                  checked={
                    restaurants?.data?.some((r) =>
                      r.restaurant_name
                        ?.toLowerCase()
                        ?.includes(restaurantSearch?.toLowerCase())
                    ) &&
                    restaurants?.data
                      ?.filter((r) => {
                        return !active_restaurants?.some(
                          (active) => active?.restaurant_id === r?.restaurant_id
                        );
                      })
                      .every((restaurant) =>
                        newRestaurants.some(
                          (selected) =>
                            selected?.restaurant_id ===
                            restaurant?.restaurant_id
                        )
                      )
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const toAdd = restaurants?.data
                        ?.filter(
                          (r) =>
                            r?.restaurant_name
                              ?.toLowerCase()
                              ?.includes(restaurantSearch?.toLowerCase()) &&
                            !active_restaurants?.some(
                              (active) =>
                                active?.restaurant_id === r?.restaurant_id
                            )
                        )
                        ?.filter(
                          (restaurant) =>
                            !newRestaurants.some(
                              (selected) =>
                                selected?.restaurant_id ===
                                restaurant?.restaurant_id
                            )
                        );

                      setNewRestaurants([...newRestaurants, ...toAdd]);
                    } else {
                      const toRemoveIds = restaurants?.data
                        ?.filter(
                          (r) =>
                            r?.restaurant_name
                              ?.toLowerCase()
                              ?.includes(restaurantSearch.toLowerCase()) &&
                            !active_restaurants?.some(
                              (active) =>
                                active?.restaurant_id === r?.restaurant_id
                            )
                        )
                        ?.map((restaurant) => restaurant?.restaurant_id);

                      setNewRestaurants(
                        newRestaurants?.filter(
                          (r) => !toRemoveIds?.includes(r?.restaurant_id)
                        )
                      );
                    }
                  }}
                />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    padding: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 5
                  }}
                >
                  {restaurants?.data
                    ?.filter(
                      (r) =>
                        r?.restaurant_name
                          ?.toLowerCase()
                          ?.includes(restaurantSearch?.toLowerCase()) &&
                        !active_restaurants?.some(
                          (active) => active?.restaurant_id === r?.restaurant_id
                        )
                    )
                    ?.map((restaurant) => (
                      <div
                        key={restaurant.restaurant_id}
                        className="d-flex justify-content-between"
                      >
                        <span>
                          {restaurant?.restaurant_name ||
                            restaurant?.restaurant_id}
                        </span>
                        <Form.Check
                          checked={newRestaurants?.some(
                            (r) =>
                              r?.restaurant_id === restaurant?.restaurant_id
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setNewRestaurants([
                                ...newRestaurants,
                                restaurant
                              ]);
                            } else {
                              setNewRestaurants(
                                newRestaurants?.filter(
                                  (r) =>
                                    r?.restaurant_id !==
                                    restaurant?.restaurant_id
                                )
                              );
                            }
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div className="mt-3 text-end">
                  <Button onClick={addRestaurants}>Add</Button>
                </div>
              </Modal.Body>
            )}
          </Modal>

          {/* Balancer Modal */}
          <Modal
            show={showBalancerModal}
            onHide={() => setShowBalancerModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Balancer</Modal.Title>
            </Modal.Header>

            {users?.length == active_balancers?.length ? (
              <p
                style={{
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 10,
                  width: "100%",
                  margin: 50
                }}
                className="text-center justify-content-center align-content-center"
              >
                Already Selected All The Balancers.
              </p>
            ) : (
              <Modal.Body>
                <FormControl
                  placeholder="Search Balancers"
                  value={balancerSearch}
                  onChange={(e) => setBalancerSearch(e.target.value)}
                />
                <Form.Check
                  type="checkbox"
                  className="my-2"
                  label="Select All Balancers"
                  checked={
                    users?.some((user) =>
                      user.username
                        .toLowerCase()
                        .includes(balancerSearch.toLowerCase())
                    ) &&
                    users
                      ?.filter(
                        (user) =>
                          !active_balancers?.some((b) => b.id === user.id)
                      )
                      .every((user) =>
                        newBalancers.some((selected) => selected.id === user.id)
                      )
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const toAdd = users
                        ?.filter((user) =>
                          user.username
                            .toLowerCase()
                            .includes(balancerSearch.toLowerCase())
                        )
                        ?.filter(
                          (user) =>
                            !active_balancers?.some((b) => b.id === user.id) &&
                            !newBalancers.some(
                              (selected) => selected.id === user.id
                            )
                        );

                      setNewBalancers([...newBalancers, ...toAdd]);
                    } else {
                      const toRemoveIds = users
                        ?.filter((user) =>
                          user.username
                            .toLowerCase()
                            .includes(balancerSearch.toLowerCase())
                        )
                        ?.map((user) => user.id);

                      setNewBalancers(
                        newBalancers.filter((b) => !toRemoveIds.includes(b.id))
                      );
                    }
                  }}
                />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    padding: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 5
                  }}
                >
                  {users
                    ?.filter((user) =>
                      user.username
                        .toLowerCase()
                        .includes(balancerSearch.toLowerCase())
                    )
                    ?.filter(
                      (user) => !active_balancers?.some((b) => b.id === user.id)
                    )
                    .map((user) => (
                      <div
                        key={user.id}
                        className="d-flex justify-content-between"
                      >
                        <span>{user.username}</span>
                        <Form.Check
                          checked={newBalancers.some((b) => b.id === user.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setNewBalancers([...newBalancers, user]);
                            } else {
                              setNewBalancers(
                                newBalancers.filter((b) => b.id !== user.id)
                              );
                            }
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div className="mt-3 text-end">
                  <Button onClick={addBalancers}>Add</Button>
                </div>
              </Modal.Body>
            )}
          </Modal>

          <div className="mt-3 justify-content-center d-flex w-100">
            <Button
              onClick={() => {
                updateData();
              }}
              disabled={saving || isPending}
              size="sm"
              className="px-5"
            >
              {saving || isPending ? "Creating..." : "Create"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSchedular;
