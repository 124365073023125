import ColorIndications from "assets/colorIndications";
import { useGetVendorsList } from "components/api/api";
import { useGetRestaurantList } from "components/api/api";
import { useGetInvoiceListing, useSearchInvoice } from "components/api/api";
import Skeleton from "components/common/Skeleton";
import CustomDropdown from "components/CustomDropdown";
import { FilterDropdown } from "components/layout/FilterDropdown";
import { HomePagination } from "components/layout/HomePagination";
import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { NavHeader } from "components/layout/NavHeader";
import { RestaurantDropdown } from "components/layout/RestaurantDropdown";
import { VendorDropdown } from "components/layout/VendorDropdown";
import useUpdateParams from "hooks/useUpdateParams";
import { useGetAllUsers } from "modules/vendorConsolidation/api";
import { useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Dropdown,
  Form,
  FormControl,
  Spinner
} from "react-bootstrap";
import { CheckCircle, PatchCheckFill, Search } from "react-bootstrap-icons";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
import useFilterStore from "store/persistentFilterStore";
import { vendorStore } from "store/vendorStore";
import { formatDate } from "utils/format";
const HomeTable = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const updateParmas = useUpdateParams();
  let page = searchParams.get("page") || 1;
  let page_size = searchParams.get("page_size") || 8;
  let invoice_type = searchParams.get("invoice_type") || "";
  let human_verification = searchParams.get("human_verification") || "";
  let human_verified = searchParams.get("human_verified") || "";
  let detected = searchParams.get("invoice_detection_status") || "";
  let rerun_status = searchParams.get("rerun_status") || "";
  let auto_accepted = searchParams.get("auto_accepted") || "";
  let start_date = searchParams.get("start_date") || "";
  let end_date = searchParams.get("end_date") || "";
  let clickbacon_status = searchParams.get("clickbacon_status") || "";
  let restaurant =
    searchParams.get("restaurant_id") || searchParams.get("restaurant") || "";
  let vendor =
    searchParams.get("vendor_id") || searchParams.get("vendor") || "";
  let sort_order = searchParams.get("sort_order") || "desc";
  let invoice_number = searchParams.get("invoice_number") || "";
  let assigned_to = searchParams.get("assigned_to");
  const { setFilters, clearFIlters, setDefault, filters } = useFilterStore();
  const { setPrefetchedLinks, setTotalPages, totalPages } =
    invoiceDetailStore();
  const [searchResultVisible, setSearchResutsVisible] = useState(false);

  const payload = {
    auto_accepted: auto_accepted,
    end_date: end_date,
    human_verification: human_verification,
    detected: detected,
    invoice_type: invoice_type,
    clickbacon_status: clickbacon_status,
    rerun_status: rerun_status,
    restaurant: restaurant,
    start_date: start_date,
    vendor: vendor,
    page_size,
    page,
    sort_order,
    human_verified,
    assigned_to
  };
  const { data, isLoading } = useGetInvoiceListing(payload);

  const [search, setSearch] = useState(invoice_number);
  useEffect(() => {
    if (data?.total_pages) {
      setTotalPages(data?.total_pages);
    }
  }, [data]);
  const { mutate, isPending: loadingInvoiceNumebers } = useSearchInvoice();
  const [matchedInvoices, setMatchedInvoices] = useState({});
  const updateParams = useUpdateParams();

  const { data: userData, isLoading: userDataLoading } = useGetAllUsers();
  const handleSearch = () => {
    if (!search) return;
    setSearchResutsVisible(true);

    updateParams({ invoice_number: search });

    mutate(
      { invoice_number: search, ...payload },
      {
        onSuccess: (data) => {
          setMatchedInvoices(data?.data || []);
        },
        onError: (error) => {
          console.error("Search failed:", error);
          setMatchedInvoices([]); // Clear results on error
        }
      }
    );
  };

  useEffect(() => {
    setFilters({ ...filters, assigned_to: assigned_to });
  }, [assigned_to]);

  const [isOpen, setIsOpen] = useState(false);
  const [vendorSearch, setVendorSearch] = useState("");
  const [restaurantSearch, setRestaurantSearch] = useState("");
  let restaurant_id =
    searchParams.get("restaurant_id") || searchParams.get("restaurant") || "";
  const { setSearchedVendors } = vendorStore();

  const { data: restaurants, isLoading: loadingRestaurants } =
    useGetRestaurantList();

  const {
    data: vendors,
    isLoading: loadinVendors,
    isError,
    error
  } = useGetVendorsList();
  let vendor_id =
    searchParams.get("vendor_id") || searchParams.get("vendor") || "";
  function calculateTimeDifference(dueDate) {
    const now = new Date();
    const timeDiff = dueDate - now;

    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    if (timeDiff <= 0) {
      return `Due  ${hours}h ${minutes}m ago`;
    }
    return `${hours}h ${minutes}m`;
  }

  return (
    <>
      <NavHeader view="home"></NavHeader>
      <InvoiceHeader>
        <div className="d-flex justify-content-between w-100">
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <FilterDropdown />
            <Dropdown>
              <Dropdown.Toggle className="bg-white border-0" variant="light">
                Restaurants
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div
                  style={{
                    textAlign: "left",

                    maxWidth: "300px",
                    minWidth: "300px",
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                >
                  <FormControl
                    placeholder="Search Restaurant"
                    className="sticky-lg-top"
                    value={restaurantSearch}
                    onChange={(e) => setRestaurantSearch(e.target.value)}
                  />
                  {restaurants?.data
                    ?.filter((re) => re.restaurant_id)
                    ?.filter((res) =>
                      res?.restaurant_name
                        ?.toLowerCase()
                        ?.includes(restaurantSearch?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      const isCheckedA = restaurant_id?.includes(
                        a.restaurant_id
                      )
                        ? 1
                        : 0;
                      const isCheckedB = restaurant_id?.includes(
                        b.restaurant_id
                      )
                        ? 1
                        : 0;
                      return isCheckedB - isCheckedA;
                    })
                    ?.map((r) => {
                      return (
                        <div
                          className="p-2"
                          key={r.restaurant_id}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 10
                            }}
                          >
                            <span>
                              {r?.restaurant_name || r?.restaurant_id}
                            </span>
                            <Form.Check
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let existing =
                                    restaurant_id?.split(",")?.length;

                                  if (existing >= 0) {
                                    let finalString = `${restaurant_id},${r.restaurant_id}`;

                                    updateParams({
                                      restaurant_id:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,

                                      restaurant:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,

                                      page: 1
                                    });
                                    setFilters({
                                      ...filters,
                                      restaurant:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,

                                      page: 1
                                    });
                                  }
                                } else {
                                  let existing_ids = restaurant_id?.split(",");
                                  let filteredString = existing_ids
                                    ?.filter((i) => i !== r.restaurant_id)
                                    ?.join(",");

                                  updateParams({
                                    restaurant_id: filteredString,
                                    page: 1,
                                    restaurant: filteredString
                                  });

                                  setFilters({
                                    ...filters,
                                    restaurant: filteredString,
                                    page: 1
                                  });
                                }
                              }}
                              checked={restaurant_id?.includes(r.restaurant_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="  custom-dropdown-container py-0 ">
              <Dropdown.Toggle
                variant="light"
                className="dropdown-toggle bg-white border-0"
              >
                Vendors
              </Dropdown.Toggle>
              <Dropdown.Menu className=" bg-white">
                <div
                  style={{
                    textAlign: "left",

                    maxWidth: "300px",
                    minWidth: "300px",
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                >
                  <FormControl
                    placeholder="Search Vendor"
                    className="sticky-lg-top"
                    value={vendorSearch}
                    onChange={(e) => setVendorSearch(e.target.value)}
                  />
                  {vendors?.data?.vendor_names
                    ?.filter((ve) => ve.vendor_name)
                    ?.filter((ven) =>
                      ven?.vendor_name
                        ?.toLowerCase()
                        ?.includes(vendorSearch?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      const isCheckedA = vendor_id?.includes(a.vendor_id)
                        ? 1
                        : 0;
                      const isCheckedB = vendor_id?.includes(b.vendor_id)
                        ? 1
                        : 0;
                      return isCheckedB - isCheckedA;
                    })
                    ?.map((v) => {
                      return (
                        <div
                          className="p-2"
                          key={v.vendor_id}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 10
                            }}
                          >
                            <span>
                              {v?.vendor_name}

                              {v?.human_verified && (
                                <PatchCheckFill className="mx-2" fill="blue" />
                              )}
                            </span>
                            <Form.Check
                              className="cursor-pointer"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let existing = vendor_id?.split(",")?.length;
                                  if (existing > 0) {
                                    let finalString =
                                      vendor_id + "," + v.vendor_id;
                                    updateParams({
                                      vendor_id:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      vendor:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,

                                      page: 1
                                    });
                                    setFilters({
                                      ...filters,
                                      vendor_id:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      vendor:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,

                                      page: 1
                                    });
                                  }
                                } else {
                                  let existing_ids = vendor_id?.split(",");
                                  let filteredString = existing_ids?.filter(
                                    (i) => i !== v.vendor_id
                                  );
                                  updateParams({
                                    vendor_id: filteredString,
                                    vendor: filteredString,
                                    page: 1
                                  });
                                  setFilters({
                                    ...filters,
                                    vendor: filteredString,
                                    page: 1,
                                    vendor_id: filteredString
                                  });
                                }
                              }}
                              checked={vendor_id?.includes(v.vendor_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              onToggle={(visible) => setSearchResutsVisible(visible)}
              show={searchResultVisible}
              drop="start"
              className=" flex-1 m-0"
            >
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  flexDirection: "row",
                  minWidth: "300px"
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Invoice"
                  aria-label="Search Invoice"
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && search) {
                      handleSearch();
                    }
                  }}
                  value={search}
                />
                <Button
                  variant="light"
                  disabled={!search}
                  onClick={() => {
                    handleSearch();
                  }}
                  className="before-content-none"
                >
                  <Search />
                </Button>
              </div>
              <Dropdown.Menu
                style={{
                  minWidth: "300px",
                  maxWidth: "500px",
                  background: "white",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  maxHeight: "250px",
                  overflowY: "auto",
                  marginTop: "10px"
                }}
              >
                <div className="d-flex justify-content-between mx-2">
                  <span>Matching Invoice Numbers</span>
                  <CloseButton
                    onClick={() => {
                      setSearchResutsVisible(false);
                      setSearch("");
                      updateParams({ invoice_number: undefined });
                    }}
                  />
                </div>
                <hr />
                {loadingInvoiceNumebers ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "140px" }}
                  >
                    <Spinner />
                  </div>
                ) : matchedInvoices.length > 0 ? (
                  matchedInvoices.map(
                    ({ document_uuid, invoice_number, vendor_name }) => (
                      <div
                        key={document_uuid}
                        onClick={() =>
                          navigate(
                            `/invoice-details?document_uuid=${document_uuid}`
                          )
                        }
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "8px 12px",
                          cursor: "pointer"
                        }}
                      >
                        <span>Inv. No. {invoice_number}</span>
                        <span>{vendor_name}</span>
                      </div>
                    )
                  )
                ) : (
                  <p className="text-center">No Invoice Found.</p>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Link
            className="mx-1"
            to="/vendor-consolidation-v2"
            style={{ textDecoration: "none" }}
          >
            <Button variant="info">Vendor Consolidation</Button>
          </Link>
        </div>
      </InvoiceHeader>
      <div
        className="mx-5"
        style={{
          marginTop: "35px"
        }}
      >
        <>
          <ColorIndications />
          <Table
            striped
            bordered
            hover
            variant="light"
            responsive
            className="shadow-sm"
          >
            <thead>
              <tr>
                <th>InvoiceID</th>
                <th>Source/Channel</th>
                <th>Restaurant</th>
                <th>Vendor</th>
                <th>Load Date</th>
                <th>Balance Status</th>
                <th>Final Status</th>
                <th>clickBACON Status</th>
                <th>Failure Cause Code</th>
                <th>Accepted/Rejected</th>
                <th>Rejected Reasons</th>
                <th>Invoice Type</th>
                <th>Due Time</th>
                <th>Human Verification Date</th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? [0, 1, 2, 3, 4, 5, 6, 7]?.map((_, index) => {
                    return (
                      <tr key={index}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]?.map(
                          (_, i) => {
                            return (
                              <td
                                key={i}
                                style={{
                                  height: "7vh",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  paddingTop: 30
                                }}
                              >
                                <Skeleton />
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })
                : data?.data?.length > 0 &&
                  data?.data?.map((item, i) => {
                    const timeRemaining = calculateTimeDifference(
                      new Date(item?.assignment_details?.verification_due_at)
                    );

                    return (
                      <tr
                        onClick={() => {
                          navigate(
                            `/invoice-details/?page_number=${
                              (page - 1) * 8 + (i + 1)
                            }`
                          );
                        }}
                        key={`${item.invoice_index}${i}`}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        style={{
                          height: "65px",
                          cursor: "pointer",
                          width: "100%"
                        }} // Set the height here
                      >
                        {/* <Tooltip id={item?.invoice_index} /> */}
                        <td
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            whiteSpace: "normal"
                          }}
                        >
                          {item?.invoice_number}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item.channel}
                        </td>
                        <td>
                          {item?.restaurant?.restaurant_name ||
                            item?.restaurant?.restaurant_id}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.vendor?.vendor_name}
                          {item?.vendor?.human_verified && (
                            <PatchCheckFill
                              style={{ color: "blue", marginLeft: "5px" }}
                            />
                          )}
                        </td>
                        <td>{formatDate(item?.date_uploaded)}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.balance_type}
                        </td>

                        <td>
                          {!["auto", "manual"].includes(
                            item?.balance_type?.toLowerCase()
                          ) ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={"red"}
                            >
                              <circle fill={"red"} cx="8" cy="8" r="8" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={"green"}
                            >
                              <circle fill={"green"} cx="8" cy="8" r="8" />
                            </svg>
                          )}
                        </td>
                        <td>{item?.clickbacon_status}</td>

                        <td style={{ verticalAlign: "top" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill={
                              item.document_failed_cause_code === 0 ||
                              item.document_failed_cause_code === 1 ||
                              item.document_failed_cause_code === 2 ||
                              item.document_failed_cause_code === 3 ||
                              item.document_failed_cause_code === 4
                                ? "red"
                                : item.document_failed_cause_code === 5
                                ? "#FFEF00"
                                : item.document_failed_cause_code === 6
                                ? "orange"
                                : item.document_failed_cause_code === -1
                                ? "green"
                                : "black"
                            }
                          >
                            <circle
                              fill={
                                item.document_failed_cause_code === 0 ||
                                item.document_failed_cause_code === 1 ||
                                item.document_failed_cause_code === 2 ||
                                item.document_failed_cause_code === 3 ||
                                item.document_failed_cause_code === 4
                                  ? "red"
                                  : item.document_failed_cause_code === 5
                                  ? "#FFEF00"
                                  : item.document_failed_cause_code === 6
                                  ? "orange"
                                  : item.document_failed_cause_code === -1
                                  ? "green"
                                  : "black"
                              }
                              cx="8"
                              cy="8"
                              r="8"
                            />
                          </svg>
                        </td>

                        <td style={{ textTransform: "capitalize" }}>
                          {item?.auto_accepted === true
                            ? "Auto Accepted"
                            : item?.rejected === true
                            ? "Rejected"
                            : item?.human_verified == true
                            ? "Accepted"
                            : ""}
                        </td>

                        <td>{item?.rejection_reason}</td>
                        <td>{item?.invoice_type}</td>

                        <td>
                          {" "}
                          {item?.assignment_details &&
                          (item?.rejected || item?.human_verified)
                            ? "Completed"
                            :item?.assignment_details&& timeRemaining?.split("-").join("") || "NA"}
                        </td>
                        <td>{formatDate(item?.human_verified_date)}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          <HomePagination />
        </>
      </div>
    </>
  );
};

export { HomeTable };
