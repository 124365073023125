import { create } from "zustand";

export const vendorStore = create((set, get) => ({
  vendorsToMerge: [],
  masterVendor: null,
  searchedVendors:{},
  setSearchedVendors:(data)=>set({searchedVendors:data}),

  setMasterVendor: (id) => set({ masterVendor: id }),
  setVendorsToMerge: (vendors) => set({ vendorsToMerge: vendors })
}));
