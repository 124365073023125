import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { NavHeader } from "components/layout/NavHeader";
import Header from "modules/vendorConsolidation/components/Header";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { useGetSchedularConfiguration, useGetSchedularListing } from "../api";
import { PlusCircle } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "components/common/Spinner";

const SchedularListing = () => {
  const { data, isLoading } = useGetSchedularListing();
  const navigate=useNavigate()

  return (
    <>
      <NavHeader />
      <Header text="Schedulars">
        <Link to={"/create-schedular"}>
          <Button
            className="d-flex gap-2 align-items-center justify-content-center px-2"
            size="sm"
          >
            <PlusCircle /> <span>Add New Schedular </span>
          </Button>
        </Link>
      </Header>

      {isLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className="px-5 mt-5">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Schedular Id</th>
                <th>Schedular Name</th>
                <th>Schedular Status</th>
                <th>Active Restaurants</th>
                <th>Active Balancers</th>
                <th>Verification Hours</th>
                <th>Verification Minutes</th>
                <th>Total Documents</th>
                <th>Unverified Documents        </th>
                <th>Verified Documents        </th>
                <th>Over Due Documents</th>
              </tr>
            </thead>

            <tbody>
              {data?.map(
                ({
                  scheduler_id,
                  name,
                  active_balancers,
                  active_restaurants,
                  verification_hours,
                  verification_minutes,
                  total_documents,
                  unverified_documents,
                  over_due_documents,
                  scheduler_status,verified_documents
                }) => {
                  return (
                    <tr key={scheduler_id} onClick={()=>navigate(`/configuration/${scheduler_id}`)}>
                      <td>{scheduler_id}</td>
                      <td>{name}</td>
                      <td>{scheduler_status ? "Yes" : "NO"}</td>
                      <td>{active_restaurants}</td>
                      <td>{active_balancers}</td>
                      <td>{verification_hours}</td>
                      <td>{verification_minutes}</td>
                      <td>{total_documents}</td>
                      <td>{unverified_documents}</td>
                      <td>{verified_documents}</td>
                      <td>{over_due_documents}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default SchedularListing;
