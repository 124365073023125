import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Spinner } from "components/common/Spinner";
import {
  useGetBalancerAssignments,
  useGetSchedularListing,
  useReassignBalancerOrSchedular
} from "../api";
import { useSearchParams } from "react-router-dom";
import useUpdateParams from "hooks/useUpdateParams";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill
} from "react-bootstrap-icons";
import { useFetchAllUsers } from "modules/userActivity/api";
import CustomDropdown from "components/CustomDropdown";
import { useGetAllUsers } from "modules/vendorConsolidation/api";
import { toast } from "react-toastify";

const SchedularsTable = () => {
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [reassigning, setReassigning] = useState(false);
  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    setBalancerId(null)
    // setBalancerId(null)
    setSchedularId(null)
  };

  const handleSubmit = () => {
    alert(`Selected option: ${selectedOption}`);
  };
  let page = searchParams.get("balancers_page_no") || 1;
  let page_size = searchParams.get("balancers_page_size") || 5;
  const { data, isLoading } = useGetBalancerAssignments({ page, page_size });
  const { mutate, isPending } = useReassignBalancerOrSchedular();
  const [schedular_id, setSchedularId] = useState(null);
  const [balancer_id, setBalancerId] = useState(null);
  const [balancer_UUID, setBalancerUUID] = useState(null);
  const { data: userData, isLoading: userDataLoading } = useGetAllUsers();
  let totalPages = data?.total_pages;
  const [pageIndex, setPageIndex] = useState(1);
  const { data: schedulars, isLoading: loadingSchedulars } =
    useGetSchedularListing();

  const handleReassignment = () => {
    // console.log(schedular_id,SCHEDULAR_ID,balancer_id,selectedOption)

    setReassigning(true);
    mutate(
      {
        schedular_id:schedular_id,
        balancer_id: balancer_id,
        balancer_uuid: balancer_UUID,
        assign_to: selectedOption,

      },
      {
        onSuccess: (data) => {
          toast.success(data?.message);
          setReassigning(false);
        },
        onError: () => {
          setReassigning(false);
        }
      }
    );
  };
  return (
    <div className="px-5 mt-4">
    {
      isLoading?<Spinner/>:<>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Balancer</th>
            <th>Total Assigned Documents</th>
            <th>Unverified Documents</th>
            <th>Verified Documents</th>
            <th>Overdue Documents</th>
            <th>Assigned Schedulars</th>
            <th>Re Assignment</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map(
            ({
              user,
              overdue_documents,
              total_documents,
              unverified_documents,
              verified_documents,
              assigned_schedulers
            }) => {
              return (
                <tr key={user?.id}>
                  <td>{user?.username}</td>
                  <td>{total_documents}</td>
                  <td>{unverified_documents}</td>
                  <td>{verified_documents}</td>
                  <td>{overdue_documents}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                      }}
                    >
                      {assigned_schedulers?.map((s, i) => {
                        return <p key={i}>{s}</p>;
                      })}
                    </div>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="px-4"
                      onClick={() => {
                        setShowModal(true);
                        setBalancerUUID(user?.id);
                      }}
                    >
                      Click
                    </Button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
      <div className="my-4">
        <button
          className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
          disabled={page == 1}
          onClick={() => {
            updateParams({ balancers_page_no: Number(page) - 1 });
            setPageIndex(page - 1);
          }}
        >
          <ArrowLeftCircleFill size={40} />
        </button>
        <span className="my-4 mx-2">
          <input
            value={pageIndex}
            onChange={(e) => setPageIndex(e.target.value)}
            onBlur={() => {
              if (pageIndex >= totalPages) {
                setPageIndex(totalPages);
                updateParams({ balancers_page_no: totalPages });
              } else if (pageIndex <= 1) {
                setPageIndex(1);
                updateParams({ balancers_page_no: 1 });
              } else {
                updateParams({ balancers_page_no: pageIndex });
              }
            }}
            className="btn btn-secondary"
            style={{ width: "9ch" }}
          />
          <span className="my-4">
            {" "}
            <strong>/</strong>{" "}
            {totalPages ? (
              <input
                disabled
                value={`${totalPages}`}
                className="btn btn-secondary"
                style={{ width: "9ch", cursor: "default" }}
              />
            ) : (
              <input
                disabled
                value={pageIndex}
                className="btn btn-secondary"
                style={{ width: "9ch", cursor: "default" }}
              />
            )}
          </span>
        </span>
        <button
          className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
          disabled={totalPages == 1 || totalPages == page}
          onClick={() => {
            if (page <= totalPages) {
              setPageIndex(Number(page) + 1);
              updateParams({ balancers_page_no: Number(page) + 1 });
            }
          }}
        >
          <ArrowRightCircleFill size={40} />
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title className="m-2 mx-4">Re Assign Invoices</Modal.Title>
        <Modal.Body className="px-4">
          <Form>
            <Form.Group className="d-flex align-content-center gap-3">
              <Form.Label>Assign To : -</Form.Label>
              <div className="d-flex gap-2 align-content-center ">
                <Form.Check
                  type="radio"
                  id="option1"
                  name="options"
                  label="Balancer"
                  value="balancer"
                  checked={selectedOption === "balancer"}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  id="option2"
                  name="options"
                  label="Schedular"
                  value="scheduler"
                  checked={selectedOption === "scheduler"}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Form>

          {!userDataLoading && selectedOption == "balancer" && (
            <CustomDropdown
              data={[{ id: undefined, username: "None" }, ...userData?.data]}
              Objectkey={"id"}
              displayKey={"username"}
              className="w-auto"
              maxWidth="180px"
              minWidth="180px"
              value={
                userData?.data?.find((user) => user?.id === balancer_id)
                  ?.username || "Select Balancer"
              }
              onSelect={(v) => {
                // updateParams({ verified_by: v, page: 1 });
                setBalancerId(v);
              }}
            />
          )}

          {selectedOption == "scheduler" && (
            <CustomDropdown
              data={[...schedulars]}
              Objectkey={"scheduler_id"}
              displayKey={"name"}
              className="w-auto"
              maxWidth="180px"
              minWidth="180px"
              value={
                schedulars?.find((user) => user?.scheduler_id === schedular_id)
                  ?.name || "Select Schedular"
              }
              onSelect={(v) => {
                // updateParams({ verified_by: v, page: 1 });
                console.log(v);
                setSchedularId(v);
              }}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              handleReassignment();
            }}
          >
            {!reassigning ? "Re-Assign" : "Re-Assigning..."}
          </Button>
          <div className="d-flex justify-content-end gap-2">
            <Button
              variant="danger"
              onClick={() => {
                setShowModal(false);
                setBalancerId(null);
                setSchedularId(null);
                setBalancerUUID(null)
                setSelectedOption(null)
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal></>
    }
    </div>
  );
};

export default SchedularsTable;
