import Notes from "components/Comments/Notes";
import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  FileEarmark,
  FileEarmarkPdf,
  JournalCheck,
  XCircle
} from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { globalStoreV2 } from "store/globalStoreV2";
import { useGetVendorDetails } from "./api";
import Header from "./components/Header";
import VendorDetailsTable from "./components/VendorDetails";
import { instance } from "lib/axios";
import { PdfViewer } from "modules/InvoiceDetails/components/PdfViewer";

export default function VendorDetails() {
  const { vendorID } = useParams();
  const { actual_vendor_name } = globalStoreV2();
  const { data, isLoading } = useGetVendorDetails(vendorID);
  // const {data:venorNotes,isLoading:loadingNotes}=useGetVendorNotes(vendorID)
  const tooltip = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  const pdfTooltip = <Tooltip id="tooltip">View Vendor Pdfs</Tooltip>;
  const [showNotes, setShowNotes] = useState(false);
  const [showPdfs, setShowPdfs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfsData, setPdfsData] = useState();
  const getInvoicePdfs = async () => {
    setLoading(true);
    const body = {
      fetch_mode: "vendor_id",
      vendors: [vendorID]
    };
    const response = await instance.post("/api/vendor/pdf/", body);
    if (response?.status === 200) {
      // setPdfsData(response?.data?.data);
      setPdfsData(response?.data?.data?.[vendorID]);
      setLoading(false);
    } else {
      // setLoading(false)
    }
  };
  useEffect(() => {
    if (showPdfs) {
      getInvoicePdfs();
    }
  }, [showPdfs]);

  return (
    <>
      <NavHeader view="Vendor Details" />

      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header
            text={`Vendor Details for ${actual_vendor_name} `}
            archived={data?.data?.vendor_archived}
          >
            <OverlayTrigger placement="left" overlay={tooltip}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                disabled={vendorID === null}
              >
                <JournalCheck
                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
            </OverlayTrigger>
            {showPdfs ? (
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowPdfs(!showPdfs)}
              >
                <XCircle />
              </Button>
            ) : (
              <OverlayTrigger placement="left" overlay={pdfTooltip}>
                <Button
                  variant="success"
                  className="mx-2"
                  onClick={() => setShowPdfs(!showPdfs)}
                >
                  <FileEarmarkPdf
                    height={20}
                    width={20}
                    fill="white"
                    className="cursor-pointer warning"
                  />
                </Button>
              </OverlayTrigger>
            )}
          </Header>
          <Row style={{ overflow: "hidden", margin: 0 }}>
            <Col>
              <VendorDetailsTable
                data={data}
                // handleSaveVendorDetails={handleSaveVendorDetails}
              />
            </Col>
            {showPdfs && !loading && (
              <Col className="pt-5">
                <PdfViewer
                  pdfUrls={pdfsData}
                  isLoading={loading}
                  multiple={true}
                />
                {!pdfsData && <p>No Pdfs Available</p>}
              </Col>
            )}
          </Row>
          <Notes
            show={showNotes}
            setShow={setShowNotes}
            name="Vendor Notes"
            // data={venorNotes}
            vendorId={vendorID}
          />
        </>
      )}
      <ToastContainer />
    </>
  );
}
