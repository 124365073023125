import { create } from "zustand";
import { persist } from "zustand/middleware";

export const globalStoreV2 = create(
  persist(
    (set) => ({
      actual_vendor_name: "",
      vendorsToMerge: [],
      masterVendor: null,
      additionalData:{},
      setAdditionalData: (data) => set({ additionalData: data }),
      setMasterVendor: (id) => set({ masterVendor: id }),
      setVendorsToMerge: (vendors) => set({ vendorsToMerge: vendors }),
      setActual_vendor_name: (name) => set({ actual_vendor_name: name }),
   
      clearStore: () =>
        set({
          actual_vendor_name: "",
          vendorsToMerge: [],
          masterVendor: null,
       
        })
    }),
    {
      name: "global-persist-store",
      getStorage: () => localStorage
    }
  )
);
