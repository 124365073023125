import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  Search
} from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import { useCombineVendorBranches, useGetVendorBranches } from "./api";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useUpdateParams from "hooks/useUpdateParams";
import VendorBranchesTable from "./components/VendorBranchesTable";
import { globalStoreV2 } from "store/globalStoreV2";

export default function VendorBranches() {
  const { vendorID: vendor_id } = useParams();
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  let vendor_address = searchParams.get("vendor_address") || "";
  let page = searchParams.get("page") || 1;
  const [combining, setCombining] = useState(false);
  const [searchVendorAddress, setSearchVendorAddress] =
    useState(vendor_address);
  const [pageIndex, setPageIndex] = useState(page);

  const { data, isLoading } = useGetVendorBranches({
    vendor_id,
    vendor_address,
    page: page
  });
  let totalPages = data?.total_pages || 1;
  const { mutate: combineVendorBranches } = useCombineVendorBranches();

  const { masterVendor, setMasterVendor, vendorsToMerge, setVendorsToMerge } =
    globalStoreV2();
  useEffect(() => {
    setMasterVendor(null);
    setVendorsToMerge([]);
  }, []);

  useEffect(() => {
    setPageIndex(page);
  }, [page]);
  return (
    <>
      <NavHeader view="branches" />
      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header
            view="vendor branches"
            text={`Vendor Branches`}
            children={
              <div className="d-flex justify-content-end">
                <span className="mr-3">
                  Total: {data?.data?.total_branch_count}
                </span>
                <ProgressBar
                  now={data?.data?.verified_branch_count * 100}
                  max={data?.data?.total_branch_count * 100}
                  label={`${data?.data?.verified_branch_count}/${data?.data?.total_branch_count}`}
                  variant="success"
                  style={{
                    backgroundColor: "white",
                    width: "300px",
                    marginTop: "5px",
                    marginLeft: "10px"
                  }}
                />
              </div>
            }
          />
          <div className="row mt-3 mx-5">
            <div className="col-md-6 d-flex">
              <input
                type="text"
                value={searchVendorAddress}
                className="form-control"
                placeholder="Search by vendor address"
                onChange={(e) => {
                  if (e.target.value == "") {
                    updateParams({ vendor_address: undefined });
                    setSearchVendorAddress("");
                  } else {
                    setSearchVendorAddress(e.target.value);
                  }
                }}
              />
              <button
                className="btn btn-light"
                onClick={() => {
                  updateParams({ vendor_address: searchVendorAddress });
                }}
              >
                <Search />
              </button>
            </div>
          </div>
          <VendorBranchesTable data={data?.data} />

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              style={{ width: "75%" }}
              onClick={() => {
                setCombining(true);
                combineVendorBranches(
                  {
                    branch_id: masterVendor,
                    branches_to_merge: vendorsToMerge
                  },
                  {
                    onSuccess: () => {
                      setCombining(false);
                      setMasterVendor(null);
                      setVendorsToMerge([]);
                    },
                    onError: () => {
                      setCombining(false);
                    }
                  }
                );
              }}
              disabled={
                vendorsToMerge.length === 0 || !masterVendor || combining
              }
            >
              {combining
                ? "Combining Vendor Branches........"
                : "Combine Vendor Branches"}
            </button>
          </div>

          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={pageIndex == 1 || totalPages == 1}
                onClick={() => {
                  setPageIndex((prev) => prev - 1);
                  updateParams({ page: Number(page) - 1 });
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={pageIndex}
                  onChange={(e) => {
                    if (e.target.value >= totalPages) {
                      setPageIndex(Number(totalPages));
                    } else {
                      setPageIndex(Number(e.target.value));
                    }
                  }}
                  onBlur={() => {
                    updateParams({ page: pageIndex });
                  }}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${totalPages || 1}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={totalPages == 1 || data?.is_final_page}
                onClick={() => {
                  if (data?.is_final_page) {
                  } else {
                    setPageIndex((prev) => Number(prev) + 1);
                    updateParams({ page: Number(page) + 1 });
                  }
                  // setTempValue(tempValue + 1);
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}
