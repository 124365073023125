import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSearchParams } from "react-router-dom";
import {
  humanVerifiedFilters,
  invoiceTypeFilters,
  invoiceDetectedFilters,
  rerunStatusFilters,
  autoAcceptedFilters,
  clickbaconFilters,
  sortFilters
} from "constants/filtersOptions";
import useUpdateParams from "hooks/useUpdateParams";
import useFilterStore from "store/persistentFilterStore";
import { humanVerificationFilters } from "constants/filtersOptions";

// Function to format date as "YYYY-MM-DD"
export const formatDate = (date) => {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(localDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const FilterDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  const { setFilters } = useFilterStore();

  // State to manage selected date range
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });

  // Clear all filters including the date range
  const clearFilters = () => {
    updateParams({
      human_verification: undefined,
      sort_order: undefined,
      rerun_status: undefined,
      clickbacon_status: undefined,
      human_verified: undefined,
      invoice_type: undefined,
      invoice_detection_status: undefined,
      auto_accepted: undefined,
      start_date: undefined,
      end_date: undefined
    });
    setFilters({
      human_verification: undefined,
      sort_order: undefined,
      human_verified: undefined,
      rerun_status: undefined,
      clickbacon_status: undefined,
      invoice_type: undefined,
      invoice_detection_status: undefined,
      auto_accepted: undefined,
      start_date: undefined,
      end_date: undefined
    });
    setDateRange({ startDate: null, endDate: null });
  };

  return (
    <Dropdown autoClose>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{
          background: "white",
          color: "black",
          border: "1px solid white"
        }}
      >
        Filter
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-2" autoClose>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two equal-width columns
            gap: "10px", // Adjust gap between items
            width: "400px" // Ensure it takes full width
          }}
        >
          {/* Human Verification Filter */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="verification">Human Verification</label>
            <select
              id="verification"
              className="form-select"
              onChange={(e) => {
                updateParams({ human_verification: e.target.value, page: 1 });
                setFilters({ human_verification: e.target.value, page: 1 });
              }}
              value={searchParams.get("human_verification") || "all"}
            >
              {humanVerificationFilters?.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="verified">Human Verified</label>
            <select
              id="verified"
              className="form-select"
              onChange={(e) => {
                updateParams({ human_verified: e.target.value, page: 1 });
                setFilters({ human_verified: e.target.value, page: 1 });
              }}
              value={searchParams.get("human_verified") || "all"}
            >
              {humanVerifiedFilters?.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>

          {/* Invoice Type Filter */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="invoiceType">Invoice Type</label>
            <select
              id="invoiceType"
              className="form-select"
              onChange={(e) => {
                updateParams({ invoice_type: e.target.value, page: 1 });
                setFilters({ invoice_type: e.target.value, page: 1 });
              }}
              value={searchParams.get("invoice_type") || "all"}
            >
              {invoiceTypeFilters?.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>

          {/* Invoice Detection Status */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="invoiceDetectionStatus">
              Invoice Detection Status
            </label>
            <select
              id="invoiceDetectionStatus"
              className="form-select"
              onChange={(e) => {
                updateParams({
                  invoice_detection_status: e.target.value?.toLowerCase(),
                  page: 1
                });
                setFilters({
                  invoice_detection_status: e.target.value?.toLowerCase(),
                  page: 1
                });
              }}
              value={searchParams.get("invoice_detection_status") || "all"}
            >
              {invoiceDetectedFilters.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>

          {/* Invoice Re-Run Status */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="invoiceRerunStatus">Invoice Re-Run Status</label>
            <select
              id="invoiceRerunStatus"
              className="form-select"
              onChange={(e) => {
                updateParams({ rerun_status: e.target.value, page: 1 });
                setFilters({ rerun_status: e.target.value, page: 1 });
              }}
              value={searchParams.get("rerun_status") || "all"}
            >
              {rerunStatusFilters?.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>

          {/* Auto Accepted Filter */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="autoAccepted">Auto Accepted Filter</label>
            <select
              id="autoAccepted"
              className="form-select"
              onChange={(e) => {
                updateParams({ auto_accepted: e.target.value, page: 1 });
                setFilters({ auto_accepted: e.target.value, page: 1 });
              }}
              value={searchParams.get("auto_accepted") || "all"}
            >
              {autoAcceptedFilters?.map((option) => (
                <option key={option?.key} value={option?.value}>
                  {option?.key}
                </option>
              ))}
            </select>
          </div>

          {/* ClickBacon Status */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="clickBACONStatus">ClickBACON Status</label>
            <select
              id="clickBACONStatus"
              className="form-select"
              onChange={(e) => {
                updateParams({ clickbacon_status: e.target.value, page: 1 });
                setFilters({ clickbacon_status: e.target.value, page: 1 });
              }}
              value={searchParams.get("clickbacon_status") || "all"}
            >
              {clickbaconFilters?.map(({ key, value }) => (
                <option key={key} value={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>

          {/* Sort Order */}
          <div style={{ gridColumn: "span 1" }}>
            <label htmlFor="sortOrder">Sort Order</label>
            <select
              id="sortOrder"
              className="form-select"
              onChange={(e) => {
                updateParams({ sort_order: e.target.value, page: 1 });
                setFilters({ sort_order: e.target.value, page: 1 });
              }}
              value={searchParams.get("sort_order") || "desc"}
            >
              {sortFilters?.map(({ key, value }) => (
                <option key={key} value={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Date Range - Full Width */}
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 4,
            marginTop: 15
          }}
        >
          <div
            className="w-100 border-2"
            style={{
              // display: "flex",
              flexDirection: "column"
              // justifyContent: "center",
              // alignItems: "center"
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="dateRange" style={{ textAlign: "left" }}>
                Date Range
              </label>{" "}
              <span
                className="text-muted"
                style={{
                  fontSize: "12px",
                  marginLeft: "10px", // Adjusted spacing to keep it aligned nicely with the label
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => {
                  clearFilters();
                }}
              >
                Reset
              </span>
            </div>
            <div className="w-100 ">
              <DateRange
                style={{ width: "400px" }}
                id="dateRange"
                className="justify-content-center "
                editableDateInputs={true}
                onChange={(item) => {
                  const startDate = formatDate(item.selection.startDate);
                  const endDate = formatDate(item.selection.endDate);

                  // Update the date range in the state and the params
                  setDateRange({ startDate, endDate });
                  updateParams({
                    start_date: startDate,
                    end_date: endDate,
                    page: 1
                  });
                  setFilters({
                    start_date: startDate,
                    end_date: endDate,
                    page: 1
                  });
                }}
                moveRangeOnFirstSelection={false}
                ranges={[
                  {
                    startDate: dateRange.startDate
                      ? new Date(dateRange.startDate)
                      : new Date(),
                    endDate: dateRange.endDate
                      ? new Date(dateRange.endDate)
                      : new Date(),
                    key: "selection"
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
