import { useGetVendorAddresses, useGetVendorsList } from "components/api/api";
import { invoiceTypeFilters } from "constants/filtersOptions";
import { format, formatISO, isValid, parseISO } from "date-fns";
import { queryClient } from "lib/react-query";
import { useEffect, useState } from "react";
import { Button, Dropdown, FormControl } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";
import DatePicker from "react-date-picker";
import { Link, useNavigate } from "react-router-dom";

import { invoiceDetailStore } from "store/invoiceDetailsStore";
import { useUpdateVendorOrBranch } from "../api";

const MetadataTable = ({ data, isLoading, payload, additionaldata }) => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({
    savingVendor: false,
    savingBranch: false
  });
  const {
    setUpdatedFields,
    vendorChanged,
    setVendorChanged,
    branchChanged,
    setBranchChanged,
    newVendor,
    setNewVendor,
    newBranch,
    setNewBranch,
    editBranch,
    setEditBranch,
    editVendor,
    setEditVendor
  } = invoiceDetailStore();
  const {
    document_uuid,

    restaurant,
    vendor,
    human_verified,
    branch,
    document_type,

    version,
    rejected,
    rerun_status,

    quick_book_document_type,
    document_metadata,
    human_verification_required,
    invoice_type
  } = data?.data?.[0] || data?.data;

  const { data: vendorsData, isLoading: loadingVendors } = useGetVendorsList();
  const [vendorNameSearch, setVendorNameSearch] = useState("");
  const [vendorAddressSearch, setVendorAddressSearch] = useState("");

  const setCachedData = (key, value, setFields = true) => {
    // Normalize the data structure
    let normalizedData = Array.isArray(data?.data) ? data?.data : [data?.data];
    let updated = false;
    let isDocumentMetadata = false;

    // Iterate over normalized data to apply changes
    normalizedData.forEach((item, index) => {
      if (item?.document_metadata?.hasOwnProperty(key)) {
        normalizedData[index].document_metadata[key] = value;
        isDocumentMetadata = true;
        updated = true;
      } else if (item?.hasOwnProperty(key)) {
        normalizedData[index][key] = value;

        updated = true;
      }
    });

    // Update the original `data` object based on the normalization
    let updatedData = Array.isArray(data?.data)
      ? { ...data, data: normalizedData }
      : { ...data, data: normalizedData[0] };

    // Set the updated query data
    queryClient.setQueryData(["document-metadata", payload], updatedData);

    if (updated) {
      if (setFields) {
        setUpdatedFields((prevFields) => {
          if (isDocumentMetadata) {
            return {
              ...prevFields,
              document_metadata: {
                ...prevFields.document_metadata,
                [key]: value
              }
            };
          }
          return {
            ...prevFields,
            [key]: value
          };
        });
      }
    }
  };
  useEffect(() => {
    setUpdatedFields([]);
  }, []);
  const { data: vendorAddress, isLoading: loadingAddresses } =
    useGetVendorAddresses(vendor?.vendor_id);

  const { mutate: updateVendorOrBranch, isPending } = useUpdateVendorOrBranch();
  const updateVendor = (vendor) => {
    setLoadingState({ ...loadingState, savingVendor: true });
    updateVendorOrBranch(
      {
        document_uuid: document_uuid,
        data: {
          vendor_id: vendor?.vendor_id || null,
          vendor_name: vendor?.vendor_name
        },
        Key: "vendor"
      },
      {
        onSuccess: () => {
          setVendorChanged(false);
          setLoadingState({ ...loadingState, savingVendor: false });
          // queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
          setEditVendor(false);
          setNewVendor("");
        },
        onError: () => {
          setLoadingState({ ...loadingState, savingVendor: false });
        }
      }
    );
  };
  const updateBranch = (branch) => {
    setLoadingState({ ...loadingState, savingBranch: true });
    updateVendorOrBranch(
      {
        document_uuid: document_uuid,
        data: {
          branch_id: branch?.branch_id || null,
          vendor_address: branch?.vendor_address
        },
        Key: "branch"
      },
      {
        onSuccess: () => {
          setBranchChanged(false);
          setLoadingState({ ...loadingState, savingBranch: false });
          // queryClient.invalidateQueries({ queryKey: ["document-metadata"] });
          setEditBranch(false);
          setNewBranch("");
          // queryClient.invalidateQueries({ queryKey: ["vendor-addresses"] });
        },
        onError: () => {
          setLoadingState({ ...loadingState, savingBranch: false });
        }
      }
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "580px",
          overflowX: "auto",
          overflowY: "auto"
        }}
      >
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Number{" "}
              </th>
              <td
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <FormControl
                  style={{}}
                  onChange={(e) =>
                    setCachedData("invoice_number", e.target.value)
                  }
                  value={document_metadata?.invoice_number}
                />
                {human_verified === true && rejected === false && (
                  <span class="mx-2 badge bg-success">Accepted </span>
                )}
                {rejected === true && (
                  <span class="mx-2 badge bg-danger">Rejected </span>
                )}
                {human_verified === false && rejected === false && (
                  <span class="mx-2 badge bg-warning">Pending </span>
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Type
              </th>
              <td>
                {" "}
                <select
                  id="invoiceType"
                  className="form-select"
                  onChange={(e) => {
                    // updateParams({ invoice_type: e.target.value, page: ;
                    setCachedData("invoice_type", e.target.value);
                  }}
                  value={invoice_type}
                >
                  {invoiceTypeFilters?.slice(1, 4)?.map((option) => (
                    <option key={option?.key} value={option?.value}>
                      {option?.key}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Vendor Name
              </th>
              <td
                style={{
                  cursor: "pointer",
                  color: "blue",
                  display: "flex",
                  gap: 10
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {editVendor ? (
                  <FormControl
                    style={{ position: "sticky", top: -7 }}
                    placeholder="New Vendor Name"
                    value={newVendor}
                    onFocus={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setNewVendor(e.target.value);
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <Dropdown
                    autoClose
                    className="w-100"
                    style={{ width: "100%", display: "flex", gap: 5 }}
                  >
                    <Dropdown.Toggle
                      variant="outline"
                      className="border w-100 bg-white h-100"
                      style={{
                        lineHeight: "2.0",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        justifyContent: "center"
                      }}
                    >
                      {!editVendor ? (
                        <>
                          {vendor?.vendor_name ? (
                            <Link
                              target="_blank"
                              to={`/vendor-consolidation-v2/${vendor?.vendor_id}`}
                            >
                              {vendor?.vendor_name}{" "}
                              <span style={{ marginLeft: 10 }}>
                                {vendor?.human_verified && (
                                  <PatchCheckFill fill="blue" />
                                )}
                              </span>
                            </Link>
                          ) : (
                            "Select a Vendor"
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        maxWidth: "500px",
                        minHeight: "100px",
                        maxHeight: "300px",
                        minWidth: "300px",
                        overflowY: "scroll",
                        overflowX: "auto"
                      }}
                    >
                      <FormControl
                        style={{ position: "sticky", top: -7 }}
                        placeholder="Search Vendor Name"
                        value={vendorNameSearch}
                        onChange={(e) => setVendorNameSearch(e.target.value)}
                      />

                      <div className="d-flex justify-content-center align-content-center w-100 p-2">
                        {!editVendor && (
                          <Button
                            size="sm"
                            className="px-2 h-50"
                            variant="warning"
                            onClick={() => setEditVendor(true)}
                          >
                            Add New Vendor
                          </Button>
                        )}
                      </div>

                      {vendorsData?.data?.vendor_names
                        ?.filter((it) =>
                          it?.vendor_name
                            ?.toLowerCase()
                            ?.includes(vendorNameSearch?.toLowerCase())
                        )
                        ?.map(({ vendor_id, vendor_name, human_verified }) => {
                          return (
                            <Dropdown.Item
                              key={vendor_id}
                              onClick={() => {
                                setVendorChanged(true);
                                setCachedData(
                                  "vendor",
                                  {
                                    vendor_name,
                                    vendor_id,
                                    human_verified
                                  },
                                  false
                                );
                              }}
                            >
                              {vendor_name}{" "}
                              {human_verified && <PatchCheckFill fill="blue" />}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {(vendorChanged || editVendor) && (
                  <Button
                    size="sm"
                    className="px-5"
                    disabled={loadingState.savingVendor}
                    variant="warning"
                    onClick={() => {
                      if (newVendor?.length > 0) {
                        updateVendor({ vendor_name: newVendor });
                      } else {
                        updateVendor(vendor);
                      }
                    }}
                  >
                    {loadingState.savingVendor ? "Updating.." : "Update"}
                  </Button>
                )}
                {editVendor && (
                  <Button
                    size="sm"
                    className="px-5"
                    disabled={loadingState.savingVendor}
                    variant="warning"
                    onClick={() => {
                      setEditVendor(false);
                      setNewVendor("");
                    }}
                  >
                    Close
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Vendor Address
              </th>
              <td className="d-flex gap-5 align-items-center">
                {editBranch ? (
                  <FormControl
                    value={newBranch}
                    placeholder="New Branch Name"
                    onChange={(e) => {
                      setNewBranch(e.target.value);
                    }}
                  />
                ) : (
                  <Dropdown
                    autoClose
                    className="w-100"
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: 5,
                      justifyContent: "center"
                    }}
                  >
                    {!editBranch && (
                      <Dropdown.Toggle
                        variant="outline"
                        className="border w-100 bg-white h-100"
                        style={{
                          lineHeight: "2.0",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          justifyContent: "center",
                          flexWrap: "wrap",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                          whiteSpace: "normal", // allows text to wrap
                          maxWidth: "350px",
                          flexShrink: 1 // lets the container shrink as needed
                        }}
                      >
                        <Link
                          target="_blank"
                          to={`/vendor-consolidation-v2/branches/${vendor?.vendor_id}`}
                        >
                          {branch?.vendor_address}
                          <span style={{ marginLeft: 10 }}>
                            {branch?.human_verified && (
                              <PatchCheckFill fill="blue" />
                            )}
                          </span>
                        </Link>
                      </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu
                      style={{
                        maxWidth: "500px",
                        minHeight: "100px",
                        maxHeight: "300px",
                        minWidth: "300px",
                        overflowY: "scroll",
                        overflowX: "auto"
                      }}
                    >
                      <FormControl
                        value={vendorAddressSearch}
                        placeholder="Search vendor address"
                        onChange={(e) => setVendorAddressSearch(e.target.value)}
                      />

                      <div className="d-flex justify-content-center align-content-center w-100 p-2">
                        {!editBranch && (
                          <Button
                            size="sm"
                            className="px-2 h-50"
                            variant="warning"
                            onClick={() => setEditBranch(true)}
                          >
                            Add New Branch
                          </Button>
                        )}
                      </div>
                      {vendorAddress?.data?.branches
                        ?.filter((b) =>
                          b.vendor_address
                            ?.toLowerCase()
                            ?.includes(vendorAddressSearch?.toLowerCase())
                        )
                        ?.map((branch, i) => {
                          return (
                            <Dropdown.Item
                              key={i}
                              style={{
                                flexWrap: "wrap",
                                wordBreak: "normal",
                                wordWrap: "break-word"
                              }}
                              onClick={() => {
                                setCachedData(
                                  "branch",
                                  {
                                    vendor_address: branch?.vendor_address,
                                    branch_id: branch?.branch_id,
                                    human_verified: branch?.human_verified
                                  },
                                  false
                                );
                                setBranchChanged(true);
                              }}
                            >
                              {branch?.vendor_address}{" "}
                              {branch?.human_verified && (
                                <PatchCheckFill fill="blue" />
                              )}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {(editBranch || branchChanged) && (
                  <Button
                    size="sm"
                    className="px-5 h-50"
                    variant="warning"
                    onClick={() => {
                      if (newBranch?.length > 0) {
                        updateBranch({ vendor_address: newBranch });
                      } else {
                        updateBranch(branch);
                      }
                    }}
                  >
                    {loadingState.savingBranch ? "Updating.." : "Update"}
                  </Button>
                )}
                {editBranch && (
                  <Button
                    size="sm"
                    className="px-5"
                    disabled={loadingState.savingVendor}
                    variant="warning"
                    onClick={() => {
                      setEditBranch(false);
                      setNewBranch("");
                    }}
                  >
                    Close
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Date
              </th>
              <td>
                <DatePicker
                  format="MM-dd-yyyy"
                  value={
                    document_metadata?.invoice_date &&
                    isValid(parseISO(document_metadata.invoice_date))
                      ? parseISO(document_metadata.invoice_date) // Parse ISO string
                      : null
                  }
                  locale="en-US"
                  onChange={(date) => {
                    if (date && isValid(date)) {
                      // Store date as ISO string in UTC format
                      setCachedData(
                        "invoice_date",
                        formatISO(date, { representation: "date" })
                      );
                    } else {
                      setCachedData("invoice_date", null);
                    }
                  }}
                />
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Due Date
              </th>
              <td>
                <DatePicker
                  format="MM-dd-yyyy"
                  value={
                    document_metadata?.invoice_due_date &&
                    isValid(parseISO(document_metadata.invoice_due_date))
                      ? parseISO(document_metadata.invoice_due_date)
                      : null
                  }
                  locale="en-US"
                  onChange={(date) => {
                    if (date && isValid(date)) {
                      setCachedData(
                        "invoice_due_date",
                        formatISO(date, { representation: "date" })
                      );
                    } else {
                      setCachedData("invoice_due_date", null);
                    }
                  }}
                />
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Quick Books Document Type
              </th>
              <td>
                {" "}
                <Dropdown
                  autoClose={"outside"}
                  onSelect={(v) => {}}
                  width={"100%"}
                >
                  <Dropdown.Toggle
                    variant="outline"
                    className="border w-100 bg-white h-100"
                    style={{
                      lineHeight: "2.0",
                      fontSize: "14px",
                      textTransform: "capitalize"
                    }}
                  >
                    {quick_book_document_type || "Select a Document Type"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    <>
                      {additionaldata?.data?.vendor_invoice_document_types?.map(
                        (c) => (
                          <Dropdown.Item
                            key={c}
                            eventKey={c}
                            onClick={() =>
                              setCachedData("quick_book_document_type", c)
                            }
                          >
                            {c}
                          </Dropdown.Item>
                        )
                      )}
                    </>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Ship To
              </th>
              <td>
                <FormControl
                  value={document_metadata?.invoice_ship_to}
                  onChange={(e) =>
                    setCachedData("invoice_ship_to", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Bill To
              </th>
              <td>
                <FormControl
                  value={document_metadata?.invoice_bill_to}
                  onChange={(e) =>
                    setCachedData("invoice_bill_to", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Sold To
              </th>
              <td>
                <FormControl
                  value={document_metadata?.invoice_sold_to}
                  onChange={(e) =>
                    setCachedData("invoice_sold_to", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Credit Card Name
              </th>
              <td>
                <FormControl
                  value={document_metadata?.credit_card_name}
                  onChange={(e) =>
                    setCachedData("credit_card_name", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Credit Card Number
              </th>
              <td>
                <FormControl
                  value={document_metadata?.credit_card_number}
                  onChange={(e) =>
                    setCachedData("credit_card_number", e.target.value)
                  }
                />
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Total
              </th>
              <td>{document_metadata?.invoice_extracted_total ?? "NA"}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Balance Due
              </th>
              <td>{document_metadata?.invoice_balance_due ?? "NA"}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Restaurant Name
              </th>
              <td>
                {restaurant?.restaurant_name || restaurant?.restaurant_id ? (
                  <Link
                    to={`https://crunch-client.vercel.app/restaurants/${restaurant?.restaurant_id}/vendors/vendor-details/${vendor?.vendor_id}`}
                    target="_blank"
                  >
                    {restaurant?.restaurant_name || restaurant?.restaurant_id}
                  </Link>
                ) : (
                  "NA"
                )}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Vendor Phone Number
              </th>
              <td>{document_metadata?.vendor_phone_number}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Document Type Prediction
              </th>
              <td>
                {" "}
                <Dropdown
                  autoClose={"outside"}
                  onSelect={(v) => {}}
                  width={"100%"}
                >
                  <Dropdown.Toggle
                    variant="outline"
                    className="border w-100 bg-white h-100"
                    style={{
                      lineHeight: "2.0",
                      fontSize: "14px",
                      textTransform: "capitalize"
                    }}
                  >
                    {document_type || "Select a Document Type"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    <>
                      {additionaldata?.data?.document_types?.map((c) => {
                        return (
                          <Dropdown.Item
                            key={c}
                            eventKey={c}
                            onClick={() => setCachedData("document_type", c)}
                            style={{ textTransform: "capitalize" }}
                          >
                            {c}
                          </Dropdown.Item>
                        );
                      })}
                    </>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Verdict
              </th>
              <td>{}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Concerns
              </th>
              <td>
                <ul></ul>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Human Verification Required
              </th>
              <td>
                {human_verified == null ? (
                  <p>NA</p>
                ) : (
                  <p>{human_verification_required ? "Yes" : "No"}</p>
                )}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Taxes Added
              </th>
              <td>
                {document_metadata?.added_taxes?.lenth > 0 ? (
                  <ul>
                    {document_metadata?.added_taxes?.map((item, i) => {
                      <li key={i} style={{ textTransform: "capitalize" }}>
                        {item}
                      </li>;
                    })}
                  </ul>
                ) : (
                  "NA"
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Fees Added
              </th>
              <td>
                {" "}
                {document_metadata?.added_fees?.lenth > 0 ? (
                  <ul>
                    {document_metadata?.added_fees?.map((item, i) => {
                      <li key={i} style={{ textTransform: "capitalize" }}>
                        {item}
                      </li>;
                    })}
                  </ul>
                ) : (
                  "NA"
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Discounts Added
              </th>
              <td>
                {" "}
                {document_metadata?.added_discounts?.lenth > 0 ? (
                  <ul>
                    {document_metadata?.added_discounts?.map((item, i) => {
                      <li key={i} style={{ textTransform: "capitalize" }}>
                        {item}
                      </li>;
                    })}
                  </ul>
                ) : (
                  "NA"
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Payment Terms
              </th>
              <td>{document_metadata?.invoice_payment_terms}</td>
            </tr>

            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Total No. of Pages Received
              </th>
              <td>{document_metadata?.total_number_of_pages_received}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice Total From Table
              </th>
              <td>{document_metadata?.total_from_table || "NA"}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Invoice UUID
              </th>
              <td>{document_uuid}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Version
              </th>
              <td>{version}</td>
            </tr>
            <tr>
              <th
                style={{
                  width: "200px",
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize"
                }}
              >
                Rerun Status
              </th>
              <td>
                {rerun_status === null ? (
                  <p>NA</p>
                ) : (
                  <p>{rerun_status ? "Rerun" : "Not Rerun"}</p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MetadataTable;
