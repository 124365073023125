import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "lib/axios";
export const useGetInvoiceAssignment = (payload) => {
  return useQuery({
    queryKey: ["assignedInvoices", payload],
    queryFn: async () => {
      const {
        page,
        page_size,
        over_due,
        start_date,
        end_date,
        restaurant,
        vendor,
        human_verified,
        assigned_to,
        assigned_at_order
      } = payload;

      // Filter only the params with values
      const params = {
        page,
        page_size,
        over_due,
        start_date,
        end_date,
        restaurant,
        vendor,
        human_verified,
        assigned_to,
        assigned_at_order
      };

      const queryString = Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const apiUrl = `/api/invoice-balancer-scheduler/assignments/?${queryString}`;
      const response = await instance.get(apiUrl);

      return response?.data;
    }
  });
};

export const useGetSchedularConfiguration = (schedular_id) => {
  return useQuery({
    queryKey: ["schedular-configuration", schedular_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/invoice-balancer-scheduler/${schedular_id}/scheduler/`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useUpdateSchedularConfiguration = () => {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await instance.put(
        `/api/invoice-balancer-scheduler/${payload?.scheduler_id}/scheduler/`,
        {
          ...payload
        }
      );
      return response?.data;
    }
  });
};

export const useGetSchedularListing = () => {
  return useQuery({
    queryKey: ["schedulars"],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/invoice-balancer-scheduler/scheduler/list/`
        );
        return response?.data?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useCreateSchedular = () => {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await instance.post(
        "/api/invoice-balancer-scheduler/scheduler/",
        {
          ...payload
        }
      );
      return response?.data;
    }
  });
};

export const useGetBalancerAssignments = (payload) => {
  return useQuery({
    queryKey: ["balancer-assignments", payload],
    queryFn: async () => {
      try {
        let response = await instance.get(
          `/api/invoice-balancer-scheduler/balancer-assignments/?${payload?.page}&page_size=${payload?.page_size}`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useReassignBalancerOrSchedular = () => {
  return useMutation({
    mutationFn: async ({ assign_to, balancer_uuid, schedular_id,balancer_id }) => {
      let response = await instance.post(
        `/api/invoice-balancer-scheduler/${balancer_uuid}/reassign-balancer-invoice/`,
        {
          assign_to,
          balancer_uuid:balancer_id,
          scheduler_id:schedular_id
        }
      );
      return response?.data;
    }
  });
};
