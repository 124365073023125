import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";
import { Link } from "react-router-dom";
import useAppStore from "store/global";
import { useInvoicePaginationActions } from "store/invoicePagination";
import { ArrowLeft, HouseFill } from "react-bootstrap-icons";
import React, { useState, useEffect } from "react";
import { useVerificationParams } from "store/global";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { ButtonGroup, CloseButton, Dropdown, Spinner } from "react-bootstrap";
import { useGlobalFilterParams } from "store/globalFIlter";
import { FilterDropdown } from "./FilterDropdown";
import { VendorDropdown } from "./VendorDropdown";
import { RestaurantDropdown } from "./RestaurantDropdown";
import useNavigationHistory from "hooks/useNavigationHistory";
import useFilterStore from "store/persistentFilterStore";
const useSearchQuery = () => {
  const filters = useGlobalFilterParams();
  const invoice_name = useAppStore((state) => state.searchInput);
  const payload = {
    invoice_number_search: invoice_name,
    filters
  };
  return useQuery({
    enabled: Boolean(invoice_name),
    queryKey: ["invoice_search", invoice_name, ...Object.values(filters)],
    queryFn: () => axiosInstance.post("/invoice_search", payload)
  });
};

export const NavHeader = ({ view, children }) => {
  const { setPageNumber, setTempValue } = useInvoicePaginationActions();


  const {
    searchInput,
    setSearchInput,
    searchResultVisible,
    setSearchResutsVisible,
    getVerified,
    showVertical,
    setShowVertical
  } = useAppStore();
  const { page } = useParams();
  const [searchParams,setSearchParams] = useSearchParams();
  const urlFilters = searchParams.get("filters") ?? "{}";
  const navigate = useNavigate();
  const { data, isPending, isError, error } = useSearchQuery();
  const invoiceNumArray = data?.data?.matching_invoices;

  const [search, setSearch] = useState(searchInput ?? "");

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
  };
  const { pathname,search:queryParams } = useLocation();
  
  const { filters } = useFilterStore();
  const appendFiltersToUrl = () => {
    const newParams = new URLSearchParams(searchParams);

    // Loop through each filter and append to the search params
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      }
    });

    // Update searchParams with new filters
    setSearchParams(newParams);
  };
  useEffect(() => {
if(pathname?.includes("invoice-details")||pathname?.includes("home")){
  appendFiltersToUrl();
  
}
  }, []);

  return (
    <nav
      className="navbar -3 shadow-sm"
      style={{
        backgroundColor: "#FDFFD0",
        position: "fixed",
        top: "0",
        bottom: "100",
        zIndex: "100",
        width: "100%"
      }}
    >
      <div
        // className="row"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // To vertically center items
          flexDirection: "row",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 40,
          paddingRight: 40
        }}
      >
        <div
          className="navbar-brand mb-0 h1 float-start"
          style={{ fontSize: "1.4em", letterSpacing: "1px" }}
        >
          <HouseFill
            className="mx-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <ArrowLeft
            className="mx-2 cursor-pointer"
            onClick={() => {
            if(view=="home"){
              navigate('/')
            }

            else if(pathname?.includes("invoice-details") && queryParams?.includes("vendor")){
              navigate("/vendor-consolidation-v2")
             
             }
            else if(pathname?.includes("invoice-details") && !queryParams?.includes("vendor")){
             navigate("/home")
            
            }
            
            else if(pathname?.includes("combine-vendors")){
             let id=pathname.split("/")[3];
             navigate(`/vendor-consolidation-v2/${id}`)
            }
            else if (view=="Vendor Details"){
              navigate("/vendor-consolidation-v2")
            }
            else if (view=="Vendor Items"){
              let id=pathname.split("/")[3];
              navigate(`/vendor-consolidation-v2/${id}`)
              
            }
            
            
            else{
              navigate(-1)

            }
              
            }}
          />

          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            Invoice Intelligence Platform
          </Link>
        </div>

        {view !== "Registration" && <div className="col-md-4">{children}</div>}

        <div
          className=" "
          style={{ display: "flex", gap: "16px", alignItems: "center" }}
        >
          {view === "details" ? (
            <>
              <div className="form-check">
                <input
                  className="form-check-input mx-2"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value={getVerified}
                  onChange={() => setShowVertical(!showVertical)}
                  style={{ float: "none" }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Change Layout
                </label>
              </div>
              {page !== "pdf" && (
                <Link
                  to="/details/pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (page === "table") {
                      localStorage.setItem(
                        "filters",
                        localStorage.getItem("filters")
                      );
                    } else {
                      localStorage.setItem("filters", JSON.stringify(filters));
                    }
                  }}
                >
                  PDF
                </Link>
              )}
              {page !== "table" && (
                <Link
                  to="/details/table"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (page === "pdf") {
                      localStorage.setItem(
                        "filters",
                        localStorage.getItem("filters")
                      );
                    } else {
                      localStorage.setItem("filters", JSON.stringify(filters));
                    }
                  }}
                >
                  Table
                </Link>
              )}
            </>
          ) : view === "home" ? (
            <>
              <Link to="/statistics">Statistics</Link>
            </>
          ) : view === "engineeringIssuuues" ? (
            <>
              <Link to="/rerun-invoices">Re-Run Invoices</Link>
            </>
          ) : null}

          <img
            src={`${process.env.PUBLIC_URL}/logout.png`}
            alt="Registration"
            className="img-fluid "
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/login");
            }}
            style={{ height: "20px", marginLeft: "20px", cursor: "pointer" }}
          />
        </div>
      </div>
    </nav>
  );
};

const SearchToggle = React.forwardRef(
  (
    {
      children,
      style,
      className,
      disabled,
      "aria-labelledby": labeledBy,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
          {...props}
        >
          <button
            disabled={disabled}
            className="input-group-text h-100"
            id="basic-addon1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
            </svg>
          </button>
        </div>
      </>
    );
  }
);
