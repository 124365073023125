import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFetchDocumentComments, useAddClickBaconDocumentNote } from "./api";
import { SendPlusFill } from "react-bootstrap-icons";
import useAppStore from "store/global";
import DOMPurify from "dompurify";
import { useSearchParams } from "react-router-dom";

const ChatMessage = ({ message }) => {
  const createMarkup = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html).replace(/\n/g, "<br>");
    return { __html: sanitizedHtml };
  };

  const userEmail = localStorage.getItem("user_email");
  const isOwnMessage = message.fromEmail === userEmail;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "10px",
        justifyContent: isOwnMessage ? "flex-end" : "flex-start"
      }}
    >
      {!isOwnMessage && (
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "green",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold"
          }}
        >
          cB
        </div>
      )}
      <div
        style={{
          backgroundColor: isOwnMessage ? "#d4edda" : "#f8f9fa",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: isOwnMessage ? "10px" : "10px",
          marginRight: isOwnMessage ? "10px" : "0px",
          maxWidth: "80%",
          position: "relative",
          marginBottom: "10px",
          alignSelf: isOwnMessage ? "flex-end" : "flex-start"
        }}
      >
        <div dangerouslySetInnerHTML={createMarkup(message.notes)} />
        <div
          style={{
            float: "right",
            bottom: "5px",
            right: "10px",
            color: "gray",
            fontSize: "12px"
          }}
        >
          {new Date(message.createdAt).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

export default function Comments({ name, show, setShow, invoiceUUID }) {
  const [searchParams]=useSearchParams()
  let page=searchParams.get("page_number")
  const [pageNumber, setPageNumber] = useState(1);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [note, setNote] = useState("");
  const handleClose = () => setShow(false);
  const { mutate, isPending } = useAddClickBaconDocumentNote();
  const { data, isLoading, isError } = useFetchDocumentComments(
    invoiceUUID,
    pageNumber,
    show
  );

  useEffect(()=>{
    setComments([])
  },[page])
  useEffect(() => {
    if (data&& show) {
      if (Object.keys(data?.data)?.length > 0 ) {
        setComments((prevComments) => {
          // Avoid duplicating notes by checking for unique IDs
          const existingIds = new Set(
            prevComments?.map((comment) => comment.id)
          );
          const newComments = data?.data?.filter(
            (comment) => !existingIds?.has(comment.id)
          );
          return [...prevComments, ...newComments];
        });
        if (pageNumber >= data.total_pages) {
          setHasMore(false);
        }
      }
    }
  }, [data, show]);

  const fetchMoreData = () => {
    if (!isLoading && hasMore) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handleSend = () => {
    if (note.trim() !== "") {
      mutate(
        { document_uuid: invoiceUUID, note: note },
        {
          onSuccess: () => {
            setNote("");
            setPageNumber(1);
          
          }
        }
      );
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            height: "calc(100% - 60px)"
          }}
        >
          {isError && <div>Error</div>}
          <div
            id="scrollableDiv"
            style={{
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse"
            }}
          >
            <InfiniteScroll
              dataLength={comments?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={comments?.length>0?<div>Loading...</div>:<p></p>}
              endMessage={<div></div>}
              scrollableTarget="scrollableDiv"
              inverse={true}
            >
              {comments
                ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                ?.map((comment) => (
                  <ChatMessage key={comment.id} message={comment} />
                ))}
            </InfiniteScroll>
          </div>
        </Offcanvas.Body>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            borderTop: "1px solid #dee2e6",
            backgroundColor: "#f8f9fa"
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Type a message..."
            style={{ flex: 1, marginRight: "10px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <button
            className="btn btn-success"
            disabled={isPending || note.trim() === ""}
            onClick={handleSend}
          >
            <SendPlusFill fill="white" />
          </button>
        </div>
      </Offcanvas>
    </>
  );
}
