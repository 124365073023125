import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import { useState } from "react";
import { ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useAppStore from "store/global";
export const InvoiceHeader = ({ children, styles }) => {
  const updateSearchParams = useUpdateSearchParams();
  const [show, setShow] = useState(false);

  const [showToolTip, setShowToolTip] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  // const { setVerified } = useGlobalFilterActions();
  const [deduplicationLoading, setDeduplicationLoading] = useState(false);
  const { vendorName, invoiceUUID, pdfSource, vendorId } = useAppStore();
  const location = useLocation();

  const tooltip = <Tooltip id="tooltip">View Document Notes</Tooltip>;
  const tooltip2 = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  return (
    <div
      className="mx-1 sticky-top  bg-white"
      
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        marginTop: "100px",
        top:"50px",
      }}
    >
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          borderRadius: "5px",
          width: "100%",
          textAlign: "left",
          justifyContent: "space-between",
          ...styles
        }}
        className="mx-4 d-flex "
      >
        {children}
        <ButtonGroup aria-label="Invoice Buttons" className="ml-auto">
          {location.pathname === "/details" ||
          location.pathname.startsWith("/invoice-details") ||
          location.pathname.startsWith("/details/both") ? (
            <OverlayTrigger placement="left" overlay={tooltip2}>
              <></>
            </OverlayTrigger>
          ) : null}

      
          {location.pathname === "/details" ||
          location.pathname.startsWith("/invoice-details") ||
          location.pathname.startsWith("/details/both") ? (
            <></>
          ) : null}
          {location.pathname === "/details" ||
          location.pathname.startsWith("/invoice") ||
          location.pathname.startsWith("/details/both") ? (
          <></>
          ) : null}
        </ButtonGroup>
      </div>
      <ToastContainer />
      {/* <Comments show={show} setShow={setShow} name="Comments" data={[]} /> */}
    </div>
  );
};
