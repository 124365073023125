import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export const verificationFilterOptions = [
  "All",
  "Human Verification Required",
  "Human Verification Not Required"
];
export const verificationFilterMap = {
  All: "both",
  "Human Verification Required": "true",
  "Human Verification Not Required": "false"
};
export const detectionFilterOptions = ["All", "Detected", "Undetected"];
export const detectionFilterMap = {
  All: "all",
  Detected: "detected",
  Undetected: "undetected"
};
export const rerunFilterOptions = ["All", "Rerun", "Not Rerun"];
export const rerunFilterMap = {
  All: "both",
  Rerun: "true",
  "Not Rerun": "false"
};

export const invoiceFilterOptions = [
  "All",
  "Normal Invoice",
  "Liquor Invoice",
  "Summary Invoice"
];
export const invoiceFilterMap = {
  All: "all",
  "Normal Invoice": "normal_invoice",
  "Liquor Invoice": "liquor_invoice",
  "Summary Invoice": "summary_invoice"
};

export const autoAcceptedFilterOptions = ["true", "false", "both"];

export const autoAcceptedFilterMap = {
  true: "true",
  false: "false",
  both: "both"
};

const useGlobalFilterStore = createWithEqualityFn(
  (set) => ({
  
  }),
  Object.is
);

// This hook will not trigger re-render even if any of the state change
export const useGlobalFilterActions = () =>
  useGlobalFilterStore((state) => state.actions);

export const useGlobalFilterParams = () =>
  useGlobalFilterStore(
    (state) => ({
     
    }),
    shallow
  );

export default useGlobalFilterStore;
