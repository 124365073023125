import { Spinner } from "components/common/Spinner";
import CustomDropdown from "components/CustomDropdown";
import { NavHeader } from "components/layout/NavHeader";
import useUpdateParams from "hooks/useUpdateParams";
import { useEffect, useState } from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  Search
} from "react-bootstrap-icons";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetAllUsers, useGetVendorItemMaster } from "./api";
import Header from "./components/Header";
import VendorItemMastertable from "./components/VendorItemMaster";
export default function VendorItemMaster() {
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  const { vendorId: vendor_id } = useParams();
  const verified_by = searchParams.get("verified_by") || "";
  const item_code = searchParams.get("item_code") || "";
  const item_description = searchParams.get("item_description") || "";
  const human_verified = searchParams.get("human_verified") || "";
  const search_by = searchParams.get("search_by") || "";
  const page = searchParams.get("page") || 1;
  const document_uuid = searchParams.get("document_uuid") || "";
  const category_review_required =
    searchParams.get("category_review_required") || "";
  let sort_by_item_code = searchParams.get("sort_by_item_code") || null;
  let sort_by_item_description =
    searchParams.get("sort_by_item_description") || null;
  const [pageIndex, setPageIndex] = useState(page);
  const [searchTerm, setSearchTerm] = useState(
    item_code || item_description || ""
  );
  const payload = {
    vendor_id,
    human_verified,
    category_review_required,
    verified_by,
    item_code,
    item_description,
    page,
    document_uuid,
    page_size: 10,
    sort_by_item_code,
    sort_by_item_description
  };

  const { data, isLoading } = useGetVendorItemMaster(payload);

  const { data: usersData, isLoading: loadingUsers } = useGetAllUsers();
  useEffect(() => {
  
    const handleKeyDown = (e) => {
      const tagName = document.activeElement.tagName.toLowerCase();
      const isEditable =
        document.activeElement.isContentEditable ||
        tagName === "input" ||
        tagName === "textarea" ||
        tagName === "select";
      if (!isEditable) {
        if (e.key === "ArrowLeft" && pageIndex > 1) {
          // Go to the previous page
          updateParams({ page: pageIndex - 1 });
          setPageIndex(pageIndex - 1);
        } else if (e.key === "ArrowRight" && pageIndex < data?.total_pages) {
          // Go to the next page
          updateParams({ page: Number(pageIndex) + 1 });
          setPageIndex(pageIndex + 1);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pageIndex, data?.total_pages, updateParams]);

  useEffect(() => {
    setPageIndex(page);
  }, [page]);
  return (
    <>
      <NavHeader view="Vendor Items" />
      <Header
        text={"Vendor Items"}
        children={
          <div className="d-flex justify-content-end">
            <span className="mr-3">Total: {data?.data?.total_item_count}</span>
            <ProgressBar
              now={data?.data?.verified_item_count}
              max={data?.data?.total_item_count}
              label={`${data?.data?.verified_item_count}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px"
              }}
            />
          </div>
        }
      />
      {/* {loading && ( */}
      {isLoading && document_uuid == "" && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div className="mx-5 my-4">
          <div className="d-flex justify-content-end my-2">
            <div className="d-flex justify-content-end mx-4">
              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {human_verified == ""
                    ? "Human Verified"
                    : human_verified
                    ? "Verified"
                    : "Not Verified"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({ human_verified: true, page: 1 });
                    }}
                  >
                    Verified
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({ human_verified: false, page: 1 });
                    }}
                  >
                    Not Verified
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({ human_verified: "", page: 1 });
                    }}
                  >
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {!loadingUsers && !isLoading ? (
                <CustomDropdown
                  data={[...usersData?.data, { id: null, username: "None" }]}
                  Objectkey={"id"}
                  displayKey={"username"}
                  className="w-auto"
                  maxWidth="180px"
                  minWidth="180px"
                  value={"Verified By"}
                  onSelect={(v) => {
                    if (v == null) {
                      updateParams({ verified_by: undefined, page: 1 });
                    } else {
                      updateParams({ verified_by: v, page: 1 });
                    }
                  }}
                />
              ) : null}

              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {category_review_required == ""
                    ? "Category Review"
                    : category_review_required == "true"
                    ? "Yes"
                    : "No"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({
                        category_review_required: "true",
                        page: 1
                      });
                    }}
                  >
                    Yes
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({
                        category_review_required: "false",
                        page: 1
                      });
                    }}
                  >
                    No
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      updateParams({ category_review_required: "", page: 1 });
                    }}
                  >
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{
                  borderRadius: "0"
                }}
                onChange={(e) => {
                  if (e.target.value == "") {
                    updateParams({
                      search_by: undefined,
                      item_code: undefined,
                      item_description: undefined,
                      page: 1
                    });
                    setSearchTerm("");
                  } else {
                    setSearchTerm(e.target.value);
                  }
                }}
                value={searchTerm}
              />
            </div>
            <div className="col-md-2">
              <select
                className="form-control"
                style={{
                  borderRadius: "0"
                }}
                onChange={(e) => {
                  if (e.target.value === "clear") {
                    setSearchTerm("");
                    updateParams({ search_by: undefined });
                  } else {
                    updateParams({ search_by: e.target.value });
                  }
                }}
                value={search_by}
              >
                <option>Search By</option>
                <option value={"item_code"}>Item Code</option>
                <option value={"item_description"}>Item Description</option>
                <option value={"clear"}>Clear</option>
              </select>
            </div>
            {/* <div className="col-md-2"> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                if (search_by == "item_description") {
                  updateParams({ item_description: searchTerm, page: 1 });
                } else if (search_by == "item_code") {
                  updateParams({ item_code: searchTerm, page: 1 });
                }
              }}
              style={{
                borderRadius: "0"
              }}
            >
              <Search />
            </button>
            {/* </div> */}
          </div>
          <VendorItemMastertable data={data} payload={payload} />
          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={pageIndex == 1 || data?.total_pages == 1}
                onClick={() => {
                  if (pageIndex > 1) {
                    setPageIndex((prev) => prev - 1);
                    updateParams({ page: Number(page) - 1 });
                  }
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={pageIndex}
                  onChange={(e) => {
                    setPageIndex(Number(e.target.value));
                  }}
                  onBlur={() => {
                    if (pageIndex <= data?.total_pages && pageIndex !== 0) {
                      updateParams({ page: pageIndex });
                    } else {
                      setPageIndex(data?.total_pages);
                      updateParams({ page: data?.total_pages });
                    }
                  }}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${data?.total_pages || 1}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={data?.total_pages == 1 || data?.is_final_page}
                onClick={() => {
                  if (pageIndex < data?.total_pages) {
                    setPageIndex((prev) => prev + 1);
                    updateParams({ page: Number(page) + 1 });
                  }
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
