import { useSearchParams } from "react-router-dom";

const useUpdateParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParams = (params) => {
    // Convert current params to an object and make a copy
    const currentParams = Object.fromEntries(searchParams.entries());

    // Iterate and update the current params
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete currentParams[key]; // Remove if undefined
      } else {
        currentParams[key] = String(params[key]); // Ensure values are strings
      }
    });

    setSearchParams(currentParams);
  };

  return updateParams;
};

export default useUpdateParams;
