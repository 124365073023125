import Comments from "components/Comments/Comments";
import Notes from "components/Comments/Notes";
import CategoryWiseSumTable from "components/Tables/CategoryWiseSumTable";
import { Spinner } from "components/common/Spinner";
import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { InvoicePagination } from "components/layout/InvoicePagination";
import { NavHeader } from "components/layout/NavHeader";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  CloseButton,
  Col,
  Dropdown,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import {
  ChatDotsFill,
  JournalCheck,
  PatchCheckFill,
  Search,
  ShareFill
} from "react-bootstrap-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFindDuplicateInvoices } from "./api";
import { PdfViewer } from "./components/PdfViewer";
import Tables from "./components/Tables";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
import { usePrefetchPdfLinks } from "components/api/api";
import { FilterDropdown } from "components/layout/FilterDropdown";
import { RestaurantDropdown } from "components/layout/RestaurantDropdown";
import { VendorDropDown } from "modules/statistics/components/VendorDropDown";
import { VendorDropdown } from "components/layout/VendorDropdown";
import useFilterStore from "store/persistentFilterStore";
import { useSearchInvoice } from "components/api/api";
import useUpdateParams from "hooks/useUpdateParams";
import { useGetAllUsers } from "modules/vendorConsolidation/api";
import CustomDropdown from "components/CustomDropdown";
import { useGetRestaurantList } from "components/api/api";
import { useGetVendorsList } from "components/api/api";
const Tab = ({ value, activeValue, onClick, ...props }) => (
  <button
    className="border p-2"
    style={{
      flex: "1 1 0%",
      minWidth: "120px",
      backgroundColor: activeValue === value ? "#FDFFD0" : "white",
      textTransform: "capitalize",
      cursor: "pointer"
    }}
    onClick={() => onClick(value)}
    {...props}
  >
    {value}
  </button>
);
const InvoiceDetails = () => {
  const [show, setShow] = useState(true);
  const [showComments, setShowComments] = useState(false);

  const { data: userData, isLoading: userDataLoading } = useGetAllUsers();
  let count = 0;
  const [searchParams, setSearchParams] = useSearchParams();
  let page = searchParams.get("page_number") || 1;
  let filterPage = searchParams.get("page") || 1;

  let document_uuid = searchParams.get("document_uuid") || "";
  let layout = searchParams.get("layout") || null;
  let page_size = searchParams.get("page_size") || 8;
  let invoice_type = searchParams.get("invoice_type") || "";
  let human_verification = searchParams.get("human_verification") || "";
  let human_verified = searchParams.get("human_verified") || "";
  let detected = searchParams.get("detected") || "";
  let rerun_status = searchParams.get("rerun_status") || "";
  let auto_accepted = searchParams.get("auto_accepted") || "";
  let start_date = searchParams.get("start_date") || "";
  let end_date = searchParams.get("end_date") || "";
  let clickbacon_status = searchParams.get("clickbacon_status") || "";
  let restaurant = searchParams.get("restaurant_id") || "";
  let vendor = searchParams.get("vendor_id") || "";
  let sort_order = searchParams.get("sort_order") || "desc";
  let invoice_number = searchParams.get("invoice_number") || "";
  let assigned_to = searchParams.get("assigned_to");
  const [modalShow, setModalShow] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [activeTab, setActiveTab] = useState("Metadata");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { prefetchedLinks, setPrefetchedLinks, setTableData } =
    invoiceDetailStore();

  const tooltip = <Tooltip id="tooltip">View Document Notes</Tooltip>;
  const tooltip2 = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  const [payload, setPayload] = useState({});
  const [showDuplicatesWarning, setShowDuplicatesWarning] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const { data: duplicates, isLoading: findingDuplicates } =
    useFindDuplicateInvoices(document_uuid || data?.data?.[0]?.document_uuid);

  useEffect(() => {
    if (duplicates?.error) {
      setShowDuplicatesWarning(true);
    } else {
      setShowDuplicatesWarning(false);
    }
  }, [duplicates]);
  let timer;
  useEffect(() => {
    if (data) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShowTable(false);
      }, 1000);
    }
  }, [data, isLoading]);

  const appendFiltersToUrl = () => {
    const newParams = new URLSearchParams(searchParams);

    // Loop through each filter and append to the search params
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      }
    });

    // Update searchParams with new filters
    setSearchParams(newParams);
  };
  useEffect(() => {
    appendFiltersToUrl();
  }, []);
  const [searchResultVisible, setSearchResutsVisible] = useState(false);
  const [search, setSearch] = useState(invoice_number);
  const navigate = useNavigate();
  const updateParams = useUpdateParams();
  const { mutate, isPending: loadingInvoiceNumebers } = useSearchInvoice();
  const [matchedInvoices, setMatchedInvoices] = useState({});
  const handleSearch = () => {
    if (!search) return;
    setSearchResutsVisible(true);

    updateParams({ invoice_number: search });

    mutate(
      { invoice_number: search, ...filters },
      {
        onSuccess: (data) => {
          setMatchedInvoices(data?.data || []);
        },
        onError: (error) => {
          console.error("Search failed:", error);
          setMatchedInvoices([]); // Clear results on error
        }
      }
    );
  };
  const { setFilters, clearFIlters, setDefault, filters } = useFilterStore();
  const [vendorSearch, setVendorSearch] = useState("");
  const [restaurantSearch, setRestaurantSearch] = useState("");
  let restaurant_id =
    searchParams.get("restaurant_id") || searchParams.get("restaurant") || "";

  const { data: restaurants, isLoading: loadingRestaurants } =
    useGetRestaurantList();

  const {
    data: vendors,
    isLoading: loadinVendors,
    isError,
    error
  } = useGetVendorsList();
  let vendor_id =
    searchParams.get("vendor_id") || searchParams.get("vendor") || "";
  return (
    <>
      <NavHeader />
      <InvoiceHeader>
        <div className="d-flex justify-content-between w-100">
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <FilterDropdown />
            <Dropdown>
              <Dropdown.Toggle className="bg-white border-0" variant="light">
                Restaurants
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div
                  style={{
                    textAlign: "left",

                    maxWidth: "300px",
                    minWidth: "300px",
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                >
                  <FormControl
                    placeholder="Search Restaurant"
                    className="sticky-lg-top"
                    value={restaurantSearch}
                    onChange={(e) => setRestaurantSearch(e.target.value)}
                  />
                  {restaurants?.data
                    ?.filter((re) => re.restaurant_id)
                    ?.filter((res) =>
                      res?.restaurant_name
                        ?.toLowerCase()
                        ?.includes(restaurantSearch?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      const isCheckedA = restaurant_id?.includes(
                        a.restaurant_id
                      )
                        ? 1
                        : 0;
                      const isCheckedB = restaurant_id?.includes(
                        b.restaurant_id
                      )
                        ? 1
                        : 0;
                      return isCheckedB - isCheckedA;
                    })
                    ?.map((r) => {
                      return (
                        <div
                          className="p-2"
                          key={r.restaurant_id}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 10
                            }}
                          >
                            <span>
                              {r?.restaurant_name || r?.restaurant_id}
                            </span>
                            <Form.Check
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let existing =
                                    restaurant_id?.split(",")?.length;
                                  if (existing > 0) {
                                    let finalString =
                                      restaurant_id + "," + r.restaurant_id;
                                    updateParams({
                                      restaurant_id:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      restaurant:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      page: 1
                                    });
                                    setFilters({
                                      ...filters,
                                      restaurant:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      page: 1
                                    });
                                  }
                                } else {
                                  let existing_ids = restaurant_id?.split(",");
                                  let filteredString = existing_ids?.filter(
                                    (i) => i !== r.restaurant_id
                                  );
                                  updateParams({
                                    restaurant_id: filteredString,
                                    page: 1,
                                    restaurant: filteredString
                                  });
                                  setFilters({
                                    ...filters,
                                    restaurant: filteredString,
                                    page: 1
                                  });
                                }
                              }}
                              checked={restaurant_id?.includes(r.restaurant_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="  custom-dropdown-container py-0 ">
              <Dropdown.Toggle
                variant="light"
                className="dropdown-toggle bg-white border-0"
              >
                Vendors
              </Dropdown.Toggle>
              <Dropdown.Menu className=" bg-white">
                <div
                  style={{
                    textAlign: "left",

                    maxWidth: "300px",
                    minWidth: "300px",
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                >
                  <FormControl
                    placeholder="Search Vendor"
                    className="sticky-lg-top"
                    value={vendorSearch}
                    onChange={(e) => setVendorSearch(e.target.value)}
                  />
                  {vendors?.data?.vendor_names
                    ?.filter((ve) => ve.vendor_name)
                    ?.filter((ven) =>
                      ven?.vendor_name
                        ?.toLowerCase()
                        ?.includes(vendorSearch?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      const isCheckedA = vendor_id?.includes(a.vendor_id)
                        ? 1
                        : 0;
                      const isCheckedB = vendor_id?.includes(b.vendor_id)
                        ? 1
                        : 0;
                      return isCheckedB - isCheckedA;
                    })
                    ?.map((v) => {
                      return (
                        <div
                          className="p-2"
                          key={v.vendor_id}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 10
                            }}
                          >
                            <span>{v?.vendor_name}

                              
                            {v?.human_verified && <PatchCheckFill className="mx-2" fill="blue"/>}
                            </span>
                            <Form.Check
                              className="cursor-pointer"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let existing = vendor_id?.split(",")?.length;
                                  if (existing > 0) {
                                    let finalString =
                                      vendor_id + "," + v.vendor_id;
                                    updateParams({
                                      vendor_id:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      vendor:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      page: 1
                                    });
                                    setFilters({
                                      ...filters,
                                      vendor:
                                        finalString?.[0] == ","
                                          ? finalString?.slice(
                                              1,
                                              finalString?.length
                                            )
                                          : finalString,
                                      page: 1
                                    });
                                  }
                                } else {
                                  let existing_ids = vendor_id?.split(",");
                                  let filteredString = existing_ids?.filter(
                                    (i) => i !== v.vendor_id
                                  );
                                  updateParams({
                                    vendor_id: filteredString,
                                    vendor: filteredString,
                                    page: 1
                                  });
                                  setFilters({
                                    ...filters,
                                    vendor: filteredString,
                                    page: 1
                                  });
                                }
                              }}
                              checked={vendor_id?.includes(v.vendor_id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              onToggle={(visible) => setSearchResutsVisible(visible)}
              show={searchResultVisible}
              drop="start"
              className="position-relative w-100 flex-1.5"
            >
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  flexDirection: "row",
                  minWidth: "300px"
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && search) {
                      handleSearch();
                    }
                  }}
                  placeholder="Search Invoice"
                  aria-label="Search Invoice"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Button
                  variant="light"
                  disabled={!search}
                  onClick={() => {
                    handleSearch();
                  }}
                  className="before-content-none"
                >
                  <Search />
                </Button>
              </div>
              <Dropdown.Menu
                style={{
                  minWidth: "300px",
                  maxWidth: "500px",
                  background: "white",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  maxHeight: "250px",
                  overflowY: "auto",
                  marginTop: "10px"
                }}
              >
                <div className="d-flex justify-content-between mx-2">
                  <span>Matching Invoice Numbers</span>
                  <CloseButton
                    onClick={() => {
                      setSearchResutsVisible(false);
                      setSearch("");
                      updateParams({ invoice_number: undefined });
                    }}
                  />
                </div>
                <hr />
                {loadingInvoiceNumebers ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "140px" }}
                  >
                    <Spinner />
                  </div>
                ) : matchedInvoices.length > 0 ? (
                  matchedInvoices.map(
                    ({ document_uuid, invoice_number, vendor_name }) => (
                      <div
                        key={document_uuid}
                        onClick={() =>
                          navigate(
                            `/invoice-details?document_uuid=${document_uuid}`
                          )
                        }
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "8px 12px",
                          cursor: "pointer"
                        }}
                      >
                        <span>Inv. No. {invoice_number}</span>
                        <span>{vendor_name}</span>
                      </div>
                    )
                  )
                ) : (
                  <p className="text-center">No Invoice Found.</p>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="justify-content-end d-flex">
            {data &&
              (data?.data?.document_source == "clickbacon" ||
                data?.data?.[0]?.document_source == "clickbacon") && (
                <div style={{ position: "relative" }}>
                  <OverlayTrigger placement="left" overlay={tooltip}>
                    <Button
                      variant="success"
                      onMouseOver={() => {
                        setShowToolTip(true);
                      }}
                      onMouseLeave={() => setShowToolTip(false)}
                      className="mx-2"
                      onClick={() => setShowComments(true)}
                    >
                      <ChatDotsFill
                        height={20}
                        width={20}
                        fill="white"
                        className="cursor-pointer warning"
                      />
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
            {data && (
              <Comments
                show={showComments}
                setShow={setShowComments}
                name="Comments"
                invoiceUUID={document_uuid || data?.data?.[0]?.document_uuid}
              />
            )}
            <OverlayTrigger placement="left" overlay={tooltip2}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                // disabled={vendorID === null}
              >
                <JournalCheck
                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
            </OverlayTrigger>

            <ShareFill
              height={20}
              width={20}
              className="cursor-pointer warning mt-2 mx-2"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/invoice-details?document_uuid=${
                    document_uuid ||
                    data?.data?.[0]?.document_uuid ||
                    data?.data?.document_uuid
                  }`
                );
                toast.info("Link copied to clipboard");
              }}
            />
            {showNotes && (
              <Notes
                show={showNotes}
                setShow={setShowNotes}
                name="Vendor Notes"
                // data={venorNotes}
                vendorId={
                  data?.data?.vendor?.vendor_id ||
                  data?.data?.[0]?.vendor?.vendor_id
                }
              />
            )}
          </div>
        </div>
      </InvoiceHeader>
      {showDuplicatesWarning && (
        <Alert
          show={showDuplicatesWarning}
          variant="warning"
          className="mx-2 my-2"
          onClose={() => {
            setShowDuplicatesWarning(false);
          }}
          dismissible
        >
          <div className="d-flex justify-content-center">
            {duplicates?.message}
          </div>
        </Alert>
      )}
      {duplicates?.data?.duplicate_documents?.length > 0 && (
        <Alert
          show={show}
          variant="warning"
          className="mx-2 mt-2"
          onClose={() => {
            setShow(false);
          }}
          dismissible
        >
          <div className="d-flex justify-content-center">
            <p>
              Duplicate Invoices Found - {duplicates?.data?.duplicate_count}
            </p>{" "}
            <Alert.Link
              className="mx-2"
              onClick={() => {
                setModalShow(true);
              }}
            >
              Check Now
            </Alert.Link>
          </div>
        </Alert>
      )}

      <>
        <Row className="mx-md-5 ">
          {layout == "pdf" ? (
            <Col md={12} className="my-4">
              <PdfViewer
                loaded={isLoading}
                pdfUrls={[
                  {
                    document_link: `${
                      data?.data?.document_link ||
                      data?.data?.[0]?.document_link
                    }
                    `,
                    document_source: `${
                      data?.data?.document_source ||
                      data?.data?.[0]?.document_source
                    }`
                  }
                ]}
              />
              {!document_uuid && (
                <InvoicePagination
                  totalPages={data?.total_pages}
                  tab={activeTab}
                />
              )}

              <CategoryWiseSumTable />
              {showTable && (
                <Tables
                  setData={setData}
                  setIsLoading={setIsLoading}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              )}
            </Col>
          ) : layout == "table" ? (
            <Tables
              setData={setData}
              setIsLoading={setIsLoading}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          ) : (
            <>
              <Col md={6} className="my-4">
                <PdfViewer
                  pdfUrls={[
                    {
                      document_link: `${
                        data?.data?.document_link ||
                        data?.data?.[0]?.document_link
                      }
                    `,
                      document_source: `${
                        data?.data?.document_source ||
                        data?.data?.[0]?.document_source
                      }`
                    }
                  ]}
                />
                {!document_uuid && (
                  <InvoicePagination
                    totalPages={data?.total_pages}
                    tab={activeTab}
                  />
                )}

                <CategoryWiseSumTable />
              </Col>

              <Tables
                setData={setData}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            </>
          )}
        </Row>
      </>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <p>Duplicate Invoices Found </p>
          </Modal.Title>{" "}
        </Modal.Header>
        <p
          style={{
            fontSize: 16,
            fontWeight: 600,
            paddingLeft: 38,
            textDecoration: "underline"
          }}
        >
          Current Invoice
        </p>
        <div
          style={{
            borderBottom: "1px solid #d1d5db",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <p
            style={{
              fontSize: 14,
              fontWeight: 600,
              paddingLeft: 20
            }}
          >
            Upload Date:{" "}
            {duplicates?.data?.current_document?.date_uploaded
              ?.split(".")?.[0]
              ?.split("T")
              ?.join(" ")}
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: 600,
              paddingRight: 20
            }}
          >
            Human Verified:{" "}
            {duplicates?.data?.current_document?.rejected ? "Yes" : "No"}
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: 600,
              paddingRight: 20
            }}
          >
            Rejected:{" "}
            {duplicates?.data?.current_document?.rejected ? "Yes" : "No"}
          </p>
        </div>

        <Modal.Body>
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: 20,
              textDecoration: "underline"
            }}
          >
            Duplicate Invoices
          </p>
          <div className="d-flex justify-content-center mt-0">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Human Verified</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Upload Date</th>
                </tr>
              </thead>
              <tbody>
                {duplicates?.data?.duplicate_documents?.map(
                  (invoice, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <Link
                          to={`/invoice-details?document_uuid=${invoice.document_uuid}`}
                          target="_blank"
                        >
                          View
                        </Link>
                      </td>
                      <td>{invoice?.human_verified ? "Yes" : "No"}</td>
                      <td>{invoice?.rejected ? "Yes" : "No"}</td>
                      <td>
                        {invoice?.date_uploaded
                          ?.split(".")?.[0]
                          ?.split("T")
                          ?.join(" ")}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoiceDetails;
