import { queryClient } from "lib/react-query";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, Spinner } from "react-bootstrap";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  Download,
  SaveFill,
  SortAlphaDown,
  SortAlphaUp,
  Trash3Fill,
  XCircleFill,
  ZoomIn,
  ZoomOut
} from "react-bootstrap-icons";
import { Document, Page } from "react-pdf";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useAppStore from "store/global";
import { itemMasterStore } from "store/itemMasterStore";
import {
  useDeleteItemMaster,
  useGetAdditionalData,
  useGetItemMasterPdfs,
  useMergeVendorItemMaster,
  useUpdateVendorItemMaster
} from "../api";
import useUpdateParams from "hooks/useUpdateParams";
import { EditableInput } from "./VendorDetails";

const PdfViewerComponent = ({
  vendorId,
  itemUUID,
  setViewInvoice,
  getItemList,
  pdfs,
  setPdfs,
  currentPdfIndex,
  setCurrentPdfIndex
}) => {
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const handlePrevClick = () => {
    if (currentPdfIndex > 0) {
      setCurrentPdfIndex(currentPdfIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPdfIndex < pdfs.length - 1) {
      setCurrentPdfIndex(currentPdfIndex + 1);
    }
  };

  const currentPdf = pdfs?.length > 0 ? pdfs[currentPdfIndex] : null;
  const currentPdfUrl = currentPdf ? currentPdf?.document_link : null;
  const currentPdfSource = currentPdf ? currentPdf?.document_source : null;
  const updateParams = useUpdateParams();
  return (
    <div className="container border border-warning rounded p-3 mb-4">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="row justify-content-center mt-3">
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-primary w-100"
                onClick={handlePrevClick}
                disabled={currentPdfIndex === 0}
              >
                Previous
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-primary w-100"
                onClick={handleNextClick}
                disabled={currentPdfIndex === pdfs?.length - 1}
              >
                Next
              </button>
            </div>
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-success w-100"
                disabled={
                  pdfs?.[currentPdfIndex] === null ||
                  pdfs?.[currentPdfIndex] === undefined ||
                  pdfs?.length === 0
                }
                onClick={
                  () => {
                    updateParams({
                      document_uuid: pdfs?.[currentPdfIndex]?.document_uuid
                    });
                  }
                  // getItemList(, true)
                }
              >
                Items
              </button>
            </div>
            {/* <div className="col-md-2 mb-2">
              <Link
                className="btn btn-sm btn-info w-100"
                target="_blank"
                rel="noreferrer"
                to={``}
              >
                Invoice
              </Link>
            </div> */}
            <div className="col-md-2 mb-2">
              <button
                className="btn btn-sm btn-danger w-100"
                onClick={() => {
                  setViewInvoice(false);
                  updateParams({ document_uuid: undefined });
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div className="">
            <div className="">
              {currentPdfSource === "google_drive" ? (
                <iframe
                  title="pdf"
                  src={`${currentPdfUrl?.replace(
                    "uc?id=",
                    "file/d/"
                  )}/preview?embedded=true`}
                  width="100%"
                  height="470"
                  allow="autoplay"
                ></iframe>
              ) : currentPdfSource === "azure_blob" ||
                currentPdfSource === "clickbacon" ? (
                <DocumentComponent
                  pdfUrl={currentPdfUrl}
                  // hoveredCellCoordinates={hoveredCellCoordinates}
                />
              ) : (
                <div>No PDFs available</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const DocumentComponent = ({ pdfUrl, hoveredCellCoordinates }) => {
  const [pdfScale, setPdfScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [numPages, setNumPages] = useState(null);
  //const [pageNum, setPageNum] = useState(1);
  const { pageNum, setPageNum } = useAppStore();
  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // setPageNum(1);
  }
  return (
    <>
      <div
        className="ml-50 p-2 rounded"
        style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "16px" }}
        >
          <ZoomIn
            height={20}
            width={20}
            disabled={pdfScale >= 8}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s * 2)}
          />
          <ZoomOut
            height={20}
            width={20}
            disabled={pdfScale <= 0.1}
            className="cursor-pointer"
            onClick={() => setPdfScale((s) => s / 2)}
          />
          <button
            type="button"
            disabled={pageNum <= 1}
            onClick={previousPage}
            className="btn text-center btn-sm btn-outline-secondary"
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNum >= numPages}
            onClick={nextPage}
            className="btn btn-sm btn-outline-secondary"
          >
            Next
          </button>
          <ArrowClockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
          />
          <ArrowCounterclockwise
            height={20}
            width={20}
            className="cursor-pointer"
            onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
          />
          <div>
            <span>
              Page {pageNum} of {numPages || 1}
            </span>
            <Download
              height={20}
              width={20}
              className="cursor-pointer mx-4"
              onClick={() => window.open(pdfUrl, "_blank")}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "470px", overflow: "auto" }}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNum} scale={pdfScale} rotate={rotation}>
            {hoveredCellCoordinates && hoveredCellCoordinates.length > 0 && (
              <>
                {hoveredCellCoordinates.map((box, index) => (
                  <div
                    key={index}
                    id="bound-box"
                    style={{
                      position: "absolute",
                      left:
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) + "px",
                      top:
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) + "px",
                      width:
                        Math.max(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) -
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.x)
                        ) +
                        "px",
                      height:
                        Math.max(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) -
                        Math.min(
                          ...box.coordinates.map((coordinate) => coordinate.y)
                        ) +
                        "px",
                      // border: "1px solid rgba(144,238,144)",
                      padding: "5px",
                      background:
                        hoveredCellCoordinates.length <= 100
                          ? "rgba(144,238,144,0.17)"
                          : "rgba(144,238,144,0.02)",
                      borderRadius: "4px",
                      transform: "scale(1.1)",
                      pointerEvents: "none"
                    }}
                  />
                ))}
              </>
            )}
          </Page>
        </Document>
      </div>
    </>
  );
};

export default function VendorItemMastertable({ data = [], payload = {} }) {
  const [editableCell, setEditableCell] = useState(null);
  const [approveId, setApproveId] = useState(null);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [documentUUID, setDocumentUUID] = useState(null);
  const [selectedUUID, setSelectedUUID] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const { vendorId } = useParams();

  const {
    itemsToMerge,
    setItemsToMerge,
    masterBranchId,
    setMasterBranchId,
    masterUUID,
    setMasterUUID
  } = itemMasterStore();
  const [merging, setMerging] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [pdfs, setPdfs] = useState([]);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const handleEditClick = (e, id, row) => {
    setEditableCell(id);
    setRowToEdit(row);
  };

  const { data: additionalData, isLoading: loadingAdditionalData } =
    useGetAdditionalData();
  let category_choices = additionalData?.data?.category_choices;
  const setCachedData = (key, val, index) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { items } = copyObj?.data;
    items[index][key] = val;
    queryClient.setQueryData(["vendor-item-master", payload], copyObj);
  };

  const { mutate: updateVendorItemMaster } = useUpdateVendorItemMaster();
  const { mutate: mergeItems } = useMergeVendorItemMaster();
  const { mutate: deleteItemMaster } = useDeleteItemMaster();
  const { data: pdfsData, isLoading } = useGetItemMasterPdfs(selectedUUID);
  useEffect(() => {
    setPdfs(pdfsData?.data);
  }, [pdfsData, isLoading]);
  const [searchParams] = useSearchParams();
  let sort_by_item_code = searchParams.get("sort_by_item_code") || null;
  let sort_by_item_description =
    searchParams.get("sort_by_item_description") || null;

  const updateParams = useUpdateParams();

  return (
    <>
      <div style={{ overflowX: "auto", display: "flex" }}>
        <div
          className={`table-responsive  ${
            viewInvoice ? "col-md-6 mx-2" : "col-md-12"
          }`}
        >
          <table className="table table-striped table-hover table-bordered shadow-sm table-responsive">
            <thead>
              <tr>
                {data?.data?.required_columns?.map((col) => {
                  return (
                    <th key={col} style={{ textTransform: "capitalize" }}>
                      <div className="d-flex justify-content-center  gap-4">
                        {col.split("_").join(" ")}
                        {col.split("_").join(" ") == "item code" ? (
                          <div className="cursor-pointer">
                            {sort_by_item_code == null ||
                            sort_by_item_code == "null" ? (
                              <SortAlphaDown
                                fill="red"
                                onClick={() =>
                                  updateParams({ sort_by_item_code: "asc" })
                                }
                              />
                            ) : sort_by_item_code == "asc" ? (
                              <SortAlphaUp
                                fill="red"
                                onClick={() =>
                                  updateParams({ sort_by_item_code: "desc" })
                                }
                              />
                            ) : (
                              <XCircleFill
                                fill="red"
                                onClick={() =>
                                  updateParams({ sort_by_item_code: null })
                                }
                              />
                            )}
                          </div>
                        ) : col.split("_").join(" ") == "item description" ? (
                          <div className="cursor-pointer">
                            {sort_by_item_description == null ||
                            sort_by_item_description == "null" ? (
                              <SortAlphaDown
                                fill="red"
                                onClick={() =>
                                  updateParams({
                                    sort_by_item_description: "asc"
                                  })
                                }
                              />
                            ) : sort_by_item_description == "asc" ? (
                              <SortAlphaUp
                                fill="red"
                                onClick={() =>
                                  updateParams({
                                    sort_by_item_description: "desc"
                                  })
                                }
                              />
                            ) : (
                              <XCircleFill
                                fill="red"
                                onClick={() =>
                                  updateParams({
                                    sort_by_item_description: null
                                  })
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </th>
                  );
                })}

                <>
                  <th style={{ textAlign: "center" }}>Verified By</th>
                  {editableCell !== null && <th>Actions</th>}
                  {editableCell == null && (
                    <>
                      <th style={{ textAlign: "center" }}>Approve</th>
                      <th style={{ textAlign: "center" }}>Select Master</th>
                      <th style={{ textAlign: "center" }}>Select Item</th>
                    </>
                  )}
                </>

                <th>Invoice</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.items?.map((row, mainIndex) => (
                <React.Fragment key={row?.item_uuid}>
                  <tr>
                    {data?.data?.required_columns?.map((col) => {
              
                      return (
                        <td
                          onClick={(e) =>
                            handleEditClick(e, row?.item_uuid, row)
                          }
                          key={col}
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* { editableCell==null &&  col == "category" ? "" : row[col]} */}
                          {editableCell == null ? (
                            <>
                              {col == "category" ? (
                                <>{row["category"]?.name}</>
                              ) : (
                                row[col]
                              )}
                            </>
                          ) : (
                            <>
                              {col == "category" ? (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    style={{
                                      background: "none",
                                      color: "black",
                                      minWidth: "200px",
                                      border: "none"
                                    }}
                                  >
                                    {row["category"]?.name || "Select Category"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "200px",
                                      overflow: "auto"
                                    }}
                                  >
                                    <FormControl
                                      style={{
                                        position: "sticky",
                                        top: "-10px"
                                      }}
                                      value={categorySearchTerm}
                                      placeholder="Search category name"
                                      onChange={(e) =>
                                        setCategorySearchTerm(e.target.value)
                                      }
                                    />
                                    {category_choices
                                      ?.filter((it) =>
                                        it?.name
                                          ?.toLowerCase()
                                          ?.includes(
                                            categorySearchTerm?.toLowerCase()
                                          )
                                      )
                                      ?.map(({ category_id, name }, index) => {
                                        return (
                                          <Dropdown.Item
                                            key={category_id}
                                            onClick={() =>
                                              setCachedData(
                                                "category",
                                                { category_id, name },
                                                mainIndex
                                              )
                                            }
                                          >
                                            {name}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <EditableInput
                                  value={row[col]}
                                  onChange={(e) =>
                                    setCachedData(col, e, mainIndex)
                                  }
                                />
                              )}
                            </>
                          )}
                        </td>
                      );
                    })}

                    <td>{row?.verified_by?.username}</td>

                    {editableCell == null && (
                      <td
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {false && approveId == row?.item_uuid ? (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                          />
                        ) : row["human_verified"] === true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="green"
                            className="bi bi-check-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="blue"
                            className="bi bi-hand-thumbs-up-fill"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              if(!row?.category){
                                return toast.error("Category is a required field for approving items.")
                              }
                              setApproveId(row?.item_uuid);
                              // handleApprove(row);
                              setCachedData("human_verified", true, mainIndex);
                              updateVendorItemMaster(
                                {
                                  item_uuid: row?.item_uuid,
                                  data: { human_verified: true }
                                },
                                {
                                  onSuccess: () => {
                                    queryClient.invalidateQueries({
                                      queryKey: ["vendor-item-master"]
                                    });
                                  }
                                }
                              );
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                        )}
                      </td>
                    )}
                    {editableCell !== null && (
                      <td>
                        {editableCell === row?.item_uuid ? (
                          <>
                            <SaveFill
                              className="text-primary mx-1"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (!row?.category) {
                                  return toast.error(
                                    "Category is a required field."
                                  );
                                }
                                updateVendorItemMaster(
                                  {
                                    item_uuid: row?.item_uuid,
                                    data: row
                                  },
                                  {
                                    onSuccess: () => {
                                      setEditableCell(null);
                                    }
                                  }
                                );
                              }}
                            />
                            <XCircleFill
                              className="text-danger mx-1"
                              onClick={() => {
                                setEditableCell(null);
                                queryClient.invalidateQueries({
                                  queryKey: ["vendor-item-master"]
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    )}
                    {editableCell == null && (
                      <td>
                        <input
                          type="checkbox"
                          checked={masterUUID === row?.item_uuid}
                          onChange={() => {
                            if (masterUUID === row?.item_uuid) {
                              setMasterUUID(null);
                              setMasterBranchId(null);
                            } else {
                              setMasterUUID(row?.item_uuid);
                              setMasterBranchId(row?.branch?.branch_id);
                            }
                          }}
                          disabled={
                            itemsToMerge.find((item) => item === row?.item_uuid)
                              ? true
                              : false
                          }
                        />
                      </td>
                    )}
                    {editableCell == null && (
                      <td>
                        <input
                          type="checkbox"
                          checked={itemsToMerge?.includes(row?.item_uuid)}
                          onChange={() => {
                            if (
                              itemsToMerge.find(
                                (item) => item === row?.item_uuid
                              )
                            ) {
                              setItemsToMerge(
                                itemsToMerge.filter(
                                  (item) => item !== row?.item_uuid
                                )
                              );
                            } else {
                              setItemsToMerge([
                                ...itemsToMerge,
                                row?.item_uuid
                              ]);
                            }
                          }}
                          disabled={
                            masterUUID === row.item_uuid
                              ? true
                              : false ||
                                (masterBranchId !== null &&
                                  masterBranchId !== row?.branch?.branch_id)
                          }
                        />
                      </td>
                    )}
                    <td>
                      {viewInvoice && selectedUUID === row?.item_uuid ? (
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setViewInvoice(false);
                            setSelectedUUID(null);
                          }}
                        >
                          Hide
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setViewInvoice(true);

                            setSelectedUUID(row?.item_uuid);
                          }}
                        >
                          View
                        </button>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          setItemToDelete(row);
                          setShowDeleteModal(true);
                        }}
                      >
                        <Trash3Fill />
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        {viewInvoice && (
          <PdfViewerComponent
            setViewInvoice={setViewInvoice}
            vendorId={vendorId}
            itemUUID={selectedUUID}
            // handleMultipleCellHover={handleMultipleCellHover}
            // hoveredCellCoordinates={hoveredCellCoordinates}
            // getItemList={getItemList}
            pdfs={pdfs}
            setPdfs={setPdfs}
            currentPdfIndex={currentPdfIndex}
            setCurrentPdfIndex={setCurrentPdfIndex}
          />
        )}
        <ToastContainer />
      </div>
      <div className="">
        <button
          className="btn btn-primary my-2"
          onClick={() => {
            if (!masterUUID || itemsToMerge?.length == 0) {
              toast.error("Select Items");
              return;
            }
            setMerging(true);
            mergeItems(
              { master_item_uuid: masterUUID, items_to_merge: itemsToMerge },
              {
                onSuccess: () => {
                  setMerging(false);
                  setItemsToMerge([]);
                  setMasterUUID(null);
                },
                onError: () => setMerging(false)
              }
            );
          }}
          disabled={merging}
          style={{
            cursor: merging ? "not-allowed" : "pointer",
            width: "100%"
          }}
        >
          {merging ? (
            <Spinner size="sm" animation="border" variant="light" />
          ) : (
            "Merge Item Master"
          )}
        </button>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item Master</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {itemToDelete?.item_description}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            disabled={deleting}
            onClick={() => {
              setDeleting(true);
              deleteItemMaster(
                {
                  item_uuid: itemToDelete?.item_uuid,
                  type: "soft"
                },
                {
                  onSuccess: () => {
                    setShowDeleteModal(false);
                    setItemToDelete(false);
                    setDeleting(false);
                  },
                  onError: () => setDeleting(false)
                }
              );
            }}
          >
            Soft Delete
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowDeleteModal(false);
            }}
            disabled={true}
          >
            Hard Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
