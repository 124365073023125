import { useSearchInvoice } from "components/api/api";
import { FilterDropdown } from "components/layout/FilterDropdown";
import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { NavHeader } from "components/layout/NavHeader";
import { RestaurantDropdown } from "components/layout/RestaurantDropdown";
import { VendorDropdown } from "components/layout/VendorDropdown";
import useUpdateParams from "hooks/useUpdateParams";
import { useState } from "react";
import { Button, CloseButton, Dropdown } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetInvoiceStats } from "./api";
import { StatisticsTable } from "./components/Table";
import { BarChart } from "./components/BarChart";
import { Spinner } from "components/common/Spinner";

export const StatisticsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const updateParmas = useUpdateParams();
  let page = searchParams.get("page") || 1;
  let page_size = searchParams.get("page_size") || 8;
  let invoice_type = searchParams.get("invoice_type") || "";
  let human_verified = searchParams.get("human_verified") || "";
  let detected = searchParams.get("detected") || "";
  let rerun_status = searchParams.get("rerun_status") || "";
  let auto_accepted = searchParams.get("auto_accepted") || "";
  let start_date = searchParams.get("start_date") || "";
  let end_date = searchParams.get("end_date") || "";
  let clickbacon_status = searchParams.get("clickbacon_status") || "";
  let restaurant = searchParams.get("restaurant_id") || "";
  let vendor = searchParams.get("vendor_id") || "";
  let sort_order = searchParams.get("sort_order") || "desc";
  let invoice_number = searchParams.get("invoice_number") || "";

  const [searchResultVisible, setSearchResutsVisible] = useState(false);

  const payload = {
    auto_accepted: auto_accepted,
    end_date: end_date,
    human_verified: human_verified,
    detected: detected,
    invoice_type: invoice_type,
    clickbacon_status: clickbacon_status,
    rerun_status: rerun_status,
    restaurant: restaurant,
    start_date: start_date,

    vendor: vendor,
    page_size,
    page,
    sort_order
  };
  const { data, isLoading } = useGetInvoiceStats({ ...payload, page_size: 5 });
  const { data: matchedInvoices, isLoading: loadingInvoiceNumebers } =
    useSearchInvoice(invoice_number);
  const [search, setSearch] = useState(invoice_number);

  return (
    <>
      <NavHeader view="statistics" />

      <InvoiceHeader>
        <div className="d-flex justify-content-between w-100">
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <FilterDropdown />
            <RestaurantDropdown />
            <VendorDropdown />
            <Dropdown
              onToggle={(s) => setSearchResutsVisible(s)}
              drop="start"
              show={searchResultVisible}
              style={{ flex: "1.5 1 0%" }}
              className="position-relative w-100 flex-1.5"
            >
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  flexDirection: "row",
                  minWidth: "300px"
                }}
              >
                <input
                  type="text"
                  className="form-control pr-5"
                  placeholder="Search Invoice"
                  aria-label="Search Invoice"
                  aria-describedby="basic-addon1"
                  style={{ minWidth: "200px" }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <Dropdown.Toggle
                  disabled={!search}
                  style={{
                    background: "white",
                    border: "1px solid white",
                    marginLeft: 50
                  }}
                  onClick={() => {
                    if (search) {
                      setSearchResutsVisible(true);
                    } else {
                      setSearchResutsVisible(false);
                    }
                    updateParmas({ invoice_number: search });
                  }}
                  // as={SearchToggle}
                  className="before-content-none h-100 position-absolute top-0 bottom-0 end-0"
                >
                  <Search style={{ background: "white", color: "black" }} />
                </Dropdown.Toggle>
              </div>
              <Dropdown.Menu
                style={{
                  width: "100%",
                  minWidth: "300px",
                  maxWidth: "500px",
                  background: "white",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  maxHeight: "250px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#FDFFD0",
                  paddingRight: "12px",
                  marginRight: "-44px",
                  marginTop: "40px"
                }}
              >
                <div
                  className="d-flex flex-row justify-content-between mx-2"
                  style={{ height: "30px" }}
                >
                  <span>Matching Invoice Numbers</span>

                  <CloseButton
                    onClick={() => {
                      setSearchResutsVisible(false);
                      setSearch("");
                      updateParmas({ invoice_number: undefined });
                    }}
                  />
                </div>
                <hr className="featurette-divider mt-0 mb-0"></hr>
                <div
                  style={{
                    height: "140px",
                    overflowY: "auto",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                >
                  {loadingInvoiceNumebers && (
                    <div
                      style={{
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        height: "140px"
                      }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {!loadingInvoiceNumebers &&
                    (matchedInvoices?.data?.length > 0 ? (
                      matchedInvoices?.data?.map(
                        ({ document_uuid, invoice_number, vendor_name }) => {
                          return (
                            <tr
                              key={document_uuid}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignContent: "center",
                                alignItems: "center",
                                gap: 10,
                                paddingTop: 4,
                                paddingBottom: 4,
                                cursor: "pointer"
                              }}
                            >
                              <td style={{ fontSize: "12px" }}>
                                Inv. No. {invoice_number}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {vendor_name}
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <p
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          height: "140px"
                        }}
                      >
                        No Invoice Found.
                      </p>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Link
            className="mx-1"
            to="/vendor-consolidation-v2"
            style={{ textDecoration: "none" }}
          >
            <Button variant="info">Vendor Consolidation</Button>
          </Link>
        </div>
      </InvoiceHeader>

      {isLoading ? <div className="d-flex justify-content-center align-items-center align-content-center" style={{height:"700px"}}>
        <Spinner/>
      </div>:
        <>
          {" "}
          <BarChart stats={data?.data?.statistics} isLoading={isLoading} />
          <StatisticsTable
            data={data?.data?.table_data}
            isLoading={isLoading}
            totalPages={data?.total_pages}
          />{" "}
        </>
      }
    </>
  );
};
