import Axios from "axios";
import { toast } from "react-toastify";
import { API_URL, NEW_BACKEND_URL } from "../config/env";
import axios from "axios";

const paramsSerializer = (params) => {
  let result = "";
  Object.keys(params).forEach((key) => {
    result += `${key}=${encodeURIComponent(params[key])}&`;
  });
  return result.substring(0, result.length - 1);
};

const authRequestInterceptor = (config) => {
  const authToken = localStorage.getItem("token");
  
  config.headers = config.headers ?? {};
  if (authToken) {
    config.headers.authorization = `Bearer ${authToken}`;
  }
  config.headers.Accept = "application/json";
  return config;
};


const messagesNotToShow = [
  "Vendor, Restaurant or Invoice number are required to find duplicates"
];

const refreshAccessToken = async () => {
  // Get the refresh token from storage
  const refreshToken = localStorage.getItem("refreshToken");
  
  if (!refreshToken) {
    throw new Error("No refresh token found. User must log in.");
  }

  try {
    // Make a request to your refresh endpoint (assuming it's a POST request)
    const response = await instance.post("/api/auth/refresh-token", { refreshToken });
    
    // If successful, return the new access token
    return response.data.accessToken; // Assuming the response contains a new access token
  } catch (error) {
    throw new Error("Refresh token expired or invalid.");
  }
};

export const errorInterceptor = async (error) => {

  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;

    if (!originalRequest._retry) {
      originalRequest._retry = true;
      
      try {

        const newAccessToken = await refreshAccessToken();
        localStorage.setItem("token", newAccessToken);
      
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
 
        return instance(originalRequest);
      } catch (refreshError) {

        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
        return Promise.reject(refreshError);  
      }
    }
  }

  if (!error.response) {
    if (error.code !== "ERR_CANCELED") {
      toast.error("Something went wrong with the server");
    }
    return Promise.reject(error);
  }
  const message = error?.response?.data?.message || error?.message || "Something went wrong";

  if (!messagesNotToShow?.includes(message)) {
    toast.error(message);
  }

  return Promise.reject(error);
};

export const axiosInstance = Axios.create({
  baseURL: API_URL
});

export const instance = Axios.create({
  baseURL: NEW_BACKEND_URL
});

instance.interceptors.response.use(null, errorInterceptor);
axiosInstance.interceptors.request.use(authRequestInterceptor);
instance.interceptors.request.use(authRequestInterceptor);
axiosInstance.defaults.paramsSerializer = paramsSerializer;


axiosInstance.interceptors.response.use(null, errorInterceptor);
// axios.interceptors.request.use((config) => {
//   config.data = { request: config.data };
//   return config;
// });
// axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
