import { useGetVendorsList } from "components/api/api";
import useUpdateParams from "hooks/useUpdateParams";
import { useState } from "react";
import { Dropdown, FormControl, Spinner } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import useFilterStore from "store/persistentFilterStore";
import { vendorStore } from "store/vendorStore";

export const VendorDropdown = () => {
  const updateParams = useUpdateParams();
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, isError, error } = useGetVendorsList();
  const { setFilters } = useFilterStore();
  const [searchParams] = useSearchParams();
  const { setSearchedVendors } = vendorStore();
  let vendor_id =
    searchParams.get("vendor_id") || searchParams.get("vendor") || "";
  return (
    <Dropdown
      autoClose={"outside"}
      onSelect={(v) => {
        if (v === "-1") {
          return;
        }
        // setVendorName(v);
        console.log(v);
        // updateParams({ filters: { vendor_id: v } });
      }}
    >
      <Dropdown.Toggle
        variant="outline"
        className="border w-100 bg-white h-100"
        style={{
          lineHeight: "1.2",
          fontSize: "14px"
        }}
      >
        {data?.data?.vendor_names?.find((it) => it?.vendor_id == vendor_id)?.[
          "vendor_name"
        ] || "All Vendors"}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          maxWidth: "300px",
          minHeight: "100px",
          maxHeight: "300px",
          overflowY: "scroll",
          overflowX: "scroll",
          zIndex: 9999
        }}
      >
        {isLoading ? (
          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : isError ? (
          error.message
        ) : (
          <>
            <FormControl
              type="text"
              className="form-control mx-auto  mb-1"
              placeholder="Search"
              onInput={(e) => {
                setSearchQuery(e.target.value);

                setSearchedVendors(
                  data?.data?.vendor_names?.filter((it) =>
                    it?.vendor_name
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  )
                );
              }}
              style={{
                width: "98%",
                position: "sticky",
                top: -7
              }}
            />
            <Dropdown.Item
              eventKey={null}
              onClick={() => {
                updateParams({ vendor_id: undefined, vendor: undefined });
                setFilters({ vendor_id: undefined });
              }}
            >
              None
            </Dropdown.Item>
            {data?.data?.vendor_names
              ?.filter((it) =>
                it?.vendor_name
                  ?.toLowerCase()
                  ?.includes(searchQuery?.toLowerCase())
              )
              ?.map((vendor, i) => (
                <Dropdown.Item
                  className="d-flex justify-content-between align-items-center"
                  key={vendor.vendor_id}
                  onClick={() => {
                    updateParams({
                      vendor_id: vendor?.vendor_id,
                      page: 1,
                      vendor: vendor?.vendor_id,
                      page_number: 1
                    });
                    setFilters({ vendor: vendor?.vendor_id, page: 1 });
                  }}
                  eventKey={vendor?.vendor_name}
                >
                  {vendor?.vendor_name}
                  {vendor?.human_verified && <PatchCheckFill fill="blue" />}
                </Dropdown.Item>
              ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
