import { Nav, Tab } from "react-bootstrap";

export default function Header({ text, children, archived = false }) {
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="all"
      variant="pills"
      justify
    >
      <Nav
        variant="pills"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          marginTop: "100px",
          gap: "10px"
        }}
        className="d-flex bg-nav p-2 rounded-2 align-items-center mx-5"
      >
        {/* <div className="col-sm-2" /> */}
        <Nav.Item>
          <Nav.Link
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "22px",
              marginLeft: "33px"
            }}
          >
            {text}
            {archived && (
              <span class="mx-2 badge bg-warning" style={{ fontWeight: 400 }}>
                Archived{" "}
              </span>
            )}
            {/* <Button size="sm" variant="warning" style={{fontSize:"14px",marginLeft:10,borderRadius:10}}>{"Archived"}</Button> */}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item
          style={{
            marginLeft: "auto",
            marginRight: "33px"
          }}
        >
          {children}
        </Nav.Item>
      </Nav>

      <Tab.Content></Tab.Content>
    </Tab.Container>
  );
}
