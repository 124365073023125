import useUpdateParams from "hooks/useUpdateParams";
import { useEffect, useState } from "react";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill
} from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
export const HomePagination = ({  }) => {
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [pageIndex, setPageIndex] = useState(page);
  const {totalPages}=invoiceDetailStore()
  const updateParams = useUpdateParams();
  useEffect(() => {
    setPageIndex(page);
  }, [searchParams, page]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const tagName = document.activeElement.tagName.toLowerCase();
      const isEditable =
        document.activeElement.isContentEditable ||
        tagName === "input" ||
        tagName === "textarea" ||
        tagName === "select";
  
      if (!isEditable) {
        if (e.key === "ArrowLeft" && pageIndex > 1) {
          // Go to the previous page
          updateParams({ page: Number(pageIndex) - 1 });
          setPageIndex(pageIndex - 1);
        } else if (e.key === "ArrowRight" && pageIndex < totalPages) {
          // Go to the next page
          updateParams({ page: Number(pageIndex) + 1 });
          setPageIndex(pageIndex + 1);
        }
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pageIndex, totalPages, updateParams]);
  
  return (
    <div className="my-4">
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={page == 1}
        onClick={() => {
          updateParams({ page: Number(page) - 1 });
          setPageIndex(page - 1);
        }}
      >
        <ArrowLeftCircleFill size={40} />
      </button>
      <span className="my-4 mx-2">
        <input
          value={pageIndex}
          onChange={(e) => setPageIndex(e.target.value)}
          onBlur={() => {
            if (pageIndex >= totalPages) {
              setPageIndex(totalPages);
              updateParams({ page: totalPages });
            } else if (pageIndex <= 1) {
              setPageIndex(1);
              updateParams({ page: 1 });
            } else {
              updateParams({ page: pageIndex });
            }
          }}
          className="btn btn-secondary"
          style={{ width: "9ch" }}
        />
        <span className="my-4">
          {" "}
          <strong>/</strong>{" "}
          {totalPages ? (
            <input
              disabled
              value={`${totalPages}`}
              className="btn btn-secondary"
              style={{ width: "9ch", cursor: "default" }}
            />
          ) : (
            <input
              disabled
              value={pageIndex}
              className="btn btn-secondary"
              style={{ width: "9ch", cursor: "default" }}
            />
          )}
        </span>
      </span>
      <button
        className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
        disabled={totalPages == 1 || totalPages == page}
        onClick={() => {
          if (page <= totalPages) {
            setPageIndex(Number(page) + 1);
            updateParams({ page: Number(page) + 1 });
          }
        }}
      >
        <ArrowRightCircleFill size={40} />
      </button>
    </div>
  );
};
