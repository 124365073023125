import React, { useState } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "modules/vendorConsolidation/components/Header";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Assignment from "./Components/Assignment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateRange } from "react-date-range";
import { formatDate } from "components/layout/FilterDropdown";
import useUpdateParams from "hooks/useUpdateParams";
import "react-date-range/dist/styles.css"; // Import date range picker default styles
import "react-date-range/dist/theme/default.css"; // Import date range picker theme styles
import CustomDropdown from "components/CustomDropdown";
import { useGetAllUsers } from "modules/vendorConsolidation/api";
import { RestaurantDropdown } from "components/layout/RestaurantDropdown";
import { VendorDropdown } from "components/layout/VendorDropdown";
import { toast, ToastContainer } from "react-toastify";
import { useAssignUnAssignedInvoices } from "modules/vendorConsolidation/api";
import { queryClient } from "lib/react-query";
import SchedularsTable from "./Components/SchedularsTable";
const Tab = ({ value, activeValue, onClick, ...props }) => (
  <button
    className="border p-2 mx-1"
    style={{
      flex: "1 1 0%",
      minWidth: "120px",
      backgroundColor: activeValue === value ? "#FDFFD0" : "white",
      textTransform: "capitalize",
      cursor: "pointer"
    }}
    onClick={() => onClick(value)}
    {...props}
  >
    {value}
  </button>
);
export default function InvoiceAssignment() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [unAssignedDateRange, setUnAssignedDateRange] = useState({
    startDate: null,
    endDate: null
  });

  const { data: userData, isLoading: userDataLoading } = useGetAllUsers();
  const [humanVerified, setHumanVerified] = useState(null);
  const [showToAssignModal, setShowToAssignModal] = useState(false);
  const [overDue, setOverDue] = useState(null);
  const updateParams = useUpdateParams();
  const [showFilters, setShowFilters] = useState(false);
  let assigned_to = searchParams.get("assigned_to");
  let start_date = searchParams.get("start_date");
  let end_date = searchParams.get("end_date");
  let human_verified = searchParams.get("human_verified");
  let over_due = searchParams.get("over_due");
  const [assigning, setAssigning] = useState(false);
  const { mutate, isPending } = useAssignUnAssignedInvoices();
  const [tab, setTab] = useState("Invoice Assignment");
  return (
    <div>
      <NavHeader view="Invoice Assignment" />

      <ToastContainer />
      <Header>
        <div className="d-flex align-content-center justify-content-center w-100 border-2">
          <div style={{ position: "absolute", left:60 }}>
            <Tab
              activeValue={tab}
              onClick={() => setTab("Invoice Assignment")}
              value={"Invoice Assignment"}
            />
            <Tab
              activeValue={tab}
              onClick={() => setTab("Balancer Assignment")}
              value={"Balancer Assignment"}
            />
          </div>

          <div className="d-flex gap-20">
            {/* Filters Dropdown */}

            <Dropdown
              show={showFilters}
              onToggle={(isOpen) => setShowFilters(isOpen)}
            >
              <Dropdown.Toggle variant="light" id="dropdown-filters">
                Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ marginRight: 100 }}>
                {/* Date Picker */}
                <div className="mb-3">
                  <strong>Date Picker</strong>
                  <p
                    className="w-100 d-flex justify-content-end nav-underline cursor-pointer mb-1"
                    onClick={() => {
                      setDateRange({
                        startDate: null,
                        endDate: null
                      });
                      updateParams({
                        start_date: "",
                        end_date: ""
                      });
                    }}
                  >
                    Reset
                  </p>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      const startDate = formatDate(item.selection.startDate);
                      const endDate = formatDate(item.selection.endDate);

                      // Update the date range in the state and the params
                      setDateRange({ startDate, endDate });
                      updateParams({
                        start_date: startDate,
                        end_date: endDate,
                        page: 1
                      });
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={[
                      {
                        startDate: dateRange.startDate
                          ? new Date(dateRange.startDate)
                          : new Date(),
                        endDate: dateRange.endDate
                          ? new Date(dateRange.endDate)
                          : new Date(),
                        key: "selection"
                      }
                    ]}
                  />
                </div>

                <hr />

                {/* Human Verified Dropdown */}
                <div className="mb-3">
                  <strong>Human Verified</strong>
                  <DropdownButton
                    title={
                      human_verified
                        ? "Yes"
                        : human_verified == ""
                        ? "Select"
                        : "No"
                    }
                    id="dropdown-human-verified"
                    variant="light"
                    onSelect={(value) => {
                      setHumanVerified(value);
                      updateParams({ human_verified: value, page: 1 });
                    }}
                  >
                    <Dropdown.Item eventKey="true">Yes</Dropdown.Item>
                    <Dropdown.Item eventKey="false">No</Dropdown.Item>
                    <Dropdown.Item eventKey="">Clear</Dropdown.Item>
                  </DropdownButton>
                </div>

                <hr />

                {/* Over Due Dropdown */}
                <div className="mb-3">
                  <strong>Over Due</strong>
                  <DropdownButton
                    title={over_due ? "Yes" : over_due == "" ? "Select" : "No"}
                    id="dropdown-over-due"
                    variant="light"
                    onSelect={(value) => {
                      setOverDue(value);
                      updateParams({ over_due: value, page: 1 });
                    }}
                  >
                    <Dropdown.Item eventKey="true">Overdue</Dropdown.Item>
                    <Dropdown.Item eventKey="false">Not Overdue</Dropdown.Item>
                    <Dropdown.Item eventKey="">Clear</Dropdown.Item>
                  </DropdownButton>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="mx-2 " style={{ display: "flex", gap: 10 }}>
              <div className="">
                {!userDataLoading && (
                  <CustomDropdown
                    data={
                      [
                        { id: undefined, username: "None" },
                        ...userData?.data
                      ] ?? []
                    }
                    Objectkey={"id"}
                    displayKey={"username"}
                    className="w-100"
                    maxWidth="250px"
                    minWidth="250px"
                    value={
                      userData?.data?.find((user) => user?.id === assigned_to)
                        ?.username || "Assigned To"
                    }
                    onSelect={(v) => {
                      updateParams({ assigned_to: v, page: 1 });
                    }}
                  />
                )}
              </div>
              <RestaurantDropdown />
              <VendorDropdown />
            </div>

           
            <Button
              variant="success"
              size="sm"
              className="mx-1"
              onClick={() => navigate(`/schedulars`)}
            >
              Schedulars
            </Button>
          </div>
        </div>
      </Header>
      {tab!=="Balancer Assignment"?<Assignment />:   <SchedularsTable />}
   
      
      <Modal
        show={showToAssignModal}
        onHide={() => {
          setShowToAssignModal(false);
          setUnAssignedDateRange({
            startDate: null,
            endDate: null
          });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Unassigned Invoices</Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-auto">
          <div>
            <DateRange
              editableDateInputs={true}
              className="w-100"
              style={{ width: "100%" }}
              onChange={(item) => {
                const startDate = formatDate(item.selection.startDate);
                const endDate = formatDate(item.selection.endDate);
                setUnAssignedDateRange({ startDate, endDate });
              }}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: unAssignedDateRange.startDate
                    ? new Date(unAssignedDateRange.startDate)
                    : new Date(),
                  endDate: unAssignedDateRange.endDate
                    ? new Date(unAssignedDateRange.endDate)
                    : new Date(),
                  key: "selection"
                }
              ]}
            />
          </div>
          <div className="mt-3 text-end">
            <Button
              onClick={() => {
                setAssigning(true);
                mutate(
                  {
                    start_date: unAssignedDateRange?.startDate,
                    end_date: unAssignedDateRange?.endDate,
                    restaurant_ids: []
                  },
                  {
                    onSuccess: (data) => {
                      toast.success(data?.message);
                      setAssigning(false);
                      setShowToAssignModal(false);
                      setUnAssignedDateRange({
                        startDate: null,
                        endDate: null
                      });
                      queryClient.invalidateQueries({
                        queryKey: ["assignedInvoices"]
                      });
                    },
                    onError: () => {
                      setAssigning(false);
                    }
                  }
                );
              }}
            >
              {assigning ? "Assigning..." : "Assign"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
