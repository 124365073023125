import React from "react";
import { useFetchAllUsers } from "./api";
import { NavHeader } from "components/layout/NavHeader";
import Header from "modules/vendorConsolidation/components/Header";
import { Spinner } from "components/common/Spinner";
import { useNavigate } from "react-router-dom";
export default function UserActivity() {
  const { data, isLoading, isError } = useFetchAllUsers();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
 
  return (
    <>
      <NavHeader view="User Activity" />
      <Header text="Users" />
      {isLoading && <Spinner />}
      {isError && <div>Something went wrong</div>}
      {data && (
        <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
          <table className="table table-striped table-hover table-bordered table-responsive">
            <thead>
              <tr>
                <th>Username</th>
                <th>ID</th>
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((user) => (
                <tr key={user?.id}>
                  <td>{user?.username}</td>
                  <td>{user?.id}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        // navigate(
                        //   `/user-activity-stats?user_id=${user.user_uuid}`
                        // )
                        {}
                      }
                    >
                      View Activity
                    </button>
                  </td>
                </tr>
              ))}
              {role === "admin" && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <button
                      className="btn btn-sm btn-primary"
                      style={{ width: "75%" }}
                      onClick={() =>
                        navigate(`/user-activity-stats?user_id=all`)
                      }
                    >
                      View Combined Statistics
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
